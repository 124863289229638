import cloneDeep from 'lodash.clonedeep';
import {
  AnalitycsJSON,
  EType,
  IAnalyticsJSON,
  getWrapText,
} from '~packages/analyticsJSON';
import { allWidgetStructures } from '~packages/charts-new/chartTypes';

import {
  EWidgetType,
  WidgetField,
  WidgetInfo,
  WidgetSettingsFieldItem,
  WidgetStructure,
  WidgetStructureField,
} from '@/types';
import DatabaseQuery from '@bi-core/helpers/DatabaseQuery/DatabaseQueryNew';

const databaseQuery = new DatabaseQuery();

// TODO DatasetLegend типизоровать точнее
type DatasetLegend = {
  dataset: Array<Record<string, unknown>>;
  activeSeries: Array<Record<string, unknown>>;
  legend: Array<Record<string, unknown>>;
};

export default class Chart {
  datasetLegend: DatasetLegend = {
    dataset: [],
    activeSeries: [],
    legend: [],
  };

  widgetInfo: WidgetInfo;
  widgetStructure: WidgetStructure;
  analyticsJSON: IAnalyticsJSON;

  x: WidgetSettingsFieldItem[];
  y: WidgetSettingsFieldItem[];
  sort: WidgetSettingsFieldItem[];

  constructor(settings) {
    this.widgetInfo = settings;
    this.widgetStructure = cloneDeep(
      allWidgetStructures.find(
        structure => structure.id === settings.structureId
      )
    );
    this.x =
      this.widgetInfo.widgetFields.find(field => field.id === 'x')?.items || [];
    this.y =
      this.widgetInfo.widgetFields.find(field => field.id === 'y')?.items || [];
    this.sort =
      this.widgetInfo.widgetFields.find(field => field.id === 'sort')?.items ||
      [];
  }

  isValidateSettings() {
    let valid = false;
    const structureFields: WidgetStructureField[] =
      this.widgetStructure?.structureFields || [];
    const widgetFields: WidgetField[] = this.widgetInfo.widgetFields;
    const requiredFields: WidgetStructureField[] = structureFields.filter(
      f => f.required === true
    );
    const filledRequiredFields = [];
    // Проверка есть ли итемы в полях, которые required
    for (const field of widgetFields) {
      const filledField: WidgetStructureField | undefined = requiredFields.find(
        f => f.id === field.id && field.items.length > 0
      );
      if (filledField) filledRequiredFields.push(filledField);
    }
    if (
      requiredFields.length > 0 &&
      requiredFields.length === filledRequiredFields.length
    ) {
      valid = true;
    } else if (requiredFields.length === 0 && widgetFields.length > 0) {
      valid = true;
    }
    return valid;
  }

  // для формирования АС нужно убрать поля, которые не должны попасть в columns
  getFieldsForQuery(widgetFields) {
    const filteredFields = widgetFields.filter(widgetField =>
      this.widgetStructure.structureFields.some(
        ({ id, isColumn }) => widgetField.id === id && isColumn
      )
    );
    const fields = [];
    for (const field of filteredFields) {
      for (const item of field.items) {
        fields.push({ ...item });
      }
    }
    return fields;
  }

  // TODO доделать прокидывыание итема в сортировку
  getQuerySettings(settingPanel) {
    const { table, additionalFilters, widgetFields } = settingPanel;
    const fields = this.getFieldsForQuery(widgetFields);
    const filter = widgetFields.find(f => f.id === 'filter')?.items || [];
    const limit = [];
    const sort = widgetFields.find(f => f.id === 'sort')?.items || [];
    const sortX = [];
    return {
      fields,
      table: table,
      additionalFilters,
      modelId: settingPanel.modelId,
      filter,
      limit,
      sort,
      sortX,
    };
  }

  getJsonFormat(settings, schema) {
    const querySettings = this.getQuerySettings(settings);
    const { columns, filters, additionalFilters, sort, columnsLevels, sortX } =
      databaseQuery.createAttributeJsonFormat(querySettings);
    return databaseQuery.getJsonFormat(
      EWidgetType.Chart,
      {
        columns,
        columnsLevels,
        filters,
        additionalFilters,
        sort,
        sortX,
        level: 0,
        page: null,
        limit: querySettings.limit,
      },
      schema
    );
  }

  getOptionsConvert() {
    return {
      isAddAllIndicators: true,
      isRollupData: true,
    };
  }

  convertDataQuery(analyticsJSON, dataQuery, options) {
    return analyticsJSON.convert(dataQuery, options);
  }

  generateDataset(dataQuery) {
    const analyticsJSON = new AnalitycsJSON(EType.Column, this.analyticsJSON);
    return this.convertDataQuery(
      analyticsJSON,
      dataQuery,
      this.getOptionsConvert()
    );
  }

  generateDatasetLegend(dataQuery): DatasetLegend {
    const { source, indicators } = this.generateDataset(dataQuery);
    return {
      dataset: [{ source }],
      activeSeries: indicators,
      legend: indicators,
    };
  }

  generateAllOptionChart(datasetLegend): any {
    const settings = { ...this };
    const optionChart = {
      dataset: datasetLegend.dataset,
    };
    // if (settings.limitValues?.show) this.generateMarkline(optionChart);
    return optionChart;
  }

  updateOptionChart(optionChart) {
    console.log('update ', optionChart);
  }

  generateOptionChart(dataQuery) {
    this.datasetLegend = this.generateDatasetLegend(dataQuery);
    const optionChart = this.generateAllOptionChart(this.datasetLegend);
    this.updateOptionChart(optionChart);
    return optionChart;
  }
}
