import { ETypeColumn } from '@/types';

export default function createAttribute(column, columnName = '') {
  const newlineRegExp = /\n/g;
  const humanName = column.humanName.replace(newlineRegExp, ' ');
  return {
    description: humanName,
    name: column.name || columnName,
    _name: humanName,
    type: column.type || ETypeColumn.Dimension,
    dataType: column?.dataType || '',
    source: column?.source || '',
    level: column?.level || 0,
  };
}
