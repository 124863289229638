import type { IUser } from '@/types';
import { useQuery } from '@tanstack/vue-query';

import {
  getErrorQuery,
  getQueryMiddlewares,
  useQueryWrapper,
} from '../helpers';

export default function useMe() {
  const { alert, api } = getQueryMiddlewares('userInfo', 'fetchAll');

  const fetchMe = async () => {
    const apiResponse = await api();
    if (!apiResponse?.status) {
      throw getErrorQuery(
        'Не удалось загрузить текущего пользователя',
        apiResponse.errors,
        apiResponse.statusCode
      );
    }
    return apiResponse.response as IUser;
  };

  const queryKey = ['users', 'me'];
  const query = useQuery(queryKey, fetchMe);

  return useQueryWrapper(query, queryKey, alert);
}
