const idBiLoader = 'bi-loader';
const loader = document.getElementById(idBiLoader);
const defaultSleep = 2000;

function sleep(ms = 0) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const mount = async () => {
  await sleep(defaultSleep);
  const loader = document.getElementById(idBiLoader);
  if (loader) {
    loader.remove();
  }
};

const html = `<div class="overlay" style="background: rgba(255, 255, 255, 0.6); position: fixed;">
<div class="loader">
  <div class="loader-cube">
    <div class="loader-cube__inner">
      <div class="sk-cube-grid" style="height: 100px; width: 100px;">
        <div class="sk-cube sk-cube0"></div>
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
      </div>
    </div>
  </div>
</div>
</div>`;

if (loader) {
  loader.innerHTML = html;
}

export { mount, sleep };
