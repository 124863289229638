import { WidgetResponseDto } from '@/api/generated';

import { EChartTypes, EDataType, ETypeColumn, TFormattedText } from '..';

export enum EWidgetType {
  Table = 'table',
  Chart = 'chart',
  Filter = 'filter',
  Image = 'image',
}

export enum EState {
  View = 'view',
  Edit = 'edit',
}

export interface TuiOptions extends Record<string, unknown> {
  name: string;
  type: EWidgetType;
  subtype: EChartTypes;
  series?: Array<{
    field: string;
    aggregation: string;
  }>;
  metrics?: Array<{
    field: string;
    aggregation: string;
    chartType: EChartTypes;
  }>;
  imageId?: string;
  imageCropId?: string;
  imageFit?: string;
  formatting?: {
    name: TFormattedText;
  };
}

export type TWidgetData = Omit<WidgetResponseDto, 'type' | 'subtype'> & {
  type: EWidgetType;
  subtype: EChartTypes;
  uiOptions: TuiOptions;
};

export interface WidgetLocalSettings {
  showTitle: boolean;
  editorText: {
    text: string;
    formatting: TFormattedText;
  };
}

export type TNewWidget = Omit<TWidgetData, 'id' | 'creationTime'>;

export function isWidget(
  widget: TWidgetData | TNewWidget | TuiOptions
): widget is TWidgetData {
  return 'id' in widget && 'creationTime' in widget && 'uiOptions' in widget;
}

interface WidgetDashboardsData {
  dashboardId: string;
  dashboardName: string;
}

export type WidgetData = Omit<
  WidgetResponseDto,
  | 'modelId'
  | 'analyticStructure'
  | 'uiOptions'
  | 'calculatedFields'
  | 'dataQueryId'
> & {
  modificationTime: string;
  widgetDashboards: WidgetDashboardsData[];
  widgetModel: {
    modelId: string;
    modelName: string;
  };
};

export enum EAggregationList {
  Numeric = 'numeric',
  Analytic = 'analytic',
  Date = 'date',
}

export enum EItemFieldRelation {
  ValNum = 'value-numeric',
  ValNotNum = 'value-not-numeric',
  Dimension = 'dimension',
}

export enum EAggregationFunctionNew {
  Sum = 'SUM',
  Avg = 'AVG',
  Count = 'COUNT',
  Uniq = 'uniq',
  Min = 'MIN',
  Max = 'MAX',
}

export enum ESortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type WidgetSettingsFieldItem = {
  chipsId: string;
  id: string;
  name: string;
  type: ETypeColumn;
  dataType: EDataType;
  aggregation?: EAggregationFunctionNew;
  icon?: Record<string, any>[];
  settings?: Record<string, any>[];
};

export type WidgetField = {
  id: string;
  type: string;
  items: WidgetSettingsFieldItem[];
  // TODO поправить тип для настроек
  settings: Record<string, any>;
};

export type WidgetInfo = {
  structureId: string;
  id: string;
  modelId: string;
  projectId: string;
  widgetFields: WidgetField[];
};

export enum EChartLib {
  Echarts = 'echarts',
  D3 = 'd3',
  Custom = 'custom', // для счетчика и карты ?
}

export enum EEchartsWidgetType {
  Line = 'line',
  Pie = 'pie',
  Bar = 'bar',
  LineBar = 'line-bar',
  Radar = 'radar',
  Treemap = 'treemap',
  Sunburst = 'sunburst',
}
// дублировал в id для удобства
export enum EEchartsWidgetId {
  Line = '0',
  StackedLine = '1',
  Pie = '2',
  Bar = '3',
  StackedBar = '4',
  LineBar = '5',
  StackedLineBar = '6',
  Radar = '7',
  Treemap = '8',
  Sunburst = '9',
}

export enum ECustomWidgetType {
  Counter = 'counter',
  Map = 'map',
  Table = 'table ',
}

export enum ECustomMapSubtype {
  RussiaMap = 'russiaMap',
}
export enum ECustomCounterSubtype {
  Counter = 'counter',
}
export enum ECustomTableSubtype {
  Table = 'table',
}
type allowedTypes = ETypeColumn.Dimension | ETypeColumn.Value;

export type WidgetStructureField = {
  allowedTypes?: allowedTypes[];
  recommendedType?: allowedTypes;
  id: string;
  type: string;
  label: string;
  required: boolean;
  capacity: number;
  settings?: Record<string, string>;
  isColumn?: boolean;
};

export type WidgetStructureComponent = {
  id: 'string';
  component: 'string';
};

export type WidgetStructure = {
  id: string;
  lib: EChartLib;
  type: string;
  name: string;
  hasFilters: boolean;
  hasSort: boolean;
  hasTooltip: boolean;
  structureFields: Array<WidgetStructureField | WidgetStructureComponent>;
};

export interface AggregationListFunction {
  icon: { name: string };
  name: string;
  function: EAggregationFunctionNew | null;
}

export interface AggregationList {
  name: EAggregationList;
  funcs: AggregationListFunction[];
}
