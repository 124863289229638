import { replacePlaceholders, upperCaseFirstLetter } from '@clearview/helpers';

export const enum EMessageType {
  Error = 'error',
  Success = 'success',
}

type RecordType = Record<string, any>;
type MethodMessagesType = RecordType;

export type entityMethodsMessagesType = Record<string, MethodMessagesType>;

const declensionsFieldPlural = 'м';
const dynamicPlaceholderPrefix = ':';
const dynamicPlaceholderPrefixLength = dynamicPlaceholderPrefix.length;
const dynamicPlaceholderDeclensionPrefix =
  dynamicPlaceholderPrefix + 'declension';
const placeholderUpperCasePostfix = '.u';
const placeholderUpperCasePostfixLength = placeholderUpperCasePostfix.length;

/**
 * Get message for entity apiService method
 * @param {object} entityMethodsMessages - Methods messages
 * @param {string} methodName - Name of apiService method
 * @param {EMessageType} messageType - Type for alerts (later: can be any string)
 * @param {object} entityDeclensions - Declensions for entity
 * @param {object} methodsDefaultMessages - Default messages
 */
export default function getEntityMethodMessage(
  entityMethodsMessages: entityMethodsMessagesType,
  methodName: string,
  messageType: EMessageType,
  methodsDefaultMessages: entityMethodsMessagesType = { _: {} },
  entityDeclensions: Record<string, string | RecordType> = {
    [declensionsFieldPlural]: {},
  }
) {
  /* Extract message info */
  const methodMessageInfo = {
    ...methodsDefaultMessages._,
    ...(entityMethodsMessages[methodName] ||
      methodsDefaultMessages[methodName] ||
      {}),
  };
  const messagePattern = methodMessageInfo[messageType];
  const declensions = methodMessageInfo.isPlural
    ? entityDeclensions[declensionsFieldPlural]
    : entityDeclensions;

  return messagePattern
    ? replacePlaceholders(messagePattern, null, placeholder => {
        /* Remove braces */
        const key = placeholder.slice(1, -1);

        /* Define target & it's field */
        let field;
        let target;
        if (key.startsWith(dynamicPlaceholderDeclensionPrefix)) {
          field = methodMessageInfo[key.slice(dynamicPlaceholderPrefixLength)];
          target = declensions;
        } else {
          field = key;
          target = methodMessageInfo;
        }

        /* Text formatting */
        let value;
        if (key.endsWith(placeholderUpperCasePostfix)) {
          field = field.slice(0, -placeholderUpperCasePostfixLength);
          value = upperCaseFirstLetter(target[field]);
        } else {
          value = target[field].toLowerCase();
        }

        return value;
      })
    : '';
}
