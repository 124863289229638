import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const needRefresh = ref(false);

  return {
    needRefresh,
  };
});
