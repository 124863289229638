/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Breakpoints } from './Breakpoints';
import type { SubGridOpts } from './SubGridOpts';

export type Item = {
    id: string;
    'h': number;
    'i': string;
    'w': number;
    'x': number;
    'y': number;
    moved: boolean;
    pageId: string;
    widgetId: string;
    origX: number;
    origY: number;
    freeMovement: boolean;
    type: Item.type;
    breakpoints?: Breakpoints;
    subGridOpts?: SubGridOpts;
    locked?: boolean;
    zIndex: number;
};

export namespace Item {

    export enum type {
        WIDGET = 'WIDGET',
        ELEMENT = 'ELEMENT',
        GROUP = 'GROUP',
        GROUP_HEADER = 'GROUP_HEADER',
    }


}

