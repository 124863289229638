import { error } from '~packages/bi-logger';
import { initKeycloak, updateToken } from '~packages/keycloak';

import { useAuthStore } from '@/store/bi-store';
import type { IRouterContext } from '@/types/vue-router';
import getConstants from '@bi-core/constants/env';

export async function auth({ next, to }: IRouterContext): Promise<void> {
  const { getVars, isModeDevelopment } = getConstants();
  const envVars = getVars();
  const authStore = useAuthStore();

  authStore.isPublicRoute = false;

  if (
    envVars.VITE_DISABLED_AUTHORIZATION ||
    envVars.VITE_DISABLED_KEYCLOAK ||
    isModeDevelopment
  ) {
    if (!authStore.isLoggedIn) authStore.isLoggedIn = true;
    return next();
  } else {
    if (!authStore.isLoggedIn) {
      try {
        await initKeycloak(to.fullPath);

        authStore.isLoggedIn = true;
        return next();
      } catch (_error) {
        if (_error instanceof Error) {
          error('Keycloak error:', _error.message || error);
        }
      }
    } else {
      await updateToken();

      return next();
    }
  }
}
