/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataSourceElementStructureDto } from './DataSourceElementStructureDto';

export type DataSourceElementPreView = {
    structure: DataSourceElementStructureDto;
    preView: Array<Array<any>>;
    encoding?: DataSourceElementPreView.encoding;
    separator?: DataSourceElementPreView.separator;
    firstLineHeader?: boolean;
};

export namespace DataSourceElementPreView {

    export enum encoding {
        UTF_8 = 'UTF_8',
        UTF_16 = 'UTF_16',
        UTF_32 = 'UTF_32',
        ASCII = 'ASCII',
        WINDOWS_1251 = 'WINDOWS_1251',
        MACCYRILLIC = 'MACCYRILLIC',
        KOI8_R = 'KOI8_R',
    }

    export enum separator {
        COMMA = 'COMMA',
        SEMICOLON = 'SEMICOLON',
        TAB = 'TAB',
        PIPE = 'PIPE',
        SPACE = 'SPACE',
    }


}

