<template>
  <div
    class="cvm-header mks-shadow-1 mks-flex mks-row mks-justify-between mks-items-center"
  >
    <IconNew
      v-if="isDashboardHeader"
      @click="backToDashboardList"
      value="arrow-long-left"
      class="cvm-header__icon-back"
    />
    <div id="dashboard-title" class="cvm-header__text">{{ headerText }}</div>
    <slot name="menu">
      <CvmProfileMenu @update-menu-state="handleMenuClick" />
    </slot>
  </div>

  <div
    v-if="isMenuVisible"
    class="cvm-header__overlay absolute mks-full-width"
  ></div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { useRouter } from 'vue-router';

import CvmProfileMenu from '@/bi-views/views/components/mobile/CvmProfileMenu';
import { CVM_ROOT_NAME } from '@bi-router/routes.json';
import { IconNew } from '@clearview/ui';

interface CvmHeaderProps {
  headerText?: string;
  isDashboardHeader?: boolean;
}
defineProps<CvmHeaderProps>();

const isMenuVisible = ref(false);
const handleMenuClick = val => (isMenuVisible.value = val);

const router = useRouter();
const backToDashboardList = async () => {
  const name = CVM_ROOT_NAME;
  await router.push({ name });
};
</script>

<style lang="scss">
@import './CvmHeader';
</style>
