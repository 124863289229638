import { warn } from '~packages/bi-logger';

import getConstants from '@bi-core/constants/env';

import {
  Mode,
  RequestMethod,
  RestStandardModuleApiService,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'FormModelApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
  needInit: isModeDevelopment,
};

export default class FormModelApiService extends RestStandardModuleApiService {
  constructor() {
    super(constructorParams);
  }

  async fetchAll(params = null, type = null) {
    try {
      const apiServiceResponse = await super._sendRequest(
        this.pathFetchAll,
        params,
        { method: this.requestMethodFetchAll },
        { method: RequestMethod.fetchAll }
      );
      let dictionariesForms = apiServiceResponse.response;
      if (!dictionariesForms) warn('ERROR! Empty dictionariesForms');

      if (!Array.isArray(dictionariesForms)) {
        dictionariesForms = dictionariesForms.data || dictionariesForms.content;
      }

      return {
        errors: apiServiceResponse.errors,
        response: type === 'fetch' ? dictionariesForms[0] : dictionariesForms,
        status: apiServiceResponse.status,
      };
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async updateById(id, data) {
    const response = await this._sendRequest(
      this.pathAdd + '/' + id,
      null,
      { body: JSON.stringify(data), method: RequestMethod.PUT },
      { method: RequestMethod.PUT }
    );
    return response;
  }

  async fetch(id) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this._sendRequest(
        this.pathFetch + id,
        null,
        {},
        { dataOptions: { id }, method: RequestMethod.GET }
      );
      const model = apiServiceResponse.response;
      if (!model) warn('ERROR! Empty model');

      return {
        errors: apiServiceResponse.errors,
        response: model,
        status: apiServiceResponse.status,
      };
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async fetchByModelId(modelId) {
    const response = await this.fetchAll({ modelId }, 'fetch');
    return response;
  }

  async deleteByModelId(modelId) {
    const dictionaryForm = await this.fetchAll({ modelId }, 'fetch');
    const dictionaryFormId = dictionaryForm?.response.id;
    const response = await this.delete(dictionaryFormId);
    return response;
  }

  async updateByModelId(modelId, data) {
    const dictionaryForm = await this.fetchAll({ modelId }, 'fetch');
    const id = dictionaryForm?.response.id;
    const response = await this.updateById(id, { ...data, modelId });
    return response;
  }
}
