import { useSettingsModel } from './useSettingsModel';

export function useSettings(model = {}) {
  const settingsModel = useSettingsModel(model);
  if (!settingsModel) return {};
  const settings = settingsModel.settings;
  const tables = settingsModel.settings[0]?.tables;
  return {
    id: model.id,
    name: model.name,
    modelId: model.modelId,
    database: settings[0]?.name,
    table: tables[0]?.name,
    fields: tables[0]?.fields,
    schema: model,
  };
}
