/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdditionalFieldDto } from './AdditionalFieldDto';

export type TableSchemaColumnDto = {
    name?: string;
    source?: string;
    sourceIndex?: number;
    sourceId?: string;
    type?: TableSchemaColumnDto.type;
    dataType?: TableSchemaColumnDto.dataType;
    unit?: string;
    nullable?: boolean;
    human_name?: string;
    additional_fields?: Array<AdditionalFieldDto>;
};

export namespace TableSchemaColumnDto {

    export enum type {
        VALUE = 'VALUE',
        DIMENSION = 'DIMENSION',
    }

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        DATE = 'DATE',
    }


}

