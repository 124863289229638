import { EWidgetType } from '@/types';

import { ETypes } from './imageTypes';

export const defaultSettings = {
  [ETypes.Image]: {
    title: 'Изображение',
    name: 'Изображение',
    type: EWidgetType.Image,
    subtype: 'image',
    icon: {
      name: 'template-chart-image',
    },
    imageId: null,
    imageCropId: null,
    imageFit: 'contain',
  },
};
