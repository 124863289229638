import { EAggregationFunction } from '@/types';

import colorThemes from './colorThemes.json';

export * from './chartTypes';
export * from './validSchema';

const aggregationFunctionsNone = [
  {
    icon: 'function-off',
    name: 'Без функции',
    function: null,
  },
];

const aggregationFunctionsSUMAVG = [
  {
    icon: 'sigma',
    name: 'Сумма',
    function: EAggregationFunction.Sum,
  },
  {
    icon: 'chart-line',
    name: 'Среднее',
    function: EAggregationFunction.Avg,
  },
];

const aggregationFunctionsMINMAX = [
  {
    icon: 'minimum',
    name: 'Минимум',
    function: EAggregationFunction.Min,
  },
  {
    icon: 'maximum',
    name: 'Максимум',
    function: EAggregationFunction.Max,
  },
];

const aggregationFunctionsCount = [
  {
    icon: 'number-records',
    name: 'Количество',
    function: EAggregationFunction.Count,
  },

  {
    icon: 'number-unique',
    name: 'Уникальные значения',
    function: EAggregationFunction.Uniq,
  },
];

const aggregationFunctionsDefault = [
  ...aggregationFunctionsSUMAVG,
  ...aggregationFunctionsCount,
  ...aggregationFunctionsMINMAX,
];

const aggregationFunctionsAll = [
  ...aggregationFunctionsNone,
  ...aggregationFunctionsDefault,
];

const positionsToShow = [
  {
    name: '10 позиций',
    value: '10',
  },
  {
    name: '50 позиций',
    value: '50',
  },
  {
    name: '100 позиций',
    value: '100',
  },
  {
    name: '500 позиций',
    value: '500',
  },
  {
    name: '1000 позиций',
    value: '1000',
  },
];

export {
  colorThemes,
  aggregationFunctionsNone,
  aggregationFunctionsSUMAVG,
  aggregationFunctionsMINMAX,
  aggregationFunctionsCount,
  aggregationFunctionsDefault,
  aggregationFunctionsAll,
  positionsToShow,
};
