import { SearchParams } from '@/types';

export function getURLSearchParamsWithArray(searchParams: SearchParams) {
  const params = new URLSearchParams();
  for (const key in searchParams) {
    const val = searchParams[key];
    if (Array.isArray(val)) {
      val.forEach(el => {
        params.append(key, el);
      });
    } else {
      params.append(key, val);
    }
  }
  return params;
}
