import { download } from '@clearview/helpers';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  RequestMethod,
} from '../../../__base/client';
import type {
  IEntityBaseApiServiceParams,
  InterceptorRequest,
  InterceptorResponse,
} from './../../../__base/client';

const basePathsSet = new Set<string>();

let fileName: string | null = null;
let formData: FormData | null = null;

const requestInterceptor: InterceptorRequest = ({ method, options, url }) => {
  if (method !== 'upload' || !formData) return options;

  for (const basePath of basePathsSet) {
    if (url.startsWith(basePath)) {
      delete options.headers['Content-Type'];
      options.body = formData;
      return options;
    }
  }

  return options;
};

const responseInterceptor: InterceptorResponse = async ({
  data,
  method,
  response,
}) => {
  if (
    (method === 'GET' || method === 'fetch') &&
    response.headers.get('content-type') === 'application/octet-stream'
  ) {
    const disposition = response.headers
      .get('content-disposition')
      ?.split('filename=')[1]
      .split(';')[0]
      .replaceAll('"', '');
    const fileName = decodeURI(disposition || '');
    const data = await response.blob();
    return { response: { data, name: fileName }, status: true, errors: [] };
  } else if (
    method !== 'download' ||
    !response.headers.get('content-disposition')
  )
    return data;
  if (fileName === null) {
    const contentHeader = response.headers.get('content-disposition');
    const disposition = contentHeader.split("'")[2].replace(/\.[^/.]+$/, '');
    const fileExtension = contentHeader.split('.')[1];
    fileName = decodeURI(disposition) + `.${fileExtension}`;
  }
  const blob = await response.blob();
  await download(blob, fileName);
};

export default class FileModuleApiService extends BiBeeRestStandardApiService {
  constructor(params: IEntityBaseApiServiceParams) {
    super(params);
    basePathsSet.add(this.basePath);
  }

  async download(
    data: unknown,
    path = this.pathFetchAll,
    params = {},
    name = null,
    options: IRestOptions = {}
  ) {
    fileName = name;
    const response = await this.sendRequest(
      path,
      params,
      data !== null
        ? { body: JSON.stringify(data), method: RequestMethod.POST }
        : { body: null, method: RequestMethod.GET },
      { method: 'download', ...options }
    );
    fileName = null;
    return response;
  }

  async upload(
    data: FormData,
    path = this.pathFetchAll,
    params = {},
    options: IRestOptions = {}
  ) {
    formData = data;
    const response = await this.sendRequest(
      path,
      params,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: 'upload', ...options }
    );
    formData = null;
    return response;
  }
}

FileModuleApiService.register(requestInterceptor);
FileModuleApiService.register(responseInterceptor, true);
