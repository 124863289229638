import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useDashboardWidgetStore = defineStore('dashboardWidget', () => {
  const lastCreatedWidget = ref(null);

  const setLastWidget = w => {
    lastCreatedWidget.value = w;
  };

  return {
    setLastWidget,
    lastCreatedWidget,
  };
});
