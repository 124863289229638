import { emptyFact, emptySerie, nameTotal, separator } from './constants';
import {
  IOptionsText,
  convertObjectToArray,
  getWrapText,
  hasOwnProperty,
} from './helpers';
import {
  EType,
  IAnalyticsJSON,
  IEncodeIndicator,
  IOptions,
  TData,
  TDataValue,
  TEncodeAnalityc,
  TIndicatorName,
  TSet,
  TSource,
} from './types';

class AnalitycsJSON {
  readonly type: EType;
  readonly analyticsJSON: IAnalyticsJSON;
  readonly optionsText?: IOptionsText;

  constructor(
    type: EType,
    analyticsJSON: IAnalyticsJSON,
    optionsText?: IOptionsText
  ) {
    this.type = type;
    this.analyticsJSON = analyticsJSON;
    this.optionsText = optionsText;
  }

  static getEmptySerie() {
    return emptySerie;
  }

  static getEmptyFact() {
    return emptyFact;
  }

  static getSeparatorIndicator(): string {
    return separator;
  }

  public getValueDimension(value: TDataValue) {
    return value;
  }

  public addAllIndicators(
    source: TSource,
    arrayActiveIndicators: Array<IEncodeIndicator>
  ) {
    source.forEach(record => {
      arrayActiveIndicators.forEach(activeIndictor => {
        if (!hasOwnProperty.call(record, activeIndictor.name)) {
          record[activeIndictor.name] = AnalitycsJSON.getEmptyFact();
        }
      });
    });
  }

  public convertActiveIndicatorsToArray(
    activeIndicators: Record<string, IEncodeIndicator>
  ) {
    return convertObjectToArray<IEncodeIndicator>(activeIndicators);
  }

  public convert(data: TData, options?: IOptions) {
    const source: TSource = [];
    const analitycs = this.analyticsJSON.analitycs;
    const indicators = this.analyticsJSON.indicators;
    const lengthIndicators = indicators.length;
    const activeIndicators: Record<string, IEncodeIndicator> = {};
    const activeAnalitycs: Record<string, TEncodeAnalityc> = {};

    const mapAnalitycsSet = new Map<string, TSet>();

    const _emptyFact = AnalitycsJSON.getEmptyFact();
    const _emptySerie = AnalitycsJSON.getEmptySerie();

    data.forEach(record => {
      let set: TSet = {};
      let analitycsHash = '';
      analitycs.forEach(dimension => {
        if (hasOwnProperty.call(record, dimension)) {
          const valueDimension = this.getValueDimension(record[dimension]);

          set[dimension] = valueDimension;
          analitycsHash += valueDimension + ',';
          if (options?.isTotal) {
            set[nameTotal] = 0;
          }
          if (hasOwnProperty.call(activeAnalitycs, dimension)) {
            activeAnalitycs[dimension].add(valueDimension);
          } else activeAnalitycs[dimension] = new Set();
        }
      });
      analitycsHash = analitycsHash.slice(0, -1);
      const analitycSet = mapAnalitycsSet.get(analitycsHash);
      // свернуть данные в разрезе аналитик
      if (options?.isRollupData) {
        if (!analitycSet) {
          mapAnalitycsSet.set(analitycsHash, set);
        } else set = analitycSet;
      }
      indicators.forEach(indicator => {
        const serie = indicator?.serie || _emptySerie;
        const fact = indicator?.fact || _emptyFact;
        let name: TIndicatorName = indicator?.name || fact || serie;
        if (hasOwnProperty.call(record, serie)) {
          const valueSerie: TDataValue = record[serie];
          name =
            lengthIndicators > 1
              ? String(
                  valueSerie +
                    AnalitycsJSON.getSeparatorIndicator() +
                    indicator?.alias
                )
              : String(valueSerie);
          set[name] = _emptyFact;
        }
        if (hasOwnProperty.call(record, fact)) {
          const value = record[indicator.fact];
          set[name] = value;
          if (options?.isTotal) {
            if (
              typeof value === 'number' &&
              typeof set[nameTotal] === 'number'
            ) {
              set[nameTotal] += value;
            }
          }
        }
        for (const analityc in activeAnalitycs) {
          name = String(name);
          activeIndicators[name] = { name, analityc, serie };
        }
      });
      // свернуть данные в разрезе аналитик
      if (options?.isRollupData) {
        if (!analitycSet) {
          source.push(set);
        }
      } else source.push(set);
    });

    const arrayActiveIndicators: Array<IEncodeIndicator> =
      this.convertActiveIndicatorsToArray(activeIndicators);

    if (options?.isAddAllIndicators) {
      this.addAllIndicators(source, arrayActiveIndicators);
    }

    return {
      source,
      indicators: arrayActiveIndicators,
      analitycs: activeAnalitycs,
    };
  }
}

export { AnalitycsJSON, getWrapText };

export * from './types';
