import { IEntityBaseApiServiceParams } from '@/api/client';
import getConstants from '@bi-core/constants/env';

import { BiBeeRestStandardApiService, Mode } from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'DashboardFilterApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class DashboardFilterApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }
}
