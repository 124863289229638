import { InjectionKey, Ref } from 'vue';

import { WidgetStructureField } from '@/types';

export const PROVIDE__DATA_TABLE_PROPS = 'data-table-props';
export const PROVIDE__Q_TABLE_PROPS = 'q-table-props';
export const PROVIDE__Q_TABLE_UPDATES = 'q-table-updates';

export const PROVIDE__CHART_HOVER_ELEMENT = 'hoverElement';
export const PROVIDE__CHART_CHANGE_ACTIVE_ITEMS = 'changeActiveItems';
export const PROVIDE__CHART_SETTINGS_DRAG_PAYLOAD = 'dragPayload';

export const PROVIDE__DRAGGABLE_CONTEXT = 'draggableContext';
export const PROVIDE__RELATIONS_FILTER = 'relationsFilter';

export const PROVIDE__CURRENT_FIELD_STRUCTURE: InjectionKey<
  Ref<WidgetStructureField>
> = Symbol('settingsFieldStructure');
