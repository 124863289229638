import { loadModules } from '@clearview/helpers';

let loadModulesApiServices = () => {
  console.log('loadModulesApiServices plug :)');
};

try {
  loadModulesApiServices = () =>
    loadModules(import.meta.globEager('./*.ts'), fileName =>
      fileName.slice(2, -3)
    );
} catch (error) {
  console.log(error);
}

const apiServicesImported = loadModulesApiServices();

export default apiServicesImported;
