/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataSourceElementShortWithModelIdResponse } from './DataSourceElementShortWithModelIdResponse';

export type ConnectionWithDataSourceElementsResponse = {
    connectionId: string;
    name: string;
    connectionType: ConnectionWithDataSourceElementsResponse.connectionType;
    dataSourceElements: Array<DataSourceElementShortWithModelIdResponse>;
};

export namespace ConnectionWithDataSourceElementsResponse {

    export enum connectionType {
        XLSX = 'XLSX',
        CSV = 'CSV',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

