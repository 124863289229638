import numeral from 'numeral';

import { EChartFormatting } from '@/types';

import { getDigits } from './getDigits';
import { getRounding } from './getRounding';

// numeral.register('locale', 'bi', {
//   delimiters: {
//     thousands: ' ',
//     decimal: ',',
//   },
//   abbreviations: {
//     thousand: 'тыс.',
//     million: 'млн.',
//     billion: 'млрд.',
//     trillion: 'трлн.',
//   },
//   ordinal: function () {
//     return '.';
//   },
//   currency: {
//     symbol: 'руб.',
//   },
// });

// numeral.locale('bi');

export interface IOptions {
  digits?: number;
  rounding?: number;
}

const space = (text: string) => {
  return text ? ` ${text}` : '';
};

export function getFormatValue(
  value: number,
  formatting: EChartFormatting = EChartFormatting.Space,
  options?: IOptions
): string {
  const rounding = getRounding(options?.rounding);
  const digits = getDigits(options?.digits);
  let format = '0,0';
  if (formatting === 'space') {
    format = '0,0';
  } else if (formatting === 'percent') {
    format = '%';
  } else if (formatting === 'unit') {
    format = ' a';
  } else format = ' ';

  const allFormating = `${format}${space(rounding)}${space(digits)}`;
  // https://github.com/adamwdraper/Numeral-js/issues/596
  const formattingValue: string =
    options?.digits && value > 0 && value / options?.digits < 1e-6
      ? numeral(value * 1000)
          .format(allFormating)
          .replace('0,0', '0,0000')
      : numeral(value).format(allFormating);

  return formattingValue;
}
