import { warn } from '~packages/bi-logger';

import { IError, SearchParams } from '../__base/BaseApiService';
import EntityBaseApiService, {
  IEntityBaseApiServiceParams,
  IFetchDataObject,
  Method,
  Mode,
} from '../__base/EntityBaseApiService';

export { Mode };

export interface IRestOptions {
  sharedId?: string | number;
  skipStoreFetch?: boolean;
}

export interface IRestFetchDataObject extends IFetchDataObject, IRestOptions {}

export interface IBiBeeRestResponse<T> {
  response: T | null;
  errors: IError[];
  status: boolean;
  statusCode?: number;
  baseResponse: Response | null;
}

export default class BiBeeRestStandardApiService extends EntityBaseApiService {
  isHeadersEnabled: boolean;
  isLogEnabled: boolean;

  constructor(
    constructorParams: IEntityBaseApiServiceParams,
    isHeadersEnabled = true,
    isLogEnabled = false
  ) {
    super(constructorParams);
    this.isHeadersEnabled = isHeadersEnabled;
    this.isLogEnabled = isLogEnabled;
  }

  add<TResponse>(data: unknown, options: IRestOptions = {}) {
    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      this.sendRequest<TResponse>(
        this.pathAdd,
        null,
        { body: JSON.stringify(data), method: this.requestMethodAdd },
        { method: Method.add, ...options }
      )
        .then(responseData => {
          resolve(responseData);
        })
        .catch(error => {
          this._log('ERROR add: ' + error.message);
          reject(error);
        });
    });
  }

  delete<TResponse>(id: string | number, options: IRestOptions = {}) {
    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      this.sendRequest<TResponse>(
        this.pathDelete + id,
        null,
        { method: this.requestMethodDelete },
        { method: Method.delete, ...options }
      )
        .then(responseData => {
          resolve(responseData);
        })
        .catch(error => {
          this._log('ERROR delete: ' + error.message);
          reject(error);
        });
    });
  }

  fetch<TResponse>(id?: string | number, options: IRestOptions = {}) {
    if (!id) {
      const response: IBiBeeRestResponse<null> = {
        baseResponse: null,
        errors: [this._getErrorDescription('1')],
        response: null,
        status: false,
      };
      return Promise.reject(response);
    }

    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      this.sendRequest<TResponse>(
        this.pathFetch + id,
        null,
        { method: this.requestMethodFetch },
        { dataOptions: { id }, method: Method.fetch, ...options }
      )
        .then(responseData => {
          if (!responseData.response) warn(`ERROR! Empty ${this.entityName}`);
          resolve(responseData);
        })
        .catch(error => {
          this._log('ERROR fetch: ' + error.message);
          reject(error);
        });
    });
  }

  fetchAll<TResponse>(params?: SearchParams, options: IRestOptions = {}) {
    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      this.sendRequest<TResponse>(
        this.pathFetchAll,
        params,
        { method: this.requestMethodFetchAll },
        { method: Method.fetchAll, ...options }
      )
        .then(responseData => {
          if (!responseData.response) warn(`ERROR! Empty ${this.entityName}`);
          resolve(responseData);
        })
        .catch(error => {
          this._log('ERROR fetchAll: ' + error.message);
          reject(error);
        });
    });
  }

  update<TResponse, TData>(
    data: TData,
    id: string | number,
    options: IRestOptions = {}
  ) {
    const pathUpdate = this.pathUpdate;
    const pathUpdateLength = pathUpdate.length;
    const path =
      pathUpdate +
      (pathUpdate[pathUpdateLength - 1] === '/'
        ? id || data[this.itemFieldId]
        : '');

    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      this.sendRequest<TResponse>(
        path,
        null,
        { body: JSON.stringify(data), method: this.requestMethodUpdate },
        { method: Method.update, ...options }
      )
        .then(responseData => {
          resolve(responseData);
        })
        .catch(error => {
          this._log('ERROR update: ' + error.message);
          reject(error);
        });
    });
  }

  sendRequest<TResponse>(
    path: string,
    params?: SearchParams,
    options: RequestInit = { headers: {} },
    fetchDataObject: IRestFetchDataObject = {}
  ) {
    const headers = this.isHeadersEnabled
      ? this.getHeaders(this.basePath + path)
      : {};
    const headersAdded = Object.keys(headers).length;

    if (headersAdded) {
      options.headers = {
        ...(options.headers || {}),
        ...headers,
      };
    }

    if (fetchDataObject.sharedId) {
      this.basePath = this.getSharedPath(fetchDataObject.sharedId);
    }
    return new Promise<IBiBeeRestResponse<TResponse>>((resolve, reject) => {
      super
        ._sendRequest<TResponse>(path, params, options, fetchDataObject)
        .then(apiServiceResponse => {
          resolve({
            response: apiServiceResponse.response?.response || null,
            errors: apiServiceResponse.response?.errors || [],
            status: apiServiceResponse.response?.status || false,
            statusCode: apiServiceResponse.statusCode,
            baseResponse: apiServiceResponse._response,
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  protected getHeaders(reqUrl) {
    const isLogEnabled = this.isLogEnabled;

    isLogEnabled && console.group('getHeaders');

    const headers: Record<string, any> = { Accept: undefined };

    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';

    isLogEnabled &&
      this._log(
        '%c getHeaders ',
        'background: yellow; color: #000;',
        'reqUrl',
        reqUrl,
        'headers',
        headers
      );
    isLogEnabled && console.groupEnd();

    return headers;
  }

  protected getSharedPath(sharedId: string | number) {
    return (
      this.entityApiPath.modulePath +
      '/public/' +
      sharedId +
      this.entityApiPath.path
    );
  }
}
