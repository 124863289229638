/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionRequestDto = {
    name: string;
    connectionType: ConnectionRequestDto.connectionType;
    projectId: string;
    dataSourceElementsIds: Array<string>;
    dataSourceIds: Array<string>;
    description?: string;
};

export namespace ConnectionRequestDto {

    export enum connectionType {
        XLSX = 'XLSX',
        CSV = 'CSV',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

