/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Delta } from './Delta';

export type Name = {
    textContent: string;
    delta: Array<Delta>;
    alignment?: Name.alignment;
};

export namespace Name {

    export enum alignment {
        BASELINE = 'baseline',
        CENTER = 'center',
        END = 'end',
    }


}

