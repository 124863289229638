import { IEntityBaseApiServiceParams } from '@/api/client';
import type {
  OuterDataSource,
  OuterDataSourceCheck,
  OuterDataSourceRequest,
  SearchParams,
} from '@/types';
import getConstants from '@bi-core/constants/env';

import { IRestOptions, Mode, RequestMethod } from '../../__base/client';
import FileModuleApiService from './__modules/FileModuleApiService';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'DataSourceApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class DataSourceApiService extends FileModuleApiService {
  constructor() {
    super(constructorParams);
  }

  addFile(file: File, params: SearchParams = {}, options?: IRestOptions) {
    const body = new FormData();
    body.append('file', file);
    const uri = this.pathFetchAll + '/file';
    return this.upload(body, uri, params, options);
  }

  addOuter(
    data: OuterDataSourceRequest,
    params: SearchParams = {},
    options?: IRestOptions
  ) {
    return this.sendRequest<OuterDataSource>(
      '/outer',
      params,
      {
        body: JSON.stringify(data),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }

  checkOuter(
    data: OuterDataSourceRequest,
    params: SearchParams = {},
    options?: IRestOptions
  ) {
    return this.sendRequest<OuterDataSourceCheck>(
      '/outer/check',
      params,
      {
        body: JSON.stringify(data),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchAllByIds<TResponse>(ids: Array<string>, options?: IRestOptions) {
    return this.sendRequest<TResponse>(
      this.pathAdd,
      null,
      {
        body: JSON.stringify({
          ids,
        }),
        method: RequestMethod.POST,
      },
      { method: RequestMethod.POST, ...options }
    );
  }

  fetchAllElements<TResponse>(id: string, options?: IRestOptions) {
    return this.sendRequest<TResponse>(
      this.pathFetch + id + '/elements',
      null,
      {},
      { dataOptions: { id }, method: RequestMethod.GET, ...options }
    );
  }

  updateElements<TResponse>(id: string, options?: IRestOptions) {
    return this.sendRequest<TResponse>(
      this.pathFetch + id + '/elements',
      null,
      { body: null, method: RequestMethod.PUT },
      { method: RequestMethod.PUT, ...options }
    );
  }

  fetchShema<TResponse>(id: string, options?: IRestOptions) {
    const uri = '/elements/schema/' + id;
    return this.sendRequest<TResponse>(
      uri,
      null,
      {},
      { dataOptions: { id }, method: RequestMethod.GET, ...options }
    );
  }
}
