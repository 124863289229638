import comparisonInfo from './comparisonInfo.json';
import examples from './examples';

const comparisonTypes: Record<string, string> = {};

const comparisonInfoProcessed = {};
for (const comparison in comparisonInfo) {
  if (comparison.startsWith('_')) continue;
  comparisonInfoProcessed[comparison] = comparisonInfo[comparison];
}

const operatorComparisonMap = {};
for (const comparison in comparisonInfoProcessed) {
  const operator = comparisonInfo[comparison].operator;
  comparisonTypes[comparison] = operator;
  operatorComparisonMap[operator] = comparisonInfoProcessed[comparison];
}

export {
  comparisonInfoProcessed as comparisonInfo,
  comparisonTypes,
  operatorComparisonMap,
};

function processValue(value) {
  return typeof value === 'string' ? `'${value}'` : value;
}

function processValues(values) {
  return values
    .reduce((str, value) => str + processValue(value) + ', ', '')
    .slice(0, -2);
}

export function createWhereQueryPart(field, value, comparison) {
  let queryPart = field + ' ' + comparison + ' ';

  if (comparison === comparisonTypes.between) {
    // +
    queryPart += processValue(value[0]) + ' AND ' + processValue(value[1]);
  } else if (comparison === comparisonTypes.contains) {
    // +
    queryPart += `'%${value}%'`;
  } else if (comparison === comparisonTypes.includes) {
    // +
    queryPart +=
      Array.isArray(value) && value.length > 1
        ? '(' + processValues(value) + ')'
        : processValue(value);
  } else if (Array.isArray(value)) {
    /* И по умолчанию - 'IN' / '=' */
    queryPart +=
      value.length > 1
        ? '(' + processValues(value) + ')'
        : processValue(value[0]);
  } else {
    queryPart += processValue(value);
  }

  return queryPart;
}

/* Usage */
const filter = examples['1'];

let usingDemo = true;
usingDemo = false;
if (usingDemo) {
  getQueryFilter(filter);
}

export default function getQueryFilter({
  filterInfo,
  indent = '',
  indentStart = '',
}) {
  /*  */
  const indentNested = indentStart + indent;

  /* Result */
  let queryFilter = '';

  /*  */
  const filtersValue = filterInfo.value;
  const filtersFieldComparisonMap = filterInfo.fieldComparisonMap;

  Object.keys(filtersValue).forEach((field, fieldIndex) => {
    queryFilter += '\n' + indentNested;

    if (fieldIndex !== 0) {
      queryFilter += 'AND ';
    }

    queryFilter += createWhereQueryPart(
      field,
      filtersValue[field],
      filtersFieldComparisonMap[field]
    );
  });

  return queryFilter.length
    ? (indentStart ? indentStart + 'WHERE'.toLowerCase() : 'WHERE') +
        queryFilter
    : '';
}
