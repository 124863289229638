import {
  getMethods,
  hasOwnProperty,
  queryStringToObject,
} from '@clearview/helpers';

import { IEntityApiPath } from '../../helpers/__api-base/getEntityApiInfo';
import getApiServiceEntityId from './../../helpers/getApiServiceEntityId';
import BaseApiService, {
  IBaseApiService,
  IBaseApiServiceParams,
  IFetchDataObjectBase,
  Mode,
  RequestMethod,
  RequestMethodType,
  SearchParams,
} from './BaseApiService';
import errors from './entity-errors.json';

export { Mode, RequestMethod };

export const enum Method {
  add = 'add',
  delete = 'delete',
  fetch = 'fetch',
  fetchAll = 'fetchAll',
  update = 'update',
}

type MethodType = keyof typeof Method;

export type EntityRequestMethodType = MethodType | RequestMethodType;

export interface IFetchDataObject extends IFetchDataObjectBase {
  method?: EntityRequestMethodType;
  dataOptions?: any;
}

export interface IEntityBaseApiServiceParams extends IBaseApiServiceParams {
  itemFieldId?: string;
  needInit?: boolean;

  pathAdd?: string;
  pathDelete?: string;
  pathFetch?: string;
  pathFetchAll?: string;
  pathUpdate?: string;

  requestMethodAdd?: string;
  requestMethodDelete?: string;
  requestMethodFetch?: string;
  requestMethodFetchAll?: string;
  requestMethodUpdate?: string;

  moduleName?: string;
}

export interface IEntityBaseApiService extends IBaseApiService {
  entityName: string;
  moduleName: string;

  add: (data: any) => Promise<any>;
  delete: (id: number | string) => Promise<any>;
  fetch: (id: string) => Promise<any>;
  fetchAll: (params?: any) => Promise<any>;
  update: (data: any, id: number | string) => Promise<any>;
}

/*  */
const settings = {
  itemFieldId: 'id',

  pathAdd: '',
  pathDelete: '/',
  pathFetch: '/',
  pathFetchAll: '',
  pathUpdate: '/',

  requestMethodAdd: RequestMethod.POST,
  requestMethodDelete: RequestMethod.DELETE,
  requestMethodFetch: RequestMethod.GET,
  requestMethodFetchAll: RequestMethod.GET,
  requestMethodUpdate: RequestMethod.PUT,
};

type ApiSettingsType = Record<string, any>;
export type getEntityApiBaseCb = (entityName: string) => IEntityApiPath;

export default class EntityBaseApiService
  extends BaseApiService
  implements IEntityBaseApiService
{
  static apiSettings: ApiSettingsType = {};
  static appBasePath: string;
  static sseServerUrl: string;

  static getEntityApiBase: getEntityApiBaseCb = entityName => ({
    modulePath: '',
    path: '',
    url: '/api/' + entityName,
  });

  baseParams: Record<string, string>;
  data: any;
  mixinsData: any;
  entityName: string;
  entityApiPath: IEntityApiPath;

  itemFieldId: string;

  moduleName: string;

  pathAdd: string;
  pathDelete: string;
  pathFetch: string;
  pathFetchAll: string;
  pathUpdate: string;

  requestMethodAdd: string;
  requestMethodDelete: string;
  requestMethodFetch: string;
  requestMethodFetchAll: string;
  requestMethodUpdate: string;

  constructor(params: IEntityBaseApiServiceParams = {}) {
    super(params);

    const moduleName = params.moduleName;
    const className = params.className || this.className;
    let entityName: string = getApiServiceEntityId(className);

    if (entityName.startsWith(moduleName + '-'))
      entityName = entityName.slice(moduleName.length + 1);

    /*  */
    this.entityApiPath = EntityBaseApiService.getEntityApiBase(entityName);

    const basePath = this.entityApiPath.url;

    const questionMarkIndex = basePath.indexOf('?');
    if (questionMarkIndex >= 0) {
      this.basePath = basePath.slice(0, questionMarkIndex);
      this.baseParams = queryStringToObject(
        basePath.slice(questionMarkIndex + 1)
      );
    } else {
      this.basePath = basePath;
      this.baseParams = {};
    }

    // this._log('entityName', entityName);
    // this._log('basePath', this.basePath);

    this.entityName = entityName;
    this.moduleName = moduleName;

    this.itemFieldId = params.itemFieldId || settings.itemFieldId;

    this.pathAdd = hasOwnProperty.call(params, 'pathAdd')
      ? params.pathAdd
      : settings.pathAdd;
    this.pathDelete = hasOwnProperty.call(params, 'pathDelete')
      ? params.pathDelete
      : settings.pathDelete;
    this.pathFetch = hasOwnProperty.call(params, 'pathFetch')
      ? params.pathFetch
      : settings.pathFetch;
    this.pathFetchAll = hasOwnProperty.call(params, 'pathFetchAll')
      ? params.pathFetchAll
      : settings.pathFetchAll;
    this.pathUpdate = hasOwnProperty.call(params, 'pathUpdate')
      ? params.pathUpdate
      : settings.pathUpdate;

    this.requestMethodAdd =
      params.requestMethodAdd || settings.requestMethodAdd;
    this.requestMethodDelete =
      params.requestMethodDelete || settings.requestMethodDelete;
    this.requestMethodFetch =
      params.requestMethodFetch || settings.requestMethodFetch;
    this.requestMethodFetchAll =
      params.requestMethodFetchAll || settings.requestMethodFetchAll;
    this.requestMethodUpdate =
      params.requestMethodUpdate || settings.requestMethodUpdate;

    this.ERRORS = errors;
    this.mixinsData = null;
    if (params.needInit) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.init();
    }
  }

  static registerGetEntityApiBase(cb: getEntityApiBaseCb) {
    EntityBaseApiService.getEntityApiBase = cb;
  }

  static setApiSettings(apiSettings: ApiSettingsType) {
    EntityBaseApiService.apiSettings = apiSettings;
  }

  static setAppBasePath(appBasePath: string) {
    EntityBaseApiService.appBasePath = appBasePath;
  }

  static setSseServerUrl(sseServerUrl: string) {
    EntityBaseApiService.sseServerUrl = sseServerUrl;
  }

  async add(data: unknown): Promise<unknown> {
    return await this._sendRequest(
      this.pathAdd,
      null,
      { body: JSON.stringify(data), method: this.requestMethodAdd },
      { method: Method.add }
    );
  }

  async delete(id: unknown): Promise<unknown> {
    return await this._sendRequest(
      this.pathDelete + id,
      null,
      { method: this.requestMethodDelete },
      { method: Method.delete }
    );
  }

  async fetch(id: unknown): Promise<unknown> {
    if (!id) {
      return {
        _response: null,
        errors: [this._getErrorDescription('1')],
        response: null,
        status: false,
      };
    }

    return await this._sendRequest(
      this.pathFetch + id,
      null,
      { method: this.requestMethodFetch },
      { dataOptions: { id }, method: Method.fetch }
    );
  }

  async fetchAll(params: unknown): Promise<unknown> {
    return await this._sendRequest(
      this.pathFetchAll,
      params,
      { method: this.requestMethodFetchAll },
      { method: Method.fetchAll }
    );
  }

  async update(data: unknown, id: unknown): Promise<unknown> {
    const pathUpdate = this.pathUpdate;
    const pathUpdateLength = pathUpdate.length;
    // console.log(data);
    // console.log(this.itemFieldId);
    return await this._sendRequest(
      pathUpdate +
        (pathUpdate[pathUpdateLength - 1] === '/'
          ? id || data[this.itemFieldId]
          : ''),
      null,
      { body: JSON.stringify(data), method: this.requestMethodUpdate },
      { method: Method.update }
    );
  }

  /* Setters */

  /* Technical public */
  init(/* data?: any */) {
    // this.data = data;

    this.data = {};

    if (hasOwnProperty.call(this.data, '_merge')) {
      this.mixinsData = this.data._merge;
    }
    // }
  }

  sendRequest(..._args: any[]): Promise<any> {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      _response: null,
      errors: [this._getErrorDescription('2')],
      response: null,
      status: false,
    });
  }

  /* Technical protected */
  async _sendRequest<TResponse>(
    path: string,
    params?: SearchParams,
    options?: RequestInit,
    fetchDataObject: IFetchDataObject = {
      data: undefined,
      method: undefined,
      timeout: 10000,
    }
  ) {
    const method = fetchDataObject.method;
    let data = fetchDataObject.data;

    try {
      if (!data && method) {
        // _eslint-disable-next-line prettier/prettier
        data = this.getData(method);
        const id =
          (fetchDataObject.dataOptions && fetchDataObject.dataOptions.id) ||
          undefined;

        if (data) {
          if (id && method === Method.fetch && hasOwnProperty.call(data, id)) {
            data = data[id];
          }
        } else {
          switch (method) {
            case Method.add:
              data = JSON.parse(options.body);
              break;
            case Method.delete:
              data = true;
              break;
            case Method.fetch: {
              // _eslint-disable-next-line prettier/prettier
              if (id) {
                if (!data) {
                  data = this.getData(Method.fetch);
                }

                data = this.getData(Method.fetchAll);
                data =
                  data && id
                    ? Array.isArray(data)
                      ? data.find(item => item[this.itemFieldId] === id)
                      : data[id]
                    : null;
              }
              break;
            }
            case Method.fetchAll:
              data = this.data;
              break;
            case Method.update:
              data = true;
              break;
          }
        }
      }
    } catch (error) {
      this._logError({ error, method: method || 'sendRequest' });
    }

    const response = await super.sendRequest<TResponse>(
      path,
      {
        ...this.baseParams,
        ...(params || {}),
      },
      options,
      {
        ...fetchDataObject,
        data,
      }
    );

    /* TODO: fix */
    const sseServerUrl = EntityBaseApiService.sseServerUrl;

    if (sseServerUrl && response.status) {
      if (
        method === Method.add ||
        method === Method.delete ||
        method === Method.update
      ) {
        const payloadForSseServer: Record<string, any> = {
          entityId: this.entityName,
          method: method,
          payload: data,
        };

        if (method === Method.delete) {
          payloadForSseServer.payload = { id: path.slice(1) };
        } else if (method === Method.add) {
          payloadForSseServer.payload = response?.response?.response?.data[0];
        }

        // eslint-disable-next-line
        fetch(sseServerUrl, {
          body: JSON.stringify(payloadForSseServer),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
      }
    }

    return response;
  }

  /* Technical private */
  // _eslint-disable-next-line prettier/prettier
  getData(field: string) {
    let data = null;

    if (this.data && hasOwnProperty.call(this.data, field)) {
      data = this.data[field];

      if (hasOwnProperty.call(data, 'response')) {
        data = data.response || data;
      }
    }

    return data;
  }

  _getMergeData(data, field) {
    return data;
  }
}

const entityBaseApiService = new EntityBaseApiService();
export const technicalMethods = ['getData', /* 'init',  */ 'sendRequest'];
export const baseMethods = getMethods(entityBaseApiService).filter(
  m => m[0] !== '_' && !technicalMethods.includes(m)
);
