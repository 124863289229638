import createColumn from './createColumn';

export default function processColumns(columns) {
  return columns.map(column => processColumn(column, 0));
}

export function processColumn(column, level) {
  const additionalFields = column.additional_fields;
  const col = createColumn(column, level);

  if (!additionalFields?.length) return col;

  col.additionalFields = additionalFields.map(field =>
    processColumn(field, level + 1)
  );
  return col;
}
