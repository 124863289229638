import { ETypeColumn } from '@/types';

export default function createColumn(column, level) {
  const newlineRegExp = /\n/g;
  return {
    name: column.name,
    source: column.source,
    nullable: column.nullable,
    sourceId: column.sourceId,
    sourceIndex: column.sourceIndex,
    type: column.type || ETypeColumn.Dimension,
    humanName: column.human_name.replace(newlineRegExp, ' '),
    dataType: column.dataType,
    columnType: column.columnType,
    level,
  };
}
