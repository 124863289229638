/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionFieldDto = {
    id?: string;
    name?: string;
    type?: ConditionFieldDto.type;
    dataType?: ConditionFieldDto.dataType;
    aggregation?: string;
};

export namespace ConditionFieldDto {

    export enum type {
        VALUE = 'VALUE',
        DIMENSION = 'DIMENSION',
    }

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        DATE = 'DATE',
    }


}

