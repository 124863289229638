/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ColumnModelPostgresDto = {
    id?: string;
    name?: string;
    alias?: string;
    index?: number;
    dataType?: ColumnModelPostgresDto.dataType;
    isNullable?: boolean;
};

export namespace ColumnModelPostgresDto {

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

