import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import omit from 'lodash.omit';
import type { IRestOptions } from 'src/api/client';
import { error, log } from '~packages/bi-logger';

import {
  EAggregationFunction,
  ESortOrder,
  ETypeColumn,
  EWidgetType,
  TAnalyticStructure,
} from '@/types';
import { hasOwnProperty } from '@clearview/helpers';

import {
  comparisonInfo,
  operatorComparisonMap,
} from './getQueryFilter/getQueryFilter';

/* Constants */

const sourceTypes = {
  function: 'function',
  value: 'value',
};

type TSortSource = {
  // Если сортируем по колонке из селекта
  selectColumnId?: string;
  // Если сортируем по ВЧП
  formula?: string;
  // Если сортируем по колонке не из селекта (column_id - Старый вариант)
  sourceAttribute?: string;
  // Если сортируем по колонке не из селекта (id - Новый вариант)
  sourceColumnId?: string;
};

type TSort = {
  order: ESortOrder.Asc | ESortOrder.Desc;
  source: TSortSource;
};

/* Vars */
const sourceTypeFunction = sourceTypes.function;

// TODO проверить, нужно ли это для таблицы и удалить либо перенести в класс для таблицы
const handleJsonFormatProps = props => {
  const handledProps = cloneDeep(props);

  function handleColumns(props) {
    const { columns } = props;
    function handleSource(src) {
      if (src.modelId) {
        delete src.table;
        delete src.attribute;
        delete src.functionParams;
      }
    }

    for (const column of columns) {
      if (column.sources?.length) {
        for (const src of column.sources) {
          handleSource(src);
        }
      } else {
        handleSource(column.source);
      }
    }
    return columns;
  }

  handleColumns(handledProps);
  return handledProps;
};

const sourcesFunctionsToLowerCase = columns => {
  /* Function - lowerCase */
  const stack: any[] = [];
  columns.forEach(col => {
    if (col.type !== ETypeColumn.Value) return;
    const source = col.source;
    if (!source) return;
    stack.push(source);
  });

  const updateSourceFunc = source => {
    const func = source.function;
    if (func) source.function = func.toLowerCase();

    return source.functionParams || [];
  };

  while (stack.length) {
    const source = stack.pop();
    stack.push(...updateSourceFunc(source));
  }
};

const getSourseIdByColumn = (schemaColumns, columnId) => {
  return schemaColumns.find(col => col.name === columnId)?.sourceId;
};

const getJsonFormatForTable = (
  {
    columns,
    dynamics = [],
    columnsLevels = [],
    filters,
    additionalFilters,
    level,
    limit = 100,
    page,
    sort,
    isDetailed,
  },
  schema
) => {
  try {
    const columnsLevelsNew = columnsLevels;
    const schemaColumns = schema.showcaseInfo.columns;
    const modelId = columns[0]?.source.modelId;
    const filterArray = filters.map(f => {
      const filter = {
        comparison: f.comparison,
        modelId,
        value: f.value,
      };
      // проверка для раскрытия строк с ВЧП
      if (f.isCalculated) {
        filter.selectColumnId = f.attribute;
        filter.formula = f.formula;
      } else {
        let filterColumnName = f.field ?? f.attribute;
        Object.values(EAggregationFunction).forEach(f => {
          filterColumnName = filterColumnName.replace(`_${f}`, '');
        });
        const columnId = getSourseIdByColumn(schemaColumns, filterColumnName);
        filter.columnId = columnId;
      }
      return filter;
    });

    const validateColumn = column => {
      const source = column?.source;
      if (source?.function) {
        source.attribute = null;
        source.table = null;
      }
      if (source?.functionParams && Array.isArray(source.functionParams)) {
        source?.functionParams.forEach(validateColumn);
      }
      return column;
    };
    const dateNames = {};
    const _columns = columns.map(item => {
      const column = {
        id: item.id,
        name: item.id,
        source: {
          ...omit(get(item, 'source', {}), [
            'dataType',
            'isDynamic',
            'dynamicMode',
          ]),
          // TODO в новом конструкторе графиков пока что не поддерживается работа с ВЧП
          type: item.hasAggregateFunc
            ? ETypeColumn.Value
            : get(item, 'source.dataType', '') ||
              item.type ||
              ETypeColumn.Dimension,
        },
        isActive: item.isActive ?? true,
      };

      // если нет function, то не добавляем functionParams
      // если function равна null, то functionParams тоже равен null
      const functionSource = get(item, 'source.function', undefined);
      if (typeof functionSource !== 'undefined') {
        column.source.functionParams = functionSource
          ? get(item, 'source.functionParams', null)
          : null;
      }
      if (
        item.isCalculatedColumn &&
        !item.hasAggregateFunc &&
        !isDetailed &&
        item.type === ETypeColumn.Value &&
        item.source.function
      )
        column.source.formula = `${item.source.function}(${item.source.formula})`;
      return validateColumn(column);
    });

    sourcesFunctionsToLowerCase(columns);

    const _columnsLevels = columnsLevelsNew
      .map(a =>
        a?.filter((c: any) => c.type !== ETypeColumn.Value).map(i => i.id)
      )
      .filter(a => a.length);

    /*  */
    const columnsUsedIds = new Set();
    columnsLevels.slice(0, level + 1).forEach(l => {
      l.forEach(c => columnsUsedIds.add(c.id));
    });

    /*  */
    const sortForFormat = [];
    sort.forEach(i => {
      if (!columnsUsedIds.has(i.id)) return;
      sortForFormat.push({
        source: { selectColumnId: dateNames[i.id] || i.id },
        order: i.order,
      });
    });

    const data = {
      columns: _columns,
      dynamics,
      filters: filterArray,
      additionalFilters,
      limit: limit ?? null,
      page: page ?? null,
      sort: sortForFormat,
    };
    if (isDetailed) {
      data.isDetailed = isDetailed;
    } else {
      data.columnsLevels = _columnsLevels;
      data.level = level || 0;
    }
    return data;
  } catch (_error) {
    error(_error);
  }
};

const getJsonFormatForChart = (
  { columns, filters, additionalFilters, level, limit, sort, page, sortX },
  schema
) => {
  try {
    const schemaColumns = schema.showcaseInfo?.columns || [];
    const modelId = columns[0]?.source.modelId;
    let filterArray = [];
    const updateCondition = condition => {
      return {
        comparison: condition.comparison,
        comparisonKind: condition.comparisonKind,
        table: condition.table,
        field: condition.attribute,
        value: condition.value,
        columnId: condition.columnId,
        modelId: condition.modelId,
      };
    };
    const updateFilter = filter => {
      const copyFilter = Array.isArray(filter) ? filter[0] : filter;
      if (hasOwnProperty.call(copyFilter, 'filters')) {
        updateFilter(copyFilter.filters);
      } else {
        if (Array.isArray(filter)) {
          filter.forEach((f, index) => {
            filter[index] = updateCondition(f);
          });
        }
      }
    };
    if (filters.length > 0) {
      filterArray = cloneDeep(filters);
    }
    const _columns = columns.map(item => {
      const itemToFunc = {
        id: item.id,
        name: item.name,
        isActive: true,
      };
      return {
        ...itemToFunc,
        source: {
          ...item.source,
        },
      };
    });

    const columnsLevels = [
      _columns
        .filter(a => a.source.type !== ETypeColumn.Value && a?.id)
        .map(a => a.id),
    ];
    const makeSelectRestriction = sort => {
      if (!sort || !sort.length) return null;
      const [firstSort] = sort;
      const columnId = _columns.find(
        c => c.source.type === ETypeColumn.Dimension
      ).id;
      let firstDimensionId = columnId;
      for (const f of Object.values(EAggregationFunction)) {
        if (columnId.includes(`_${f}`)) {
          firstDimensionId = columnId.replace(`_${f}`, '');
        }
      }
      return {
        columnId,
        modelId,
        dimensionSourceId: getSourseIdByColumn(schemaColumns, firstDimensionId),
        [firstSort.formula ? 'formula' : 'valueSourceId']:
          firstSort.formula || getSourseIdByColumn(schemaColumns, firstSort.id),
        limit: limit,
        sortDirection: firstSort.order,
      };
    };
    const data = {
      columns: _columns,
      columnsLevels,
      filters: filterArray,
      additionalFilters,
      level: level ?? 0,
      // limit: limit ?? null,
      sort,
      page: page ?? null,
    };
    if (sortX) data.selectRestriction = makeSelectRestriction(sortX);
    return data;
  } catch (_error) {
    error(_error);
  }
};

export const getJsonFormat = (
  type: EWidgetType | null,
  settings: Record<string, any>,
  schema: Record<string, any>
): TAnalyticStructure | null => {
  // settings = handleJsonFormatProps(settings);
  if (!type) return settings as TAnalyticStructure;
  try {
    const jsonFormat =
      type === 'table'
        ? getJsonFormatForTable(settings as any, schema)
        : getJsonFormatForChart(settings as any, schema);
    // TODO особенность движка Atlant https://app.clickup.com/t/2jm1z8p
    return {
      ...jsonFormat,
      // TODO посмотреть как можно еще передавать modelId, быстрое решение для показа
      modelId: jsonFormat?.columns[0]?.source.modelId,
    } as TAnalyticStructure;
  } catch (_error) {
    if (_error instanceof Error) {
      error('ERROR! makeQuery', _error.message);
    }
    return null;
  }
};

export { comparisonInfo, operatorComparisonMap };

export interface IConstructorParams {
  apiService: any;
  indent?: string;
  schema?: any;
  url?: string;
  options?: IRestOptions;
}

export default class DatabaseQuery {
  indent;
  url;
  _apiService;
  options: IRestOptions;

  constructor(
    {
      apiService,
      indent = '  ',
      schema = {},
      url = '/api',
      options = {},
    }: IConstructorParams = {
      apiService: null,
      indent: '  ',
      schema: {},
      url: '',
      options: {},
    }
  ) {
    this.indent = indent;
    this.url = url;
    this._apiService = apiService;
    this.options = options;

    this.setSchema(schema);
  }

  getExpandFilters({ columns, filters = [], record, recordParents }) {
    /* Settings */
    const fieldId = 'id';
    const recordFieldLevel = '_l';

    /* New */
    const columnLevelMap = {};
    const fieldLevel = 'l';
    let levelForMap = 0;
    columns.forEach(c => {
      if (hasOwnProperty.call(c, fieldLevel)) levelForMap = c[fieldLevel];
      columnLevelMap[c[fieldId]] = levelForMap;
    });
    /* Define indexes for use in filters */
    const columnsStaticLevelsIndexes = [];
    columns.forEach((column, index) => {
      /* TODO: fix hardcode. Created for hotfix */
      if (column.type === ETypeColumn.Value) return;

      /* Extract column info */
      const columnId = column[fieldId];
      const itemLevel = columnLevelMap[columnId];

      /* Add index */
      if (columnsStaticLevelsIndexes[itemLevel]) {
        columnsStaticLevelsIndexes[itemLevel].push(index);
      } else {
        columnsStaticLevelsIndexes[itemLevel] = [index];
      }
    });

    /* Get filters for query */
    const filtersForQuery = this.getFiltersForQuery(
      filters,
      {
        columns: columns,
        columnsLevelsIndexes: columnsStaticLevelsIndexes,
      },
      { record, recordParents },
      { fieldId, recordFieldLevel }
    );

    return {
      filters: filtersForQuery,
    };
  }

  /* OK - Create query for total */
  async fetchRecords(jsonFormat, params = {}) {
    this._log('jsonFormat', jsonFormat);

    const { data: apiServiceResponse } =
      await this._apiService.fetchRecordsCalculated(jsonFormat, {
        ...params,
        ...this.options,
      });

    return Array.isArray(apiServiceResponse)
      ? apiServiceResponse
      : hasOwnProperty.call(apiServiceResponse, 'items')
      ? apiServiceResponse.items || []
      : apiServiceResponse.response?.data || [];
  }

  getRecordFilters(
    { record, recordParents },
    { columns, columnsLevelsIndexes },
    { fieldId, recordFieldLevel }
  ) {
    /* Result */
    const filtersMap = {};

    /* Helpers */
    const recordLevel = record[recordFieldLevel];

    const processIndexes = (levelColumnsIndexes, levelRecord) => {
      levelColumnsIndexes.forEach(columnIndex => {
        const column = columns[columnIndex];
        if (!column) return;

        /*  */
        const source = columns[columnIndex].source;
        const value = levelRecord?.[column[fieldId]];
        if (value === undefined) return;

        if (source.type === sourceTypeFunction) return;

        const target = source;
        const attribute =
          column.isCalculatedColumn && column.type === ETypeColumn.Dimension
            ? column.id
            : target.attribute;
        const database = target.database;
        const table = target.table;
        if (!filtersMap[attribute]) {
          filtersMap[attribute] = {
            attribute,
            database,
            isCalculated: column.isCalculatedColumn,
            table,
            value: null,
            comparison: 'eq',
          };
        }

        filtersMap[attribute].value = value;
      });
    };

    columnsLevelsIndexes
      .slice(0, recordLevel)
      .forEach((levelColumnsIndexes, level) => {
        processIndexes(levelColumnsIndexes, recordParents[level]);
      });
    processIndexes(columnsLevelsIndexes[recordLevel], record);

    /* Transform to array */
    const filters = [];
    for (const attribute in filtersMap) {
      const attributeFilters = filtersMap[attribute];
      const multiple =
        comparisonInfo[attributeFilters.comparison]?.multiple || false;
      filters.push({
        ...attributeFilters,
        value: multiple
          ? Array.from(attributeFilters.value)
          : attributeFilters.value,
      });
    }

    return filters;
  }

  /*  */
  getFiltersForQuery(
    filters,
    { columns, columnsLevelsIndexes },
    { record, recordParents },
    { fieldId, recordFieldLevel }
  ) {
    /* Result */
    const filtersForQuery = [...filters];

    /* Add filters from record */
    if (record) {
      /*  */
      const recordFilters = this.getRecordFilters(
        { record, recordParents },
        { columns, columnsLevelsIndexes },
        { fieldId, recordFieldLevel }
      );

      recordFilters.forEach(filter => filtersForQuery.push(filter));
    }

    return filtersForQuery;
  }

  /* OK */
  async makeQuery(
    {
      columns,
      dynamics = [],
      columnsLevels,
      expandAll,
      filters,
      level,
      limit,
      page,
      additionalFilters,
      record,
      idPrefix,
      recordParents,
      sort,
      isDetailed,
    },
    schema
  ) {
    try {
      /* Define vars */
      const queriesParams = {
        columns,
        filters,
        record,
        recordParents,
      };
      const { filters: filtersForQuery } = this.getExpandFilters({
        ...queriesParams,
      });

      const jsonFormat = this.getJsonFormatForTable(
        {
          columns,
          dynamics,
          columnsLevels,
          filters: [...filtersForQuery],
          level,
          additionalFilters,
          limit,
          page,
          sort,
          isDetailed,
        },
        schema
      );

      const restOptions = this.options;
      const records = [
        ...(await this.fetchRecords(jsonFormat, {
          size: limit,
          page,
          pagination: true,
          ...restOptions,
        })),
      ];
      const recordsProcessed = this.processRecords({
        columns: columns,
        columnsLevels: columnsLevels,
        dynamics,
        expandAll,
        level,
        idPrefix,
        records,
        isDetailed,
      });

      return {
        data: {
          jsonFormat,
          records: recordsProcessed,
        },
        errors: [],
        status: true,
      };
    } catch (_error) {
      if (_error instanceof Error) {
        error('ERROR! makeQuery', _error);
      }
      return {
        data: null,
        errors: [],
        status: true,
      };
    }
  }

  getJsonFormatForTable(
    {
      columns,
      dynamics = [],
      columnsLevels,
      filters,
      additionalFilters,
      level,
      limit = 100,
      page,
      sort,
    },
    schema = this.schema
  ) {
    return getJsonFormatForTable(
      {
        columns,
        dynamics,
        columnsLevels,
        filters,
        additionalFilters,
        level,
        limit,
        page,
        sort,
      },
      schema
    );
  }

  getJsonFormatForChart(
    { columns, filters, level, limit, sort, sortX, page },
    schema = this.schema
  ) {
    return getJsonFormatForChart(
      {
        columns,
        filters,
        level,
        limit,
        sort,
        sortX,
        page,
      },
      schema
    );
  }

  handleJsonFormatProps(props) {
    const handledProps = cloneDeep(props);

    function handleColumns(props) {
      const { columns } = props;
      function handleSource(src) {
        if (src.modelId) {
          delete src.database;
          delete src.table;
          if (src.formula) {
            delete src.function;
            delete src.functionParams;
          }
        }
      }

      for (const column of columns) {
        for (const src of column.sources) {
          handleSource(src);
        }
      }
      return columns;
    }

    handleColumns(handledProps);
    return handledProps;
  }

  getJsonFormat(
    type: EWidgetType | null,
    settings: Record<string, any>,
    schema: Record<string, any> = this.schema
  ): TAnalyticStructure | null {
    return getJsonFormat(type, settings, schema);
  }

  createAttributeJsonFormat({
    fields,
    filter,
    additionalFilters,
    sort,
    sortX,
    table,
    modelId,
    formula,
  }) {
    /*  */
    const columnsForQuery = [];
    const sortForQuery = [];
    const sortXForQuery = [];
    const fieldsDimension = [];
    /* Process fields */
    fields.forEach(fieldInfo => {
      const { aggregation } = fieldInfo;
      const id = aggregation ? `${fieldInfo.id}_${aggregation}` : fieldInfo.id;
      const name = id;
      const dataType = fieldInfo.dataType;
      let rounding;
      let digits;

      if (fieldInfo.rounding) {
        rounding = fieldInfo.rounding;
      } else if (fieldInfo.rounding === 0) {
        rounding = -1;
      }

      if (fieldInfo.digits) {
        digits = fieldInfo.digits;
      }

      const columnForQuery = {
        id,
        name,
        source: {},
        rounding,
        digits,
        dataType,
      };

      const columnSource = {
        type: null,
        modelId: null,
        formula: null,
      };
      if (fieldInfo.type === ETypeColumn.Value) {
        columnSource.modelId = modelId;
        columnSource.type = ETypeColumn.Value;
        columnSource.formula = fieldInfo.isCalculatedColumn
          ? fieldInfo.formula
          : fieldInfo.aggregation
          ? `${fieldInfo.aggregation}([${fieldInfo.name}])`
          : `([${fieldInfo.name}])`;
      } else {
        columnSource.formula =
          formula ||
          (fieldInfo.isCalculatedColumn
            ? fieldInfo.formula
            : `[${fieldInfo.name}]`);
        columnSource.type = ETypeColumn.Dimension;
        columnSource.modelId = modelId;
        // columnSource.modelId = modelId;
        fieldsDimension.push(columnForQuery.id);
      }
      columnForQuery.source = columnSource;

      columnsForQuery.push(columnForQuery);
    });
    const processFilter = filterInfo => {
      const attribute = filterInfo.field;
      const filterValues = filterInfo.value;
      const comparisonKind = filterInfo.comparisonKind;

      const filterForQuery = {
        attribute,
        isCalculated: !!filterInfo.formula,
        formula: filterInfo.formula,
        comparison: filterInfo.comparison,
        comparisonKind,
        table,
        dataType: null,
        modelId: filterInfo.modelId,
        columnId: filterInfo.field,
        value: filterValues,
      };

      return filterForQuery;
    };

    const updFilter = cloneDeep(filter);
    updFilter.forEach((filterItem, index) => {
      const { id: columnId } = filterItem;
      const filterInfo = filterItem.settings.filter;
      const { comparison, value } = filterInfo;
      if (comparison) {
        updFilter[index] = {
          comparison,
          value,
          modelId,
          columnId,
        };
      }
    });
    sort.forEach(sortItem => {
      const source: TSortSource = {};
      const field = fields.find(f => f.id === sortItem.id);
      if (field) {
        const { id: fieldId, aggregation } = field;
        source.selectColumnId = aggregation
          ? `${fieldId}_${aggregation}`
          : fieldId;
      } else if (sortItem.formula) source.formula = sortItem.formula;
      else source.sourceColumnId = sortItem.sourceId;
      const newSort: TSort = {
        source,
        order: sortItem.settings.order,
      };
      sortForQuery.push(newSort);
    });
    if (sortX) {
      sortX.forEach(sortItem => {
        sortXForQuery.push({
          id: sortItem.field,
          order: sortItem.order,
          formula: sortItem.formula,
        });
      });
    }
    const data = {
      columns: columnsForQuery,
      filters: updFilter,
      additionalFilters,
      sort: sortForQuery,
      columnsLevels: [fieldsDimension],
    };
    if (sortXForQuery.length > 0) data.sortX = sortXForQuery;
    return data;
  }

  /* Helpers */
  processRecords({
    columns = [],
    dynamics = [],
    columnsLevels = [],
    level = 0,
    records = [],
    idPrefix = '',
    expandAll = false,
    isDetailed = false,
  }) {
    /* Check columns */
    const columnsLength = columns.length;
    if (!columnsLength) return;
    /* Define fields of new records  */
    const newRecords = [];

    const columnsForLevel = isDetailed
      ? columns
      : expandAll
      ? columnsLevels[level].concat(
          columnsLevels.filter((l, idx) => idx < level).flatMap(l => l)
        )
      : columnsLevels[level]
          .map(c => (c.w ? c : { ...c, w: 1 }))
          .filter(i => i.type !== ETypeColumn.Value);
    const columnsTypeValue = columns.filter(c => c.type === ETypeColumn.Value);
    const dynamicColumns = dynamics.map(d => d.columnId);
    // let levelWidth = 0;
    // columnsForLevel.forEach(c => (levelWidth += c.w));
    records.forEach((r, idx) => {
      const rNew = { _i: idPrefix + idx, _l: level };
      columnsForLevel.forEach(c => {
        const cId = c.id;
        if (dynamicColumns.includes(cId)) return;
        rNew[cId] = r[cId];
      });
      columnsTypeValue.forEach(c => {
        const cId = c.id;
        if (dynamics.length) {
          dynamics.forEach(d =>
            d.values.forEach(v => {
              rNew[c.id + '$' + v] = r[cId + '.' + v];
            })
          );
        } else rNew[cId] = r[cId];
      });
      newRecords.push(rNew);
    });
    return newRecords;
  }

  /* Setters */
  setSchema(schema) {
    this.schema = schema;
  }

  /* Technical */
  _log(...params) {
    log(...params);
  }

  _logError(...params) {
    error(...params);
  }
}
