import EntityBaseApiService, { Mode } from '../__base/EntityBaseApiService';

export { Mode };

export default class RestStandardModuleApiService extends EntityBaseApiService {
  isStandardSettingsEnabled = true;
  isHeadersEnabled;
  isLogEnabled;

  constructor(
    constructorParams,
    isStandardSettingsEnabled = true,
    isHeadersEnabled = true,
    isLogEnabled = false
  ) {
    super(constructorParams);
    this.isStandardSettingsEnabled = isStandardSettingsEnabled;
    this.isHeadersEnabled = isHeadersEnabled;
    this.isLogEnabled = isLogEnabled;
  }

  /* Technical */
  _getHeaders(reqUrl) {
    const isLogEnabled = this.isLogEnabled;

    isLogEnabled && console.group('_getHeaders');

    const headers: Record<string, any> = { Accept: undefined };

    if (this.isStandardSettingsEnabled) {
      headers.Accept = 'application/json';
      headers['Content-Type'] = 'application/json';
    }

    isLogEnabled &&
      this._log(
        '%c _getHeaders ',
        'background: yellow; color: #000;',
        'reqUrl',
        reqUrl,
        'headers',
        headers
      );
    isLogEnabled && console.groupEnd();

    return headers;
  }

  async _sendRequest(path, params, options = { headers: {} }, fetchDataObject) {
    const headers = this.isHeadersEnabled
      ? this._getHeaders(this.basePath + path)
      : {};
    const headersAdded = Object.keys(headers).length;

    if (headersAdded) {
      options.headers = {
        ...(options.headers || {}),
        ...headers,
      };
    }

    const apiServiceResponse = await super._sendRequest(
      path,
      params,
      options,
      fetchDataObject
    );

    return this.isStandardSettingsEnabled && this.mode === Mode.Production
      ? {
          ...apiServiceResponse.response,
          statusCode: apiServiceResponse.statusCode,
          _response: apiServiceResponse._response,
        }
      : apiServiceResponse;
  }
}
