import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import { IApiRequestParams } from '@/types';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from './../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'ShowcaseApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class ShowcaseApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  async fetchById<TResponse>(id: string, options?: IRestOptions) {
    return await this.sendRequest<TResponse>(
      this.pathFetch + id,
      null,
      {},
      { dataOptions: { id }, method: RequestMethod.GET, ...options }
    );
  }

  async updateById<TResponse>(id, data) {
    return await this._sendRequest<TResponse>(this.pathFetch + id, null, {
      body: JSON.stringify(data),
      method: RequestMethod.PUT,
    });
  }

  async fetchAllByFilters<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'> & { projectId: string },
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', meta);

    return await this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }

  async fetchSchema(id: string, options?: IRestOptions) {
    const uri = `/${id}/schema`;
    return await this.sendRequest(
      uri,
      null,
      {},
      { method: RequestMethod.GET, ...options }
    );
  }
}
