import { IEntityBaseApiServiceParams } from '@/api/client';
import getConstants from '@bi-core/constants/env';

import { IRestOptions, Mode } from '../../__base/client';
import FileModuleApiService from './__modules/FileModuleApiService';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'FileApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class FileApiService extends FileModuleApiService {
  constructor() {
    super(constructorParams);
  }

  async exportFile(data, params = {}, options: IRestOptions) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.download(
        data,
        '/export',
        params,
        null,
        options
      );
      const model = apiServiceResponse.baseResponse;
      return {
        errors: model?.status,
        response: model,
        status: model?.ok || false,
      };
    } catch (error) {
      this._log('ERROR: ' + error.message);
    }
  }
}
