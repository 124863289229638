import createAttribute from './createAttribute';

export default function createSchemaTableFromModel(tableSchema) {
  /* OK */
  if (!tableSchema) return {};
  /* Extract table info */
  const tableName = tableSchema.table_name;

  /* Process table columns */
  const attributes = [];
  const stack = [
    ...tableSchema.columns.map(column => ({
      ...column,
      level: 0,
    })),
  ];

  while (stack.length) {
    const column = stack.pop();
    const columnAdditionalFields = column?.additionalFields || [];
    if (columnAdditionalFields.length) {
      stack.push({ ...column, additionalFields: [] });
      stack.push(
        ...columnAdditionalFields.map(col => ({
          ...col,
          humanName: column.humanName + '. ' + col.humanName,
          name: column.name + '. ' + col.name,
          parentSource: column.source,
          level: column.level + 1,
        }))
      );
    } else {
      attributes.push(createAttribute(column));
    }
  }
  attributes.reverse();

  return {
    name: tableName,
    _name: tableName,
    attributes,
  };
}
