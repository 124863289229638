import { useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';

import { availableLangs } from '@/../languages';
import { TLanguage } from '@/types';
import { computed } from '@vue/reactivity';

function useLang() {
  const $q = useQuasar();
  const { locale } = useI18n({ useScope: 'global' });

  const lang = computed<TLanguage>({
    get: () => $q.lang,
    set: lang => {
      const _lang = availableLangs?.[lang.isoName];
      if (_lang) {
        if (document) {
          document.querySelector('html')?.setAttribute('lang', _lang.isoName);
        }
        locale.value = _lang.isoName;
        $q.lang.set(_lang);
      }
    },
  });

  return { lang };
}

export { useLang, availableLangs };
