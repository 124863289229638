import { Ref, WatchOptions, computed, isRef, ref, unref, watch } from 'vue';

import { Alert } from '@/api/__base/client/middlewares/Alerts';
import apiMiddlewares, { alerts } from '@/api/client/middlewares';
import { useAlertsStore } from '@/store/bi-store/alert';
import { clone } from '@clearview/helpers';
import { QueryClient, QueryKey, useQueryClient } from '@tanstack/vue-query';
import { MaybeRefDeep } from '@tanstack/vue-query/build/lib/types';

// eslint-disable-next-line @typescript-eslint/ban-types
function isPlainObject(value: unknown): value is Object {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return prototype === null || prototype === Object.prototype;
}

export function cloneDeep<T>(
  value: MaybeRefDeep<T>,
  customizer?: (val: MaybeRefDeep<T>) => T | undefined
): T {
  if (customizer) {
    const result = customizer(value);
    // If it's a ref of undefined, return undefined
    if (result === undefined && isRef(value)) {
      return result as T;
    }
    if (result !== undefined) {
      return result;
    }
  }

  if (Array.isArray(value)) {
    return value.map(val => cloneDeep(val, customizer)) as unknown as T;
  }

  if (typeof value === 'object' && isPlainObject(value)) {
    const entries = Object.entries(value).map(([key, val]) => [
      key,
      cloneDeep(val, customizer),
    ]);
    return Object.fromEntries(entries);
  }

  return value as T;
}

export function cloneDeepUnref<T>(obj: MaybeRefDeep<T>): T {
  return cloneDeep(obj, val => {
    if (isRef(val)) {
      return cloneDeepUnref(unref<MaybeRefDeep<T>>(val));
    }

    return undefined;
  });
}

export function getQuery(queryKey: QueryKey, _queryClient?: QueryClient) {
  const queryClient = _queryClient || useQueryClient();
  const cache = queryClient.getQueryCache();
  const query = cache.find(queryKey);
  return query;
}

export interface IParamsAlert {
  methodNameAlertMap?: Record<
    string,
    { hasAlertSuccess: boolean; hasAlertError?: boolean }
  >;
  hasAlertSuccess?: boolean;
  hasAlertError?: boolean;
}

export function getQueryMiddlewares(
  entityId: string,
  methodName: string,
  paramsAlert: IParamsAlert = {
    methodNameAlertMap: {},
    hasAlertSuccess: false,
    hasAlertError: true,
  },
  paramsApi = {}
) {
  const paramsApiDefault = { ...paramsApi, isManual: true };
  const api = apiMiddlewares[entityId]?.(paramsApiDefault)?.[methodName];
  const alert = alerts.getAlert(
    entityId,
    methodName,
    paramsAlert.methodNameAlertMap,
    paramsAlert.hasAlertSuccess,
    paramsAlert.hasAlertError
  );

  return { api, alert };
}

export function useQueryWithQueryKey<T>(
  query: T,
  queryKey: QueryKey
): { queryKey: QueryKey } & T {
  return { ...query, queryKey };
}

export function useQueryWithAlert<T extends Alert>(
  queryKey: QueryKey,
  alert: T
) {
  const _queryKey = computed(() => queryKey);
  const store = useAlertsStore();

  watch(
    _queryKey,
    () => {
      store.alerts[JSON.stringify(cloneDeepUnref(_queryKey))] = alert;
    },
    { deep: true, immediate: true }
  );
}

export function showSuccessAlert(alert: Alert) {
  alert.show(true);
}

export function showErrorAlert(alert: Alert, err: unknown) {
  alert.show(false, err?.cause?.errors);
}

export function getAlertByQueryKey(key: string) {
  const store = useAlertsStore();
  const alert = store.alerts[key];
  return alert;
}

export function showSuccessAlertByQueryKey(key: string) {
  const alert = getAlertByQueryKey(key);
  if (alert) {
    showSuccessAlert(alert);
  }
}

export function showErrorAlertByQueryKey(key: string, err: unknown) {
  const alert = getAlertByQueryKey(key);
  if (alert) {
    showErrorAlert(alert, err);
  }
}

export function useQueryWrapper<T, K extends Alert>(
  query: T,
  queryKey: QueryKey,
  alert: K
): { queryKey: QueryKey } & T {
  useQueryWithAlert(queryKey, alert);
  return useQueryWithQueryKey<T>(query, queryKey);
}

export function useMutationWrapper<T, K>(
  context: T,
  alert?: K
): { alert?: K } & T {
  return { ...context, alert };
}

export function getErrorQuery(
  message = '',
  errors?: Array<unknown>,
  statusCode?: string | number
) {
  return new Error(message, {
    cause: { errors, statusCode },
  });
}

interface VModelQueryOptions extends WatchOptions {
  initialValue?: any;
}

export function VModelQuery<T>(data: Ref<T>, options?: VModelQueryOptions) {
  const { initialValue = null, deep, immediate } = options || {};

  const value = ref<NonNullable<T>>(initialValue);

  watch(
    data,
    () => {
      if (!data.value) return;
      const cloneDataSource = clone(data.value);
      value.value = cloneDataSource;
    },
    { deep, immediate }
  );

  return { value };
}
