import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { clone } from '@clearview/helpers';

export interface DashboardFilterStoreInfo {
  values: any[] | null;
  useDefaultValues: boolean;
  enabled?: boolean | null;
}

export const useDashboardFilterInfo = defineStore('dashboardFilterInfo', () => {
  const dashboardFiltersInfo = reactive<
    Record<string, DashboardFilterStoreInfo>
  >({});
  const setNewDashboardFilterInfo = (id: string) => {
    dashboardFiltersInfo[id] = {
      useDefaultValues: true,
      values: [],
    };
  };

  const setDashboardFilterValues = (id: string, values: any[] | null) => {
    dashboardFiltersInfo[id] = {
      values: clone(values),
      useDefaultValues: false,
      enabled: true,
    };
  };

  const setDashboardFilterEnabling = (id: string, value: boolean | null) => {
    if (dashboardFiltersInfo[id]) dashboardFiltersInfo[id].enabled = value;
    else
      dashboardFiltersInfo[id] = {
        enabled: value,
        values: [],
        useDefaultValues: true,
      };
  };

  const deleteDashboardFilterInfo = (id: string) => {
    delete dashboardFiltersInfo[id];
  };

  const getDashboardFilterInfoById = (
    id: string
  ): DashboardFilterStoreInfo | null => {
    return dashboardFiltersInfo[id] || null;
  };

  return {
    dashboardFiltersInfo,
    getDashboardFilterInfoById,
    deleteDashboardFilterInfo,
    setDashboardFilterValues,
    setDashboardFilterEnabling,
    setNewDashboardFilterInfo,
  };
});
