/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DashboardModelSchemaColumnDto = {
    humanName?: string;
    sourceId?: string;
    type?: DashboardModelSchemaColumnDto.type;
    dataType?: string;
};

export namespace DashboardModelSchemaColumnDto {

    export enum type {
        VALUE = 'VALUE',
        DIMENSION = 'DIMENSION',
    }


}

