<template>
  <component
    v-bind="$attrs"
    :is="type"
    :tag="tag"
    :appear="appear"
    :name="name"
    :mode="mode"
    :enter-active-class="enterActiveClass"
    :leave-active-class="leaveActiveClass"
  >
    <slot></slot>
  </component>
</template>

<script lang="ts">
import { Transition, TransitionGroup, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BiTransition',
  components: {
    Transition,
    TransitionGroup,
  },
  props: {
    appear: {
      default: false,
      type: Boolean,
    },
    enterActive: {
      default: 'animate__fadeIn',
      type: String,
    },
    leaveActive: {
      default: 'animate__fadeOut',
      type: String,
    },
    group: {
      default: false,
      type: Boolean,
    },
    tag: {
      type: String,
      default: 'div',
    },
    name: {
      default: '',
      type: String,
    },
    mode: {
      default: 'out-in',
      type: String,
      validator(value: string) {
        return ['in-out', 'out-in', 'default'].includes(value);
      },
    },
  },
  setup(props) {
    const type = computed(() => {
      return props.group ? 'transition-group' : 'transition';
    });
    const animateStyle = computed(() => {
      return 'animate__animated';
    });
    const enterActiveClass = computed(() => {
      return animateStyle.value + ' ' + props.enterActive;
    });
    const leaveActiveClass = computed(() => {
      return animateStyle.value + ' ' + props.leaveActive;
    });

    return {
      type,
      animateStyle,
      enterActiveClass,
      leaveActiveClass,
    };
  },
});
</script>
