import { z } from 'zod';

import { EDataType, ETypeColumn } from '@/types/model';

import {
  FilterCreateRequest,
  EDashboardFilterComparison as comparisons,
  EDashboardFilterContextType as context,
} from './../../types/dashboard/index';

export const dashboardFilterFormSchema = z
  .object({
    name: z.string().min(1),
    isEnabled: z.boolean(),
    modelId: z.string().min(1),
    modelName: z.string().min(1),
    type: z.nativeEnum(context),
    dashboardId: z.string().min(1),
    pageId: z.string().nullable(),
    columnId: z.string().min(1).nullable(),
    columnName: z.string().min(1).nullable(),
    columnType: z.nativeEnum(ETypeColumn).nullable(),
    comparison: z.nativeEnum(comparisons).nullable(),
    columnDataType: z.nativeEnum(EDataType),
    values: z.union([
      z.array(z.string().nullable()),
      z.array(z.number().nullable()),
      z.array(
        z
          .object({
            keyword: z.string(),
            value: z.string().optional(),
          })
          .nullable()
      ),
    ]),
    isMultiple: z.boolean(),
  })
  .superRefine(({ columnDataType, values, comparison }, ctx) => {
    if (
      columnDataType === EDataType.Numeric &&
      comparison === comparisons.Union
    ) {
      if (values[0] && values[1] && values[0] > values[1]) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Wrong union',
          path: ['values'],
        });
      }
    }
  });

export const dashboardFilterSubmitFormSchema = () => {
  return dashboardFilterFormSchema.superRefine<FilterCreateRequest>(
    (
      {
        columnDataType,
        values,
        comparison,
        pageId,
        type,
        columnName,
        columnType,
        columnId,
      },
      ctx
    ) => {
      const emptyCheckObj = { columnName, columnType, columnId, comparison };
      for (const key in emptyCheckObj) {
        if (!emptyCheckObj[key]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Empty ${key} field`,
            path: [key],
          });
        }
      }

      if (type === context.Page && !pageId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Choose the page',
          path: ['pageId'],
        });
      }
    }
  );
};
