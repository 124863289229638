import { z } from 'zod';

const emptyNameMsg = 'Введите наименование';
const emptyCodeMsg = 'Введите код';

const saveFormModel = z.object({
  name_column: z.string().trim().nonempty({
    message: emptyNameMsg,
  }),
  code_column: z.string().trim().nonempty({
    message: emptyCodeMsg,
  }),
});

export { saveFormModel };
