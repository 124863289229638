/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataSourceElementColumnDto = {
    index?: number;
    name: string;
    dataType: DataSourceElementColumnDto.dataType;
};

export namespace DataSourceElementColumnDto {

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        DATE = 'DATE',
    }


}

