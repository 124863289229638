import type { Alert } from '@/api/__base/client/middlewares/Alerts';
import {
  showErrorAlert,
  showErrorAlertByQueryKey,
  showSuccessAlert,
  showSuccessAlertByQueryKey,
} from '@/store/queries/helpers';
import {
  MutationCache,
  QueryCache,
  VueQueryPlugin,
  VueQueryPluginOptions,
} from '@tanstack/vue-query';

const CACHE_TIME = 60 * 1000;

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: CACHE_TIME,
      },
    },
    queryCache: new QueryCache({
      onError(err, query) {
        showErrorAlertByQueryKey(query.queryHash, err);
      },
      onSuccess(_data, query) {
        showSuccessAlertByQueryKey(query.queryHash);
      },
    }),
    mutationCache: new MutationCache({
      onError: (err, variables, context) => {
        if (context && typeof context === 'object') {
          if (
            'skipGlobalHandlingOnError' in context &&
            context.skipGlobalHandlingOnError
          )
            return;

          if ('alert' in context) {
            showErrorAlert(context.alert as Alert, err);
          }
        }
      },
      onSuccess: (data, variables, context) => {
        if (context && typeof context === 'object') {
          if (
            'skipGlobalHandlingOnSuccess' in context &&
            context.skipGlobalHandlingOnSuccess
          )
            return;

          if ('alert' in context) {
            showSuccessAlert(context.alert as Alert);
          }
        }
      },
    }),
  },
};

export { VueQueryPlugin, vueQueryPluginOptions };
