import { defineStore } from 'pinia';

import { FORMULA_CONSTRUCTOR__SPLITTER_SIZES } from '@bi-core/constants';
import { useStorage } from '@vueuse/core';

interface ISettings {
  splitMain: number;
  splitSidebar: number;
}

export const useFormulaConstructorStore = defineStore(
  'formula-constructor',
  () => {
    const defaultSettings: ISettings = {
      splitMain: 30,
      splitSidebar: 50,
    };
    const settings = useStorage<ISettings>(
      FORMULA_CONSTRUCTOR__SPLITTER_SIZES,
      defaultSettings
    );

    const updateSettings = (key, value) => {
      settings.value[key] = Math.round(value);
    };

    const restoreDefaultSettings = (key: string) => {
      if (key) {
        settings.value[key] = defaultSettings[key];
      } else {
        settings.value = { ...defaultSettings };
      }
    };

    return {
      settings,
      updateSettings,
      restoreDefaultSettings,
    };
  }
);
