import { markRaw } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

import Default from '@/bi-views/views/layouts/Default.vue';
import DefaultWithHeader from '@/bi-views/views/layouts/DefaultWithHeader.vue';
import HeaderWithNavigationTabs from '@/bi-views/views/layouts/HeaderWithNavigationTabs.vue';
import LayoutWithoutQuasar from '@/bi-views/views/layouts/LayoutWithoutQuasar.vue';
import CvmDefault from '@/bi-views/views/layouts/mobile/CvmDefault.vue';
import CvmDefaultWithHeader from '@/bi-views/views/layouts/mobile/CvmDefaultWithHeader';
import { guest } from '@/middlewares';

import {
  ANALITYCS_JSON,
  CONNECTIONS_NAME,
  CONNECTIONS_PATH,
  CVM_DASHBOARDS_NAME,
  CVM_DASHBOARDS_RELATIVE_PATH,
  CVM_DASHBOARD_ITEM_NAME,
  CVM_ROOT_NAME,
  CVM_ROOT_PATH,
  CVM_SHARE_DASHBOARD_NAME,
  CVM_SHARE_PATH,
  DASHBOARDS_PATH,
  DASHBOARDS_RELATIVE_PATH,
  DASHBOARD_ID,
  DASHBOARD_ITEM_NAME,
  DASHBOARD_PAGE_NAME,
  DASHBOARD_WIDGET_ITEM_CREATE,
  DASHBOARD_WIDGET_ITEM_CREATE_NAME,
  DATA_SOURCE_CREATOR,
  DATA_SOURCE_CREATOR_PATH,
  DATA_SOURCE_EDITOR,
  DATA_SOURCE_EDITOR_PATH,
  PAGE_ID,
  PAGE_RELATIVE_PATH,
  PROJECTS_NAME,
  PROJECTS_PATH,
  PROJECT_WIDGET_ITEM_NAME,
  SHARE_DASHBOARD_NAME,
  SHARE_ID,
  SHARE_PATH,
  SHOWCASES_NAME,
  SHOWCASES_PATH,
  SHOWCASE_CREATOR,
  SHOWCASE_CREATOR_PATH,
  SHOWCASE_EDITOR,
  SHOWCASE_EDITOR_PATH,
  TEAM_NAME,
  TEAM_PATH,
  TEXT_EDITOR_NAME,
  TEXT_EDITOR_PATH,
  WIDGETS_CATALOG_NAME,
  WIDGETS_CATALOG_PATH,
  WIDGETS_ITEM_CONSTRUCTOR,
  WIDGETS_RELATIVE_PATH,
  WIDGET_ID,
  WIDGET_ITEM_CREATE_NAME,
  WIDGET_ITEM_NAME,
  WORKSPACES_NAME,
  WORKSPACES_PATH,
} from './routes.json';

const headerWithNavigationTabs = markRaw(HeaderWithNavigationTabs);
const defaultWithHeader = markRaw(DefaultWithHeader);

export const RouterViewComponent = RouterView;

const routes: RouteRecordRaw[] = [
  {
    path: '/app-info',
    component: RouterViewComponent,
    children: [
      {
        path: '',
        name: 'appInfo',
        component: () => import('@bi-views/pages/AppInfo'),
        meta: {
          layout: {
            component: defaultWithHeader,
          },
        },
      },
    ],
  },
  {
    path: ANALITYCS_JSON,
    component: RouterViewComponent,
    children: [
      {
        path: '',
        name: 'analyticsJSON',
        component: () => import('~packages/analyticsJSON/components'),
      },
    ],
  },
  {
    path: WORKSPACES_PATH,
    component: RouterViewComponent,
    children: [
      {
        path: '',
        name: WORKSPACES_NAME,
        component: () => import('@bi-views/pages/WorkspacePage'),
        meta: {
          layout: {
            component: defaultWithHeader,
          },
        },
      },
      {
        path: ':workspaceId',
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: PROJECTS_NAME,
            component: () => import('@bi-views/pages/ProjectPage'),
            meta: {
              layout: {
                component: defaultWithHeader,
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: PROJECTS_PATH,
    component: RouterViewComponent,
    children: [
      {
        path: ':projectId' + DASHBOARDS_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: DASHBOARD_PAGE_NAME,
            component: () => import('@bi-views/pages/DashboardsPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
        ],
      },
      {
        path: ':projectId' + WIDGETS_CATALOG_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: WIDGETS_CATALOG_NAME,
            component: () => import('@bi-views/pages/WidgetsPage'),
            props: true,
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
        ],
      },
      {
        path: ':projectId',
        component: RouterViewComponent,
        children: [
          {
            path: WIDGETS_RELATIVE_PATH,
            component: RouterViewComponent,
            children: [
              {
                path: ':type/' + WIDGETS_ITEM_CONSTRUCTOR,
                component: RouterViewComponent,
                children: [
                  {
                    path: DASHBOARD_WIDGET_ITEM_CREATE,
                    name: WIDGET_ITEM_CREATE_NAME,
                    component: () =>
                      import('@/bi-views/pages/WidgetConstructorPage'),
                    props: true,
                    meta: {
                      layout: {
                        component: LayoutWithoutQuasar,
                      },
                    },
                  },
                  {
                    path: ':' + WIDGET_ID,
                    name: PROJECT_WIDGET_ITEM_NAME,
                    component: () =>
                      import('@/bi-views/pages/WidgetConstructorPage'),
                    props: true,
                    meta: {
                      layout: {
                        component: LayoutWithoutQuasar,
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: ':projectId' + CONNECTIONS_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: CONNECTIONS_NAME,
            component: () => import('@bi-views/pages/ConnectionsPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
          {
            path: DATA_SOURCE_CREATOR_PATH,
            name: DATA_SOURCE_CREATOR,
            props: true,
            component: () => import('@bi-views/pages/ConnectionCreatorPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
          {
            path: DATA_SOURCE_EDITOR_PATH + ':connectionId',
            name: DATA_SOURCE_EDITOR,
            props: true,
            component: () => import('@bi-views/pages/ConnectionEditorPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
        ],
      },
      {
        path: ':projectId' + SHOWCASES_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: SHOWCASES_NAME,
            component: () => import('@bi-views/pages/ShowcasesPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
          {
            path: SHOWCASE_CREATOR_PATH,
            name: SHOWCASE_CREATOR,
            props: true,
            component: () => import('@bi-views/pages/ShowcaseCreatorPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
          {
            path: SHOWCASE_EDITOR_PATH + ':showcaseId',
            name: SHOWCASE_EDITOR,
            props: true,
            component: () => import('@bi-views/pages/ShowcaseCreatorPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
          // {
          //   path: DICTIONARY_CONSTRUCTOR_PATH,
          //   name: DICTIONARY_CONSTRUCTOR_NAME,
          //   component: () =>
          //     import('@bi-views/pages/DictionaryConstructorPage'),
          //   meta: { layout: { component: Default } },
          // },
          // {
          //   path:
          //     DICTIONARY_CONSTRUCTOR_EDITOR_PATH + ':dictionaryConstructorId',
          //   name: DICTIONARY_CONSTRUCTOR_EDITOR_NAME,
          //   props: true,
          //   component: () =>
          //     import('@bi-views/pages/DictionaryConstructorPage'),
          //   meta: { layout: { component: Default } },
          // },
          // {
          //   path: DICTIONARY_PATH + ':dictionaryId',
          //   name: DICTIONARY_NAME,
          //   props: true,
          //   component: () => import('@bi-views/pages/DictionaryPage'),
          //   meta: { layout: { component: headerWithNavigationTabs } },
          // },
        ],
      },
      {
        path: ':projectId' + TEAM_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: TEAM_NAME,
            props: true,
            component: () => import('@bi-views/pages/TeamPage'),
            meta: {
              layout: {
                component: headerWithNavigationTabs,
              },
            },
          },
        ],
      },
      {
        path: TEXT_EDITOR_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: TEXT_EDITOR_NAME,
            component: () => import('@bi-views/pages/TextEditorPage'),
            meta: {
              layout: {
                component: defaultWithHeader,
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: DASHBOARDS_PATH,
    component: RouterViewComponent,
    children: [
      {
        path: ':' + DASHBOARD_ID,
        component: RouterViewComponent,
        children: [
          {
            path: '',
            name: DASHBOARD_ITEM_NAME,
            component: () => import('@bi-views/pages/DashboardsItemPage'),
            props: route => ({ tabId: route.query.t || '' }),
            meta: {
              layout: {
                component: Default,
              },
            },
          },
          {
            path: WIDGETS_RELATIVE_PATH,
            component: RouterViewComponent,
            children: [
              {
                path: ':type',
                component: RouterViewComponent,
                children: [
                  {
                    path: WIDGETS_ITEM_CONSTRUCTOR,
                    component: RouterViewComponent,
                    children: [
                      {
                        path: ':' + WIDGET_ID,
                        component: RouterViewComponent,
                        children: [
                          {
                            path: '',
                            name: WIDGET_ITEM_NAME,
                            component: () =>
                              import('@/bi-views/pages/WidgetConstructorPage'),
                            props: true,
                            meta: {
                              layout: { component: LayoutWithoutQuasar },
                            },
                          },
                          {
                            path: 'new',
                            name: 'new',
                            component: () =>
                              import(
                                '@/bi-views/pages/WidgetConstructorPageNew'
                              ),
                            props: true,
                            meta: {
                              layout: { component: LayoutWithoutQuasar },
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PAGE_RELATIVE_PATH,
            component: RouterViewComponent,
            children: [
              {
                path: ':' + PAGE_ID,
                component: RouterViewComponent,
                children: [
                  {
                    path: WIDGETS_RELATIVE_PATH,
                    component: RouterViewComponent,
                    children: [
                      {
                        path: ':type',
                        component: RouterViewComponent,
                        children: [
                          {
                            path: WIDGETS_ITEM_CONSTRUCTOR,
                            component: RouterViewComponent,
                            children: [
                              {
                                path: DASHBOARD_WIDGET_ITEM_CREATE,
                                component: RouterViewComponent,
                                children: [
                                  {
                                    path: '',
                                    name: DASHBOARD_WIDGET_ITEM_CREATE_NAME,
                                    component: () =>
                                      import(
                                        '@/bi-views/pages/WidgetConstructorPage'
                                      ),
                                    props: true,
                                    meta: {
                                      layout: {
                                        component: LayoutWithoutQuasar,
                                      },
                                    },
                                  },
                                  {
                                    path: 'new',
                                    name: 'createNewWidget',
                                    component: () =>
                                      import(
                                        '@/bi-views/pages/WidgetConstructorPageNew'
                                      ),
                                    props: true,
                                    meta: {
                                      layout: {
                                        component: LayoutWithoutQuasar,
                                      },
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: SHARE_PATH,
    component: RouterViewComponent,
    children: [
      {
        path: ':' + SHARE_ID,
        component: RouterViewComponent,
        children: [
          {
            path: DASHBOARDS_RELATIVE_PATH,
            component: RouterViewComponent,
            children: [
              {
                path: ':' + DASHBOARD_ID,
                name: SHARE_DASHBOARD_NAME,
                component: () => import('@bi-views/pages/DashboardsItemPage'),
                meta: {
                  middlewares: [guest],
                  layout: {
                    component: Default,
                    props: {
                      headerText: 'Hi',
                    },
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: RouterViewComponent,
    children: [
      {
        path: '',
        component: () => import('@/bi-views/pages/RootPage'),
        meta: {
          layout: { component: Default },
        },
      },
    ],
  },
  {
    path: CVM_ROOT_PATH,
    component: RouterViewComponent,
    name: CVM_ROOT_NAME,
    meta: {
      layout: { component: CvmDefaultWithHeader },
    },
    redirect: { name: CVM_DASHBOARDS_NAME },
    children: [
      {
        path: CVM_DASHBOARDS_RELATIVE_PATH,
        children: [
          {
            path: '',
            component: () =>
              import('@/bi-views/views/components/mobile/CvmDashboardsList'),
            name: CVM_DASHBOARDS_NAME,
            meta: {
              layout: { component: CvmDefaultWithHeader },
              title: 'Список дашбордов',
            },
          },
          {
            path: ':' + DASHBOARD_ID,
            component: RouterViewComponent,
            children: [
              {
                path: '',
                name: CVM_DASHBOARD_ITEM_NAME,
                component: () => import('@bi-views/pages/CvmDashboardItemPage'),
                meta: {
                  layout: {
                    component: CvmDefault,
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: CVM_SHARE_PATH,
        component: RouterViewComponent,
        children: [
          {
            path: ':' + SHARE_ID,
            component: RouterViewComponent,
            children: [
              {
                path: DASHBOARDS_RELATIVE_PATH,
                component: RouterViewComponent,
                children: [
                  {
                    path: ':' + DASHBOARD_ID,
                    name: CVM_SHARE_DASHBOARD_NAME,
                    component: () =>
                      import('@bi-views/pages/CvmDashboardItemPage'),
                    meta: {
                      middlewares: [guest],
                      layout: {
                        component: Default,
                        props: {
                          headerText: 'Hi',
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    component: RouterViewComponent,
    children: [
      {
        path: '',
        component: () => import('@bi-views/pages/NotFoundPage'),
        meta: {
          layout: { component: defaultWithHeader },
        },
      },
    ],
  },
];

export { routes };
export default routes;
