<template>
  <div
    class="overlay"
    :style="'background: ' + background + '; position: ' + position"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Overlay',
  props: {
    background: {
      type: String,
      default: 'rgba(0, 0, 0, .6)',
    },
    position: {
      type: String,
      default: 'fixed',
    },
  },
};
</script>
<style>
.overlay {
  inset: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
