import { z } from 'zod';

const emptyHostMsg =
  'Адрес публикации информационной базы не должен быть пустым';
const emptyUserMsg = 'Имя пользователя не должно быть пустым';

const checkOneCConnectionSchema = z.object({
  host: z.string().trim().min(1, { message: emptyHostMsg }),
  username: z.string().trim().min(1, { message: emptyUserMsg }),
  password: z.string(),
});

export { checkOneCConnectionSchema };
