import { z } from 'zod';

const maxLengthDashboardTitle = 65;
const baseMsg = 'Нельзя сохранить дашборд.';
const emptyNameDashboardMsg = `${baseMsg} Название дашборда не должно быть пустым`;
const emptyPageNameMsg = `${baseMsg} Название страницы не должно быть пустым`;
const maxNameDashboardLengthMsg = 'Максимальная длина названия 65 символов';
const emptyNameDashboardWidgetFilterMessage =
  'Нельзя сохранить дашборд. Название виджет-фильтра не должно быть пустым';
const emptyNameDashboardWidgetMessage =
  'Нельзя сохранить дашборд. Название виджета не должно быть пустым';
const emptyNameGroupWidgets =
  'Введите заголовок группы виджетов или отключите его в настройках';
const emptyNameDashboardSchema = z
  .string()
  .trim()
  .nonempty({ message: emptyNameDashboardMsg })
  .max(maxLengthDashboardTitle, { message: maxNameDashboardLengthMsg });

const emptyNameDashboardWidgetFilter = z
  .string()
  .trim()
  .nonempty({ message: emptyNameDashboardWidgetFilterMessage });

const saveDashboardSchema = z.object({
  name: emptyNameDashboardSchema,
  elements: z.array(
    z
      .object({
        id: z.string(),
        type: z.string(),
        settings: z
          .object({
            settings: z
              .object({
                hasTitle: z.boolean(),
                title: z.object({
                  text: z.string(),
                }),
              })
              .optional(),
          })
          .optional(),
      })
      .superRefine(({ type, settings }, ctx) => {
        if (
          type === 'FILTER' &&
          settings?.settings?.hasTitle &&
          !settings?.settings.title.text
        )
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: emptyNameDashboardWidgetFilterMessage,
            path: ['settings', 'settings', 'title'],
          });
      })
  ),
  widgets: z.array(
    z
      .object({
        id: z.string(),
        settings: z
          .object({
            showTitle: z.boolean(),
            editorText: z.object({
              text: z.string(),
            }),
          })
          .optional(),
      })
      .superRefine(({ settings }, ctx) => {
        if (settings?.showTitle && !settings?.editorText.text)
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: emptyNameDashboardWidgetMessage,
            path: ['settings', 'title', 'text'],
          });
      })
  ),
  grid: z.object({
    pages: z.array(
      z.object({
        name: z.string().trim().min(1, {
          message: emptyPageNameMsg,
        }),
      })
    ),
    items: z.array(
      z.object({
        id: z.string(),
        subGridOpts: z
          .object({
            name: z.object({
              textContent: z.string(),
            }),
            settings: z.object({
              isTitle: z.boolean(),
            }),
          })
          .optional()
          .nullable()
          .superRefine((args, ctx) => {
            if (args) {
              const { name, settings } = args;
              if (settings.isTitle && !name.textContent.replace('\n', ''))
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: emptyNameGroupWidgets,
                  path: ['grid', 'items', 'name'],
                });
            }
          }),
      })
    ),
  }),
});

const saveDashboardPartialSchema = saveDashboardSchema.deepPartial();

export {
  saveDashboardSchema,
  saveDashboardPartialSchema,
  emptyNameDashboardSchema,
  maxNameDashboardLengthMsg,
  emptyNameDashboardWidgetFilter,
  emptyNameDashboardMsg,
  maxLengthDashboardTitle,
};
