import { LoadingBar, Notify, Quasar as QuasarPlugin, Screen } from 'quasar';

import { defaultLang } from '@/../languages';

const quasarPluginConfig = {
  lang: defaultLang,
  plugins: {
    LoadingBar,
    Screen,
    Notify,
  },
  config: {
    loadingBar: { skipHijack: true },
    screen: { bodyClasses: true },
    notify: {
      position: 'bottom-right',
      classes: 'bi-notification',
      actions: [
        {
          icon: 'bi-bee bi-bee-cancel',
          color: 'black',
        },
      ],
    },
  },
};

export { QuasarPlugin, quasarPluginConfig };
