<template>
  <ul class="legal-links column items-center">
    <li v-for="legal in legals" class="legal-links__item" :key="legal.id">
      <a
        :href="legal.link"
        target="_blank"
        class="legal-links__link"
        :data-test="legal.id"
      >
        {{ legal.name }}
      </a>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { ref } from 'vue';

import { dataTests } from './tests/constants';

const legals = ref([
  {
    link: 'https://mkskom.ru/legal-documents#policy',
    name: 'Политика конфиденциальности',
    id: dataTests.POLICY,
  },
  {
    link: 'https://mkskom.ru/legal-documents#agree',
    name: 'Пользовательское соглашение',
    id: dataTests.AGREEMENT,
  },
]);
</script>
<style scoped lang="scss">
@import './Legal';
</style>
