export function useSettingsModel(model = {}) {
  const databases = model?.databases || null;
  if (!databases) return null;

  const panelSettingsDatabases = [];

  const showcaseInfo = model.showcaseInfo;

  const fields = [];
  const nameDatabase = 'default';
  const tables = [];
  showcaseInfo.columns.forEach(column => {
    fields.push({
      id: column.source,
      sourceId: column.sourceId,
      columnName: column.name,
      nullable: column.nullable,
      sourceIndex: column.sourceIndex,
      name: column.humanName,
      dataType: column.dataType,
      columnType: column.columnType,
      type: column.type,
      level: column.level,
    });
    const additionalFields = column.additionalFields;

    if (additionalFields?.length) {
      additionalFields.forEach(f => {
        fields.push({
          id: f.source,
          name: `${column.humanName}.${f.humanName}`,
          dataType: f.dataType,
          type: f.type,
          level: f.level,
        });
      });
    }
  });

  const tableName = showcaseInfo.table_name;
  const table = {
    name: tableName,
    description: showcaseInfo.table_name || '',
    fields,
  };

  tables.push(table);

  panelSettingsDatabases.push({ name: nameDatabase, tables });

  return {
    settings: panelSettingsDatabases,
  };
}
