<template>
  <div class="bee-radio-group" data-test="bi-bee-radio-group">
    <div
      v-if="label"
      class="bee-radio-group__label"
      data-test="bi-bee-radio-group-label"
    >
      {{ label }}
    </div>
    <div
      :style="{ display: 'flex', 'flex-direction': direction }"
      class="bee-radio-group__body"
    >
      <div
        v-for="(item, indx) in valueList"
        class="bee-radio-box"
        data-test="bi-bee-radio-box"
        :key="indx"
      >
        <!-- v-model="selectedValue" -->
        <!-- немного изменил т.к необходимость чтобы один из инпутов был отмечен по умолчанию -->
        <input
          @input="$emit('changed', $event.target._value)"
          :value="item.value || item.id"
          class="bee-radio-input"
          type="radio"
          :checked="item.value === checked || item.id === checked"
          data-test="bi-bee-radio-input"
        />
        <label
          @click="handleLabelClick(item.id || item.value)"
          class="bee-radio-label"
          :class="{ 'bee-radio-label-clickable': isLabelClickable }"
          data-test="bi-bee-radio-label"
          >{{ item.name }}</label
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, withDefaults } from 'vue';

interface IRadioButtonProps {
  direction?: string;
  checked: number | string | boolean;
  valueList: Array<Record<string, any>>;
  label?: string;
  isLabelClickable?: boolean;
}

const props = withDefaults(defineProps<IRadioButtonProps>(), {
  valueList: () => [],
  direction: 'column',
  label: '',
  isLabelClickable: false,
});

const emit = defineEmits(['changed']);

const handleLabelClick = (itemId: string) => {
  if (!props.isLabelClickable) return;
  emit('changed', itemId);
};
</script>

<style lang="scss" scoped>
@import './RadioButton';
</style>
