import { loadModules } from '@clearview/helpers';

let loadModulesApiServices = () => {
  console.log('loadModulesApiServices plug :)');
};

try {
  loadModulesApiServices = () => {
    const overrideImportedAbsolutePath = loadModules(
      import.meta.globEager('./**.ts'),
      fileName => fileName.slice(2, -3)
    );
    return Object.keys(overrideImportedAbsolutePath)
      .map(file => ({ path: file, name: file.split('/').pop() }))
      .reduce((modules, file) => {
        modules[file.name] = overrideImportedAbsolutePath[file.path];
        return modules;
      }, {});
  };
} catch (error) {
  //
}

const modulesApiServicesImported = loadModulesApiServices();
export default modulesApiServicesImported;
