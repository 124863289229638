/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ObjectRightsRequestDto = {
    objectId: string;
    userId: string;
    userSub: string;
    accessType: ObjectRightsRequestDto.accessType;
};

export namespace ObjectRightsRequestDto {

    export enum accessType {
        READ = 'READ',
        WRITE = 'WRITE',
    }


}

