import { fetchWithTimeout, sleep } from '@clearview/helpers';

import errors from './errors.json';

export const enum Mode {
  Development = 'development',
  Production = 'production',
}

export interface IBaseApiServiceParams {
  basePath?: string;
  className?: string;
  error?: boolean;
  mode?: Mode;
}

export interface IError {
  code: string;
  message: string;
}

export const enum RequestMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export type RequestMethodType = keyof typeof RequestMethod;

export interface IFetchDataObjectBase {
  data?: any;
  delay?: number;
  method?: string;
  timeout?: number;
}

export interface IApiBaseResponse<T> {
  _response: Response;
  errors: IError[];
  response?: {
    response: T;
    errors: IError[];
    status: boolean;
  };
  status: boolean;
  statusCode: number;
}

export type SearchParams =
  | string
  | Record<string, string | string[] | number>
  | URLSearchParams
  | string[][]
  | null;

export interface IBaseApiService extends IBaseApiServiceParams {
  ERRORS?: Record<string, string>;

  sendRequest?: (
    path: string,
    params?: SearchParams,
    options?: RequestInit,
    object?: IFetchDataObjectBase
  ) => Promise<any>;
}

export interface InterceptorRequestParams {
  url: string;
  method: RequestMethodType;
  options: RequestInit;
  optionsPure: RequestInit;
}
export interface InterceptorResponseParams {
  url: string;
  data: unknown;
  method: RequestMethodType;
  response: Response;
}

export type InterceptorRequest = (params: InterceptorRequestParams) => any;
export type InterceptorResponse = (params: InterceptorResponseParams) => any;

const settings = {
  basePath: '',
  className: '',
  error: false,
  mode: Mode.Production,
};

const responseInterceptor: InterceptorResponse = async ({ data, response }) => {
  if (response.bodyUsed) return data;

  const contentType = response.headers.get('content-type');
  if (!contentType?.includes('application/json')) return data;

  let dataProcessed = await response.json();
  const dataResult = dataProcessed?.data?.result;
  if (dataResult) dataProcessed = dataResult;

  return dataProcessed;
};

export default class BaseApiService implements IBaseApiService {
  static interceptors: {
    req: InterceptorRequest[];
    res: InterceptorResponse[];
  } = {
    req: [],
    res: [responseInterceptor],
  };

  basePath: string;
  className: string;
  error: boolean;
  mode: Mode;

  ERRORS: Record<string, string>;

  constructor(params: IBaseApiServiceParams = settings) {
    this.basePath = params.basePath || settings.basePath;
    this.className = params.className || this.constructor.name;
    this.error = params.error || settings.error;
    this.mode = params.mode || settings.mode;

    this.ERRORS = errors;

    if (this.mode === Mode.Development) {
      // _eslint-disable-next-line prettier/prettier
      this._log(
        '%c' + Mode.Development + ' mode',
        'background: yellow; color: #000;'
      );
    }
  }

  static register(
    cb: InterceptorRequest | InterceptorResponse,
    isRes?: boolean
  ) {
    BaseApiService.interceptors[isRes ? 'res' : 'req'].push(cb);
  }

  static unregister(cb: InterceptorRequest | InterceptorResponse): boolean {
    const interceptors = BaseApiService.interceptors;

    let cbIndex = interceptors.req.findIndex(i => i === cb);
    if (cbIndex >= 0) {
      interceptors.req.splice(cbIndex, 1);
      return true;
    }

    cbIndex = interceptors.res.findIndex(i => i === cb);
    if (cbIndex >= 0) {
      interceptors.res.splice(cbIndex, 1);
      return true;
    }

    return false;
  }

  async sendRequest<TResponse>(
    path: string,
    params: SearchParams = {},
    options: RequestInit = {},
    { data, delay = 10, method, timeout }: IFetchDataObjectBase = {
      data: undefined,
      method: undefined,
      timeout: 10000,
    }
  ): Promise<IApiBaseResponse<TResponse>> {
    let fetchResponse;

    try {
      if (this.mode === Mode.Production) {
        /* Options */
        let fetchOptions: RequestInit = options
          ? JSON.parse(JSON.stringify(options))
          : {}; // TODO: fix deep copy
        fetchOptions.headers = {
          ...(fetchOptions.headers || {}),
        };

        if (fetchOptions.body) {
          // TODO: Add body check
          if (
            fetchOptions.method === RequestMethod.POST ||
            fetchOptions.method === RequestMethod.PUT
          ) {
            const fetchHeaders = fetchOptions.headers;

            if (fetchHeaders['Content-Type']) {
              if (fetchHeaders['Content-Type'].indexOf('application/json') < 0)
                fetchHeaders['Content-Type'] += '; application/json';
            } else {
              fetchHeaders['Content-Type'] = 'application/json';
            }
          }

          if (options.body instanceof FormData) {
            fetchOptions.body = options.body;
            delete fetchOptions.headers['Content-Type'];
          }
        }

        /* Params */
        const queryParams = params
          ? new URLSearchParams(params).toString()
          : '';
        const url =
          this.basePath + path + (queryParams.length ? '?' + queryParams : '');

        /* Request interceptors */
        let interceptors = BaseApiService.interceptors.req;
        const fetchOptionsPure: RequestInit = JSON.parse(
          JSON.stringify(fetchOptions)
        ); // TODO: fix deep copy
        if (interceptors.length) {
          for (const interceptor of interceptors) {
            fetchOptions = await interceptor({
              method,
              options: fetchOptions,
              optionsPure: fetchOptionsPure,
              url,
            });
          }
        }

        /* Fetching */
        const fetchFunc = timeout ? fetchWithTimeout : fetch;

        /* Processing response */
        fetchResponse = await fetchFunc(url, fetchOptions);
        let fetchResponseData;

        /* Response interceptors */
        interceptors = BaseApiService.interceptors.res;
        if (interceptors.length) {
          for (const interceptor of interceptors) {
            fetchResponseData = await interceptor({
              data: fetchResponseData,
              method,
              response: fetchResponse,
              url,
            });
          }
        }

        if (!fetchResponse.bodyUsed) {
          try {
            const contentType = fetchResponse.headers.get('content-type');

            if (contentType) {
              if (
                contentType.includes('application/json') ||
                contentType.includes('application/v2+json') ||
                contentType.includes('text/plain')
              ) {
                fetchResponseData = await fetchResponse.json();

                if (
                  fetchResponseData &&
                  typeof fetchResponseData === 'string'
                ) {
                  fetchResponseData = JSON.parse(fetchResponseData);
                }
              }
            }
          } catch (error) {
            this._logWarn({
              error,
              method: method || 'sendRequest',
            });
          }
        }

        return {
          _response: fetchResponse,
          errors: [],
          response: this._getMergeData(
            this._processResponseData(fetchResponseData, method),
            method
          ),
          status: true,
          statusCode: fetchResponse.status,
        };
      } else {
        await sleep(delay);

        return this.error
          ? {
              _response: fetchResponse,
              errors: [this._getErrorDescription('0')],
              response: null,
              status: false,
              statusCode: fetchResponse.status,
            }
          : {
              _response: fetchResponse,
              errors: [],
              response: this._getMergeData(data, method),
              status: true,
              statusCode: fetchResponse.status,
            };
      }
    } catch (error) {
      this._logError({ error, method });
      let data;
      if (fetchResponse) {
        try {
          data = await fetchResponse.json();

          if (typeof data === 'string' && data) {
            data = JSON.parse(data);
          }
        } catch (error) {
          this._logWarn({
            error,
            method: method || 'sendRequest',
          });
        }
      }

      return {
        _response: fetchResponse,
        errors: [{ code: null, message: error.message }],
        response: null,
        status: false,
        statusCode: fetchResponse.status,
      };
    }
  }

  /* Protected */
  _getErrorDescription(code: string): IError {
    return {
      code,
      message: this.ERRORS[code],
    };
  }

  _log(...params) {
    console.log(
      '[' +
        this.className +
        '] ' +
        (typeof params[0] === 'object' ? JSON.stringify(params[0]) : params[0]),
      ...params.slice(1)
    );
  }

  _logError({ error, method }) {
    console.error('[' + this.className + '] ' + method + ' | ' + error.message);
  }

  _logWarn({ error, method }) {
    console.warn('[' + this.className + '] ' + method + ' | ' + error.message);
  }

  _processResponseData(response: unknown, method: string) {
    if (method) {
      //
    }

    return response;
  }

  _getMergeData(data: unknown, field: string) {
    if (field) {
      //
    }

    return data;
  }
}
