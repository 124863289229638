/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataSourceElementDto } from './DataSourceElementDto';

export type DataSourceResponse = {
    id: string;
    name: string;
    type: DataSourceResponse.type;
    dataSourceElements?: Array<DataSourceElementDto>;
};

export namespace DataSourceResponse {

    export enum type {
        XLSX = 'XLSX',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CSV = 'CSV',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

