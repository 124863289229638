import cloneDeep from 'lodash.clonedeep';

import { EChartTypes } from '@/types';

import { colorThemes } from '../constants';

export function getThemeColors(
  themeName: string,
  chartType: string,
  startColor: string,
  reverse: boolean
) {
  const colorsThemesOptions = cloneDeep(colorThemes);
  const theme =
    colorsThemesOptions.find(theme => theme.name === themeName) ??
    colorsThemesOptions[0];
  let colors = theme.colors;
  if (startColor && theme.colors[0] !== startColor) {
    const colorId = theme.colors.findIndex(c => c === startColor);
    colors = [
      ...colors.slice(colorId, colors.length),
      ...colors.slice(0, colorId),
    ];
  }
  if (reverse) {
    colors = [
      ...colors.slice(0, 1),
      ...colors.slice(1, colors.length).reverse(),
    ];
  }
  const oddColors = colors.filter((c, indx) => indx % 2 === 0);
  if (chartType === EChartTypes.Map) {
    return {
      '2': oddColors.slice(0, 2),
      '4': oddColors.slice(0, 4),
      '5': oddColors,
      '10': colors,
    };
  }
  return oddColors;
}
