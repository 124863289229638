import { apiServices as baseApiServices } from '../services';
import { Alerts } from './Alerts';
import createEntityApiMiddleware, {
  EntityApiMiddlewareType,
} from './createEntityApiMiddleware';

export default function getApiMiddlewares(
  apiSettings,
  additionalApiServices = {}
): { middlewares: Record<string, EntityApiMiddlewareType>; alerts: Alerts } {
  const middlewares: Record<string, EntityApiMiddlewareType> = {};
  const alerts = new Alerts(apiSettings);
  if (!apiSettings) return { middlewares, alerts };

  const apiServices = { ...baseApiServices, ...additionalApiServices };

  for (const entityIdCamelized in apiServices) {
    middlewares[entityIdCamelized] = createEntityApiMiddleware(
      entityIdCamelized,
      apiSettings,
      apiServices
    );
  }

  return { middlewares, alerts };
}
