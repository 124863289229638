import isEqual from 'lodash.isequal';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

import type { DynamicFilterType } from '@/types/filter';
import { clone } from '@clearview/helpers';

export type DashboardDynamicStoreColumnsInfo = Record<
  string,
  {
    selectedWidgetId: string | null;
    values: unknown[];
    type: DynamicFilterType;
  }
>;

export const useDynamicFilterInfo = defineStore('dynamicFilterInfo', () => {
  const dynamicFiltersInfo = reactive<
    Record<string, DashboardDynamicStoreColumnsInfo>
  >({});
  const removingFiltersInfo = reactive({});

  const setDynamicFilter = (
    selectedWidgetId: string,
    modelId: string,
    entries: Array<[colId: string, values: unknown[]]>,
    isCalculated: boolean
  ) => {
    const exists =
      !!dynamicFiltersInfo[modelId] &&
      entries.length === Object.keys(dynamicFiltersInfo[modelId]).length &&
      entries.every(([columnId, values]) => {
        const col = dynamicFiltersInfo[modelId][columnId];
        return col && isEqual(col.values, values);
      });

    // в случае, если добавляемый фильтр уже существует и мы будем его удалять,
    // запоминаем id виджета, чтобы он не обновлялся
    removingFiltersInfo[modelId] = exists ? selectedWidgetId : null;

    // В любом случае обнуляем инфу по модели
    dynamicFiltersInfo[modelId] = {};

    // Если есть уникальное - добавляем
    if (!exists) {
      entries.forEach(([columnId, values]) => {
        dynamicFiltersInfo[modelId][columnId] = {
          selectedWidgetId,
          values: clone(values),
          type: isCalculated ? 'DYNAMIC_FORMULA' : 'DYNAMIC',
        };
      });
    }
  };

  const getDynamicFilterInfoByModelId = (
    modelId: string
  ): DashboardDynamicStoreColumnsInfo | null => {
    return dynamicFiltersInfo[modelId] || null;
  };

  const getRemovingFiltersInfoByModelId = modelId =>
    removingFiltersInfo[modelId];

  const removeDynamicFilters = (modelId: string) => {
    if (dynamicFiltersInfo[modelId]) {
      delete dynamicFiltersInfo[modelId];
    }
  };

  return {
    dynamicFiltersInfo,
    setDynamicFilter,
    getDynamicFilterInfoByModelId,
    removeDynamicFilters,
    getRemovingFiltersInfoByModelId,
  };
});
