import createSchemaTableFromModel from './createSchemaTableFromModel';

export default function createSchemaFromModel(tableSchema) {
  return {
    databases: [
      {
        name: 'default',
        tables: [createSchemaTableFromModel(tableSchema)],
      },
    ],
  };
}
