import { camelize, replacePlaceholders } from '@clearview/helpers';

import EntityBaseApiService, {
  IEntityBaseApiService,
  IEntityBaseApiServiceParams,
} from '../services/__base/EntityBaseApiService';
import getEntityApiServiceName from './../helpers/getEntityApiServiceName';
import getModuleApiServiceName from './../helpers/getModuleApiServiceName';
import apiServicesModules from './../services/__modules';

export type ApiServiceType = (
  params: IEntityBaseApiServiceParams
) => IEntityBaseApiService;

/**
 * Generate apiServices from JSON
 */
export default function generateApiServices(
  entitiesModulesMap: Record<string, any> = {},
  entitiesInfo: Record<string, any> = {},
  modulesApiServices: Record<string, any> = {}
) {
  const apiServices: Record<string, ApiServiceType> = {};

  const modulesApiServicesToUse = {
    ...apiServicesModules,
    ...modulesApiServices,
  };

  try {
    /* Check all entities with API modules mappings */
    for (const entityId in entitiesModulesMap) {
      const entityIdCamelized = camelize(entityId);
      const apiServiceName = getEntityApiServiceName(entityIdCamelized);

      /* Define API service constructor params & methods */
      let BaseApiService = EntityBaseApiService;
      const constructorParams: IEntityBaseApiServiceParams = {
        className: apiServiceName,
      };
      const entityInfo = entitiesInfo[entityId];
      let entityInfoMethods;

      /* Extract entity additional info */
      if (entityInfo) {
        constructorParams.itemFieldId = entityInfo.itemFieldId;
        constructorParams.moduleName = entityInfo.module;
        entityInfoMethods = entityInfo.methods;

        const entityApiModule = entityInfo.module;
        if (entityApiModule) {
          BaseApiService =
            modulesApiServicesToUse[getModuleApiServiceName(entityApiModule)];
        }
      }

      /* Create API service */
      apiServices[entityIdCamelized] = (
        params: IEntityBaseApiServiceParams = {}
      ) => {
        let apiService;

        try {
          apiService = new BaseApiService({
            ...constructorParams,
            ...params,
          });
        } catch (error) {
          console.warn('ERROR! generateApiServices.', entityIdCamelized);
        }

        if (!entityInfoMethods) return apiService;

        /* Describe methods */
        for (const method in entityInfoMethods) {
          const methodInfo = entityInfoMethods[method];
          apiService[method] = async (
            pathParts,
            params,
            payload,
            payloadType = 'json'
          ) => {
            return await apiService._sendRequest(
              replacePlaceholders(methodInfo.path, pathParts || {}),
              params,
              {
                body:
                  payloadType === 'json' ? JSON.stringify(payload) : payload,
                method: methodInfo.method,
              },
              { method }
            );
          };
        }
        return apiService;
      };
    }
  } catch (error) {
    console.warn('generateApiServices', error.message);
  }

  return apiServices;
}
