/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataSourceElementDynamicParamRequest = {
    dataSourceElementId: string;
    type?: DataSourceElementDynamicParamRequest.type;
    encoding?: DataSourceElementDynamicParamRequest.encoding;
    separator?: DataSourceElementDynamicParamRequest.separator;
    firstLineHeader?: boolean;
};

export namespace DataSourceElementDynamicParamRequest {

    export enum type {
        XLSX_LIST = 'XLSX_LIST',
        XLSX_TABLE = 'XLSX_TABLE',
        PG_TABLE = 'PG_TABLE',
        ONE_C_DATA = 'ONE_C_DATA',
        CSV_FILE = 'CSV_FILE',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL_TABLE = 'MS_SQL_TABLE',
        MYSQL_TABLE = 'MYSQL_TABLE',
    }

    export enum encoding {
        UTF_8 = 'UTF_8',
        UTF_16 = 'UTF_16',
        UTF_32 = 'UTF_32',
        ASCII = 'ASCII',
        WINDOWS_1251 = 'WINDOWS_1251',
        MACCYRILLIC = 'MACCYRILLIC',
        KOI8_R = 'KOI8_R',
    }

    export enum separator {
        COMMA = 'COMMA',
        SEMICOLON = 'SEMICOLON',
        TAB = 'TAB',
        PIPE = 'PIPE',
        SPACE = 'SPACE',
    }


}

