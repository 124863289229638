type ApiSettingsType = Record<string, any>;

const getInitialSettings: () => ApiSettingsType = () => ({
  entitiesModulesMap: {},
  hosts: {} || '',
  modulesHostsMap: {},
});

const settingsNames = Object.keys(getInitialSettings());

interface IModule {
  host: string;
  hostName: string;
  path: string;
}

export interface IEntityApiInfo {
  module: IModule;
  path: string;
  pathAdditional: string;
}

export interface IEntityApiPath {
  modulePath: string;
  path: string;
  url: string;
}

export default function getEntityApiInfo(
  path: string,
  apiSettings: ApiSettingsType = {
    entitiesModulesMap: {},
    hosts: {} || '',
    modulesHostsMap: {},
  }
) {
  const questionIndex = path.indexOf('?');

  const pathWithoutParams =
    questionIndex >= 0 ? path.slice(0, questionIndex) : path;

  const firstDividerIndex = path.indexOf('/');

  let entityName;
  let pathAdditional = '';
  const settings = getInitialSettings();

  if (pathWithoutParams[0] === '_') {
    const moduleName = pathWithoutParams.slice(0, firstDividerIndex);
    const secondDividerIndex = pathWithoutParams.indexOf(
      '/',
      firstDividerIndex + 1
    );

    if (secondDividerIndex >= 0) {
      entityName = pathWithoutParams.slice(
        firstDividerIndex + 1,
        secondDividerIndex
      );
      pathAdditional = pathWithoutParams.slice(secondDividerIndex);
    } else {
      entityName = pathWithoutParams.slice(firstDividerIndex + 1);
    }
    settingsNames.forEach(settingName => {
      settings[settingName] =
        apiSettings[settingName][moduleName] || apiSettings[settingName];
    });
  } else {
    if (firstDividerIndex >= 0) {
      entityName = pathWithoutParams.slice(0, firstDividerIndex);
      pathAdditional = pathWithoutParams.slice(firstDividerIndex);
    } else {
      entityName = pathWithoutParams;
    }

    settingsNames.forEach(settingName => {
      settings[settingName] = apiSettings[settingName];
    });
  }

  const {
    entitiesModulesMap = {},
    hosts = {} || '',
    modulesHostsMap = {},
  } = settings;

  const entityApiInfo: IEntityApiInfo = {
    module: {
      host: '',
      hostName: '',
      path: '',
    },
    path: '',
    pathAdditional: '',
  };

  const entityModule = entitiesModulesMap[entityName];

  if (!entityModule) return entityApiInfo;

  const entityModuleHost = modulesHostsMap[entityModule.name];

  if (!entityModuleHost) return entityApiInfo;

  entityApiInfo.module.host = hosts?.[entityModuleHost.name] || hosts;
  entityApiInfo.module.hostName = entityModuleHost.name;
  entityApiInfo.module.path = entityModuleHost.path;
  entityApiInfo.path = entityModule.path;
  entityApiInfo.pathAdditional = pathAdditional;

  // console.log('entityApiInfo', entityApiInfo);
  // console.groupEnd();

  return entityApiInfo;
}
