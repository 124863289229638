import { defineStore } from 'pinia';
import { ref } from 'vue';

import { DashboardInfo } from '@/types';

export const useDashboardInfo = defineStore('dashboardInfo', () => {
  const hasElementGridChanged = ref<boolean>(false);

  const dashboardInfo = ref<DashboardInfo | null>({
    isFilterOpened: false,
  });

  const setNewDashboardInfo = (info: DashboardInfo) => {
    dashboardInfo.value = info;
  };

  const deleteDashboardInfo = () => {
    dashboardInfo.value = null;
  };

  const setFilterOpened = value => {
    if (dashboardInfo.value) dashboardInfo.value.isFilterOpened = value;
  };

  const setModeView = value => {
    if (dashboardInfo.value) dashboardInfo.value.isModeView = value;
  };

  const setPages = pages => {
    if (dashboardInfo.value) dashboardInfo.value.pages = pages;
  };

  const setTabId = value => {
    if (dashboardInfo.value) dashboardInfo.value.tabId = value;
  };

  return {
    dashboardInfo,
    setNewDashboardInfo,
    deleteDashboardInfo,
    setFilterOpened,
    setModeView,
    setPages,
    setTabId,
    hasElementGridChanged,
  };
});
