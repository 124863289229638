/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Border = {
    color?: string;
    form: Border.form;
    border: string;
};

export namespace Border {

    export enum form {
        SOLID = 'solid',
        OUTLINED = 'outlined',
        TRANSPARENT = 'transparent',
    }


}

