/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AnalyticStructure } from './AnalyticStructure';
import type { JsonNode } from './JsonNode';

export type WidgetResponseListDto = {
    id: string;
    name: string;
    projectId: string;
    modelId: string;
    analyticStructure?: AnalyticStructure;
    type?: WidgetResponseListDto.type;
    subtype?: WidgetResponseListDto.subtype;
    uiOptions?: JsonNode;
    creationTime: string;
    ownerName?: string;
};

export namespace WidgetResponseListDto {

    export enum type {
        TABLE = 'table',
        CHART = 'chart',
        IMAGE = 'image',
        FILTER = 'filter',
    }

    export enum subtype {
        LINE = 'line',
        BAR = 'bar',
        BAR_TOTAL = 'barTotal',
        PIE = 'pie',
        BIG_NUMBER = 'bigNumber',
        TEXT = 'text',
        BASE_TABLE = 'base_table',
        CHART_MAP = 'chartMap',
        IMAGE = 'image',
        RADAR = 'radar',
        TREEMAP = 'treemap',
        SUNBURST = 'sunburst',
        MIX_LINE_BAR = 'mixLineBar',
        LINE_BAR = 'lineBar',
        LINE_BAR_TOTAL = 'lineBarTotal',
    }


}

