import { defineStore } from 'pinia';
import { watch } from 'vue';

import { availableLangs } from '@/../languages';
import { COMMON__LANG } from '@/bi-core/constants';
import { useLang } from '@/composables';
import { useStorage } from '@vueuse/core';

export const useLangStore = defineStore('language', () => {
  const { lang: _lang } = useLang();

  const lang = useStorage(COMMON__LANG, _lang.value.isoName);

  watch(lang, value => {
    _lang.value = availableLangs[value];
  });

  return {
    lang,
  };
});
