import { Ref, inject, onMounted, ref } from 'vue';

import { useWidgetInfoStore } from '@/store/bi-store';
import { PROVIDE__CURRENT_FIELD_STRUCTURE } from '@bi-core/constants';
import RadioButton from '@bi-views/views/components/RadioButton';
import { Input } from '@clearview/ui';
import { useDebounceFn } from '@vueuse/core';

import SettingWrapper from '../../SettingWrapper';
import { defaultAxisTitleOptions } from '../constants';

interface AxisTitle {
  mode: string;
  value: string | null;
}

export const defaultValue: AxisTitle = {
  mode: 'off',
  value: null,
};

export default {
  setup() {
    const store = useWidgetInfoStore();
    const field = inject(PROVIDE__CURRENT_FIELD_STRUCTURE);

    if (!field?.value) {
      return () => {
        return null;
      };
    }

    const settingsTitle = {
      on: 'on',
      off: 'off',
      manual: 'manual',
    };

    const axisTitle: Ref<AxisTitle> = ref({ ...defaultValue });

    const updateSettings = (fieldId, settings) => {
      store.updateSettings(fieldId, settings);
    };

    onMounted(() => {
      const settings = store.getSettingsByFieldId(field.value.id);

      axisTitle.value = { ...settings.title };
    });

    const changeTitle = (e, fieldName) => {
      axisTitle.value[fieldName] = e;
      const settings = store.getSettingsByFieldId(field.value.id);
      const newSetting = { ...settings, title: axisTitle.value };
      updateSettings(field.value.id, newSetting);
    };

    const hanldeSetTitleName = useDebounceFn(e => {
      changeTitle(e, 'value');
    }, 300);

    return () => {
      return (
        <div>
          <SettingWrapper title="Название оси">
            <div>
              <RadioButton
                onChanged={e => changeTitle(e, 'mode')}
                value-list={defaultAxisTitleOptions}
                checked={axisTitle.value.mode}
              />
              <Input
                onUpdate:modelValue={hanldeSetTitleName}
                modelValue={axisTitle.value.value}
                disabled={axisTitle.value.mode !== settingsTitle.manual}
              />
            </div>
          </SettingWrapper>
        </div>
      );
    };
  },
};
