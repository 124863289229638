/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Source = {
    attribute?: string;
    database?: string;
    table?: string;
    type?: string;
    function?: string;
    modelId?: string;
    formula?: string;
    aggregateFunction?: Source.aggregateFunction;
    aggregateDynamicFunc?: Source.aggregateDynamicFunc;
    functionParams?: Array<any>;
};

export namespace Source {

    export enum aggregateFunction {
        SUM = 'SUM',
        AVG = 'AVG',
        COUNT = 'COUNT',
        UNIQ = 'UNIQ',
        MIN = 'MIN',
        MAX = 'MAX',
        NONE = 'NONE',
        AUTO = 'AUTO',
    }

    export enum aggregateDynamicFunc {
        COUNT_IF = 'countIf',
        AVG_IF = 'avgIf',
        MIN_IF = 'minIf',
        MAX_IF = 'maxIf',
        SUM_IF = 'sumIf',
    }


}

