/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ObjectsRelationsFilterRequestDto = {
    /**
     * Идентификатор вышестоящего объекта системы
     */
    parentId?: string;
    /**
     * Тип объекта системы, подлежащего фильтрации
     */
    systemObjectType: ObjectsRelationsFilterRequestDto.systemObjectType;
    /**
     * Тип столбца, по значениям которого осуществляется фильтрация
     */
    tableColumnToFilter: string;
};

export namespace ObjectsRelationsFilterRequestDto {

    /**
     * Тип объекта системы, подлежащего фильтрации
     */
    export enum systemObjectType {
        WORKSPACE = 'WORKSPACE',
        PROJECT = 'PROJECT',
        DASHBOARD = 'DASHBOARD',
        CONNECTION = 'CONNECTION',
        MODEL = 'MODEL',
        WIDGET = 'WIDGET',
        SHOWCASE = 'SHOWCASE',
    }


}

