import { IEntityBaseApiServiceParams } from '@/api/client';
import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import { IApiRequestParams } from '@/types';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'ProjectApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class ProjectApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  fetchAllByFilters<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'> & { workspaceId: string },
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', meta);

    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }
}
