import { BIBeeLogger as BiLogger } from '@clearview/logger';
import { ELogLevels } from '@clearview/logger/dist/types/interface';

import getConstants from '../../src/bi-core/constants/env';

const constants = getConstants();
const { isModeDevelopment } = constants;

const logger = new BiLogger({
  logLevel: isModeDevelopment ? ELogLevels.TRACE : ELogLevels.WARN,
});

const info = logger.info;
const debug = logger.debug;
const error = logger.error;
const warn = logger.warn;
const log = logger.log;

export { logger, info, debug, error, warn, log };
