/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataSourceElementStructureDto } from './DataSourceElementStructureDto';

export type DataSourceElementResponse = {
    elementId: string;
    dataSourceId: string;
    modelId?: string;
    elementName: string;
    elementType: DataSourceElementResponse.elementType;
    structure: DataSourceElementStructureDto;
    preView: Array<Array<any>>;
    encoding?: DataSourceElementResponse.encoding;
    separator?: DataSourceElementResponse.separator;
    firstLineHeader?: boolean;
};

export namespace DataSourceElementResponse {

    export enum elementType {
        XLSX_LIST = 'XLSX_LIST',
        XLSX_TABLE = 'XLSX_TABLE',
        PG_TABLE = 'PG_TABLE',
        ONE_C_DATA = 'ONE_C_DATA',
        CSV_FILE = 'CSV_FILE',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL_TABLE = 'MS_SQL_TABLE',
        MYSQL_TABLE = 'MYSQL_TABLE',
    }

    export enum encoding {
        UTF_8 = 'UTF_8',
        UTF_16 = 'UTF_16',
        UTF_32 = 'UTF_32',
        ASCII = 'ASCII',
        WINDOWS_1251 = 'WINDOWS_1251',
        MACCYRILLIC = 'MACCYRILLIC',
        KOI8_R = 'KOI8_R',
    }

    export enum separator {
        COMMA = 'COMMA',
        SEMICOLON = 'SEMICOLON',
        TAB = 'TAB',
        PIPE = 'PIPE',
        SPACE = 'SPACE',
    }


}

