export function getRounding(rounding?: number | null): string {
  let _rounding = '';
  if (rounding) {
    _rounding = '0.';
    for (let index = 0; index < rounding; index++) {
      _rounding += '0';
    }
  }
  return _rounding;
}
