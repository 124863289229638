/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionalFormatCondition } from './ConditionalFormatCondition';

export type ConditionalFormat = {
    name?: string;
    unionCondition?: ConditionalFormat.unionCondition;
    conditions?: Array<ConditionalFormatCondition>;
};

export namespace ConditionalFormat {

    export enum unionCondition {
        OR = 'or',
        AND = 'and',
    }


}

