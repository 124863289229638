import { EChartTypes } from '@/types';

import { EZoomTypes } from '../types';

const DESCRIPTION_TYPES = [
  {
    id: 'chart-line',
    alias: 'Линейный график',
    name: 'Линейный график',
  },
  {
    id: 'chart-pie',
    alias: 'Диаграмма',
    name: 'Круговая диаграмма',
  },
  {
    id: 'chart-bar',
    alias: 'Столбчатая диаграмма',
    name: 'Столбчатая диаграмма',
  },
  {
    id: 'chart-line-bar',
    alias: 'Линейчатая диаграмма',
    name: 'Линейчатая диаграмма',
  },
  {
    id: 'chart-bar-total',
    alias: 'Столбчатая диаграмма с накоплением',
    name: 'Столбчатая диаграмма с накоплением',
  },
  {
    id: 'chart-line-bar-total',
    alias: 'Линейчатая диаграмма с накоплением',
    name: 'Линейчатая диаграмма с накоплением',
  },
  {
    id: 'chart-radar',
    alias: 'Радар',
    name: 'Радар',
  },
  {
    id: 'chart-treemap',
    alias: 'Древовидная карта',
    name: 'Древовидная карта',
  },
  {
    id: 'chart-sunburst',
    alias: 'Солнцевидная диаграмма',
    name: 'Солнцевидная диаграмма',
  },
  {
    id: 'chart-mix-line-bar',
    alias: 'Комбинированная диаграмма',
    name: 'Комбинированная диаграмма',
  },
  {
    id: 'chart-text',
    alias: 'Текст',
    name: 'Текстовое значение',
  },
  {
    id: 'chart-big-number',
    alias: 'Число',
    name: 'Счётчик',
  },
  {
    id: 'chart-map',
    alias: 'Карта',
    name: 'Карта',
  },
];

const COMMON_LABEL_TYPES = [
  {
    id: 'value',
    name: 'Значение',
  },
  {
    id: 'category',
    name: 'Название категории',
  },
  {
    id: 'category-value',
    name: 'Категория и значение',
  },
];

const COMMON_FORMATTING = [
  { id: 'space', name: 'Пробелы' },
  { id: 'percent', name: 'Проценты, %' },
  { id: '', name: 'Не задано' },
];

const COMMON_ROTATE = [
  {
    id: '0',
    name: '0',
  },
  {
    id: 45,
    name: '45',
  },
  {
    id: 90,
    name: '90',
  },
];

const COMMON_POSITION_TYPES = [
  { id: 'bottom', name: 'Снизу' },
  { id: 'top', name: 'Сверху' },
  { id: 'left', name: 'Слева' },
  { id: 'right', name: 'Справа' },
];

const COMMON_POSITION_ICON = [
  {
    icon: { name: 'block-align-bottom', width: '20px', height: '20px' },
  },
  {
    icon: { name: 'block-align-top', width: '20px', height: '20px' },
  },
  {
    icon: { name: 'block-align-left', width: '20px', height: '20px' },
  },
  {
    icon: { name: 'block-align-right', width: '20px', height: '20px' },
  },
];
const BIG_NUMBER_SIZE = [
  { id: '36', name: 'Маленький' },
  { id: '48', name: 'Средний' },
  { id: '60', name: 'Большой' },
  { id: '76', name: 'Крупный' },
];
const COMMON_POSITON = COMMON_POSITION_TYPES.map((elem, index) => {
  return {
    ...COMMON_POSITION_ICON[index],
    ...elem,
  };
});

const PIE_POSITION_LABEL_TYPES = [
  { id: 'outside', name: 'Снаружи' },
  { id: 'inside', name: 'Внутри' },
];

const BIG_NUMBER_POSITION_LEGEND = [
  {
    id: 'bottom',
    name: 'Снизу',
    icon: { name: 'block-align-bottom', width: '20px', height: '20px' },
  },
  {
    id: 'right',
    name: 'Справа',
    icon: { name: 'block-align-right', width: '20px', height: '20px' },
  },
];

const PIE_POSITION_LABEL_ICON = [
  {
    icon: {
      name: 'block-inside',
      height: '17px',
      width: '25px',
    },
  },
  {
    icon: {
      name: 'block-outside',
      height: '20px',
      width: '25px',
    },
  },
];

const PIE_POSITION_LABEL = PIE_POSITION_LABEL_TYPES.map((elem, index) => {
  return {
    ...PIE_POSITION_LABEL_ICON[index],
    ...elem,
  };
});

const MAP_POSITION_LEGEND_TYPES = [
  { id: 'top-left', name: 'Слева сверху' },
  { id: 'top-right', name: 'Справа сверху' },
  { id: 'bottom-left', name: 'Слева снизу' },
  { id: 'bottom-right', name: 'Справа снизу' },
];

const MAP_POSITION_LEGEND_ICONS = [
  {
    icon: { name: 'block-align-top-left', width: '20px', height: '20px' },
  },
  {
    icon: { name: 'block-align-top-right', width: '20px', height: '20px' },
  },
  {
    icon: { name: 'block-align-bottom-left', width: '20px', height: '20px' },
  },
  {
    icon: {
      name: 'block-align-bottom-right',
      width: '20px',
      height: '20px',
    },
  },
];

const MAP_POSITION_LEGEND = MAP_POSITION_LEGEND_TYPES.map((elem, index) => {
  return {
    ...MAP_POSITION_LEGEND_ICONS[index],
    ...elem,
  };
});

const TREEMAP_POSITION_LABEL_TYPES = [
  { id: 'insideBottom', name: 'Снизу' },
  { id: 'insideTop', name: 'Сверху' },
  { id: 'insideLeft', name: 'Слева' },
  { id: 'insideRight', name: 'Справа' },
];

const TREEMAP_POSITION_LABEL = TREEMAP_POSITION_LABEL_TYPES.map(
  (elem, index) => {
    return {
      ...COMMON_POSITION_ICON[index],
      ...elem,
    };
  }
);
const POSITION_LABEL = {
  [EChartTypes.Line]: COMMON_POSITON,
  [EChartTypes.MixLineBar]: COMMON_POSITON,
  [EChartTypes.Bar]: COMMON_POSITON,
  [EChartTypes.LineBar]: COMMON_POSITON,
  [EChartTypes.LineBarTotal]: COMMON_POSITON,
  [EChartTypes.BarTotal]: COMMON_POSITON,
  [EChartTypes.Radar]: COMMON_POSITON,
  [EChartTypes.Treemap]: TREEMAP_POSITION_LABEL,
  [EChartTypes.Sunburst]: PIE_POSITION_LABEL,
  [EChartTypes.Pie]: PIE_POSITION_LABEL,
  [EChartTypes.Map]: COMMON_POSITON,
  [EChartTypes.BigNumber]: BIG_NUMBER_SIZE,
};
const POSITION_FACT = {
  [EChartTypes.BigNumber]: COMMON_POSITON,
};
const POSITION_LEGEND = {
  [EChartTypes.Line]: COMMON_POSITON,
  [EChartTypes.MixLineBar]: COMMON_POSITON,
  [EChartTypes.Bar]: COMMON_POSITON,
  [EChartTypes.LineBar]: COMMON_POSITON,
  [EChartTypes.LineBarTotal]: COMMON_POSITON,
  [EChartTypes.BarTotal]: COMMON_POSITON,
  [EChartTypes.Radar]: COMMON_POSITON,
  [EChartTypes.Treemap]: COMMON_POSITON,
  [EChartTypes.Sunburst]: COMMON_POSITON,
  [EChartTypes.Pie]: COMMON_POSITON,
  [EChartTypes.Map]: MAP_POSITION_LEGEND,
  [EChartTypes.BigNumber]: BIG_NUMBER_POSITION_LEGEND,
};

const ROTATE = {
  [EChartTypes.Line]: COMMON_ROTATE,
  [EChartTypes.MixLineBar]: COMMON_ROTATE,
  [EChartTypes.Bar]: COMMON_ROTATE,
  [EChartTypes.LineBar]: COMMON_ROTATE,
  [EChartTypes.LineBarTotal]: COMMON_ROTATE,
  [EChartTypes.BarTotal]: COMMON_ROTATE,
  [EChartTypes.Radar]: COMMON_ROTATE,
  [EChartTypes.Treemap]: COMMON_ROTATE,
  [EChartTypes.Sunburst]: [
    ...COMMON_ROTATE,
    {
      id: 'radial',
      name: 'От центра',
    },
  ],
  [EChartTypes.Pie]: [
    ...COMMON_ROTATE,
    {
      id: 'radial',
      name: 'От центра',
    },
  ],
  [EChartTypes.Map]: COMMON_ROTATE,
  [EChartTypes.BigNumber]: COMMON_ROTATE,
};

const FORMATTING = {
  [EChartTypes.Line]: COMMON_FORMATTING,
  [EChartTypes.MixLineBar]: COMMON_FORMATTING,
  [EChartTypes.Bar]: COMMON_FORMATTING,
  [EChartTypes.LineBar]: COMMON_FORMATTING,
  [EChartTypes.Map]: COMMON_FORMATTING,
  [EChartTypes.Radar]: COMMON_FORMATTING,
  [EChartTypes.Sunburst]: COMMON_FORMATTING,
  [EChartTypes.LineBarTotal]: COMMON_FORMATTING,
  [EChartTypes.BarTotal]: COMMON_FORMATTING,
  [EChartTypes.Pie]: COMMON_FORMATTING,
  [EChartTypes.Treemap]: COMMON_FORMATTING,
  [EChartTypes.BigNumber]: COMMON_FORMATTING,
};
const LABEL_TYPES = {
  [EChartTypes.Line]: COMMON_LABEL_TYPES,
  [EChartTypes.MixLineBar]: COMMON_LABEL_TYPES,
  [EChartTypes.Bar]: COMMON_LABEL_TYPES,
  [EChartTypes.LineBar]: COMMON_LABEL_TYPES,
  [EChartTypes.Map]: COMMON_LABEL_TYPES,
  [EChartTypes.Radar]: COMMON_LABEL_TYPES,
  [EChartTypes.LineBarTotal]: COMMON_LABEL_TYPES,
  [EChartTypes.BarTotal]: COMMON_LABEL_TYPES,
  [EChartTypes.Treemap]: COMMON_LABEL_TYPES,
  [EChartTypes.Sunburst]: COMMON_LABEL_TYPES,
  [EChartTypes.BigNumber]: COMMON_LABEL_TYPES,
  [EChartTypes.Pie]: [
    ...COMMON_LABEL_TYPES,
    {
      id: 'percent',
      name: 'Проценты',
    },
    {
      id: 'value-percent',
      name: 'Значение и проценты',
    },
    {
      id: 'category-value-percent',
      name: 'Категория, значение и процент',
    },
  ],
};

const COMMON_DATA_POSSIBLE_AMOUNT = {
  metrics: 5,
  series: 1,
};
const BAR_TOTAL_POSSIBLE_AMOUNT = {
  metrics: 6,
  series: 1,
};

const SUNBURST_DATA_POSSIBLE_AMOUNT = {
  metrics: 1,
  series: 2,
};

const TREEMAP_DATA_POSSIBLE_AMOUNT = {
  metrics: 1,
  series: 2,
};

const PIE_DATA_POSSIBLE_AMOUNT = {
  metrics: 1,
  series: 1,
};

const BIG_NUMBER_DATA_POSSIBLE_AMOUNT = {
  metrics: 2,
};

const DATA_SETTINGS_POSSIBLE_AMOUNT = {
  [EChartTypes.Line]: COMMON_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.MixLineBar]: COMMON_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.Bar]: BAR_TOTAL_POSSIBLE_AMOUNT,
  [EChartTypes.LineBar]: BAR_TOTAL_POSSIBLE_AMOUNT,
  [EChartTypes.Map]: COMMON_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.Radar]: COMMON_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.Sunburst]: SUNBURST_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.LineBarTotal]: BAR_TOTAL_POSSIBLE_AMOUNT,
  [EChartTypes.BarTotal]: COMMON_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.Pie]: PIE_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.Treemap]: TREEMAP_DATA_POSSIBLE_AMOUNT,
  [EChartTypes.BigNumber]: BIG_NUMBER_DATA_POSSIBLE_AMOUNT,
};

const zoomChartTypes = {
  [EChartTypes.Line]: EZoomTypes.Horizontal,
  [EChartTypes.Bar]: EZoomTypes.Horizontal,
  [EChartTypes.BarTotal]: EZoomTypes.Horizontal,
  [EChartTypes.LineBar]: EZoomTypes.Vertical,
  [EChartTypes.LineBarTotal]: EZoomTypes.Vertical,
  [EChartTypes.MixLineBar]: EZoomTypes.Horizontal,
};

export {
  POSITION_LABEL,
  POSITION_LEGEND,
  ROTATE,
  DESCRIPTION_TYPES,
  FORMATTING,
  LABEL_TYPES,
  DATA_SETTINGS_POSSIBLE_AMOUNT,
  POSITION_FACT,
  zoomChartTypes,
};
