import { EntityBaseApiService, getEntityApiBase } from '@/api/client';
import getConstants from '@bi-core/constants/env';
import { getVars as getEnvVars } from '@bi-core/constants/env/envVars';

export function install() {
  const constants = getConstants();
  const { isModeDevelopment } = constants;

  const envVars = getEnvVars();

  let hosts;
  try {
    hosts = JSON.parse(envVars.VITE_BI_BEE_HOSTS);
  } catch (error) {
    hosts = envVars.VITE_BI_BEE_HOSTS;
  }

  const apiSettings = JSON.parse(envVars.VITE_API_SETTINGS);

  EntityBaseApiService.registerGetEntityApiBase(entityName =>
    getEntityApiBase({
      apiSettings: { ...apiSettings, hosts },
      basePath: '/api',
      isRequestUrlPure: !isModeDevelopment,
      target: self.location.origin,
      entityName,
      usingProxy: isModeDevelopment,
    })
  );
  EntityBaseApiService.setAppBasePath(constants.appBasePath);
}
