<template>
  <div class="mkskom-bi">
    <!-- <ReloadPrompt /> -->
    <BIIconsSprite style="position: absolute; width: 0; height: 0" />
    <div class="mkskom-bi__content">
      <slot>
        <component :is="layout?.component" v-bind="layout?.props"></component>
      </slot>
      <GlobalLoader />
      <portal-target name="bi-modal" multiple></portal-target>
    </div>
  </div>
  <VueQueryDevtools />
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { useMeta } from 'quasar';
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { BIAnalyticPlugin } from '~packages/bi-analytic';

import { GlobalLoader } from '@bi-views/views/components/Loader';
// TODO появляется белый экран после обновления
// import ReloadPrompt from '@bi-views/views/components/ReloadPrompt';
import BIIconsSprite from '@clearview/icons/bi-bee-icons.svg?component';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

export default defineComponent({
  name: 'Main',
  components: {
    BIIconsSprite,
    GlobalLoader,
    VueQueryDevtools,
    // ReloadPrompt,
  },
  setup() {
    const route = useRoute();
    const layout = computed(() => {
      return route.meta?.layout;
    });

    const meta = BIAnalyticPlugin.meta();
    useMeta(() => ({ ...meta }));

    return { layout };
  },
});
</script>
