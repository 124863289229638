import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'ShareApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class ShareApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  create(entityName: string, id: string, options?: IRestOptions) {
    const uri = `/${entityName}/${id}`;
    return this.sendRequest(
      uri,
      null,
      { method: RequestMethod.POST },
      { ...options }
    );
  }

  getKey(entityName: string, id: string, options?: IRestOptions) {
    const uri = `/getKey/${entityName}/${id}`;
    return this.sendRequest(uri, null, {}, { ...options });
  }

  delete(entityName: string, id: string, options?: IRestOptions) {
    const uri = `/${entityName}/${id}`;
    return this.sendRequest(
      uri,
      null,
      { method: RequestMethod.DELETE },
      { ...options }
    );
  }
}
