export default function getApiPath(
  path: string,
  host: string,
  entityApiInfo: Record<string, Record<string, string> | string>,
  hostsProxyMap: Record<string, Record<string, string>>,
  usingProxy?: boolean
): string {
  const pathParams = encodeURI(
    new URL(path[0] === '/' ? path : '/' + path, host).search.slice(1)
  );
  const hasParams = pathParams.length > 0;
  const apiModuleData = entityApiInfo.module as Record<string, string>;

  let result =
    apiModuleData.path +
    (entityApiInfo.path as string) +
    (entityApiInfo.pathAdditional as string);

  if (hasParams) result += '?' + pathParams;

  if (!hostsProxyMap) return result;
  const hostProxySettings = hostsProxyMap[apiModuleData.hostName];
  if (!hostProxySettings) return result;

  if (hostProxySettings.always || usingProxy) {
    if (hostProxySettings.path) {
      result = hostProxySettings.path + result;
    }

    if (hostProxySettings.params) {
      result += (hasParams ? '&' : '?') + hostProxySettings.params;
    }
  }

  return result;
}
