import { Ref, computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import type { IRestOptions } from '@/api/client';
import { useDashboardWidgetStore, useShareStore } from '@/store/bi-store';
import { EWidgetType, TWidgetData } from '@/types';
import {
  CHART_DEFAULT_TEMPLATE as chartTemplate,
  TABLE_DEFAULT_TEMPLATE as tableTemplate,
} from '@bi-core/constants';
import { clone } from '@clearview/helpers';
import { UseQueryOptions, useQuery } from '@tanstack/vue-query';

import {
  getErrorQuery,
  getQueryMiddlewares,
  useQueryWrapper,
} from '../helpers';

export function useWidget(
  widgetId: Ref<string>,
  queryOpts: UseQueryOptions<
    TWidgetData,
    ErrorConstructor,
    TWidgetData,
    (string | Ref<string>)[]
  > = {},
  options: IRestOptions = {}
) {
  const route = useRoute();
  const type = route?.params.type;
  const shareStore = useShareStore();
  if (shareStore.sharedId) {
    options.sharedId = shareStore.sharedId;
  }
  const wd = useDashboardWidgetStore();

  const { alert, api } = getQueryMiddlewares('widget', 'fetch');
  if (!widgetId.value && !options.skipStoreFetch) {
    const widget =
      !wd.lastCreatedWidget &&
      clone(type === EWidgetType.Table ? tableTemplate : chartTemplate);
    return {
      data: ref(wd.lastCreatedWidget || widget),
      isFetched: ref(true),
    };
  }
  const fetchWidget = async (widgetId: string) => {
    const apiResponse = await api(widgetId, options);
    if (!apiResponse?.status) {
      throw getErrorQuery(
        'Не удалось загрузить виджет',
        apiResponse.errors,
        apiResponse.statusCode
      );
    }
    return apiResponse.response as TWidgetData;
  };

  const queryKey = ['widget', widgetId];
  const query = useQuery(queryKey, () => fetchWidget(widgetId.value), {
    enabled: computed(() => !!widgetId.value),
    ...queryOpts,
  });

  return useQueryWrapper(query, queryKey, alert);
}
