import { getVars, setVars } from './envVars';

export default (variables = {}) => {
  const vars = { ...getVars(), ...variables };

  const isModeDevelopment = vars.NODE_ENV !== 'production';
  const usingProxy = isModeDevelopment;
  const appBasePathSettings = vars.VITE_APP_BASE_PATH_SETTINGS || '/api';

  const setDynamicEnv = async (path: string) => {
    const env = await fetch(path)
      .then(res => res.json())
      .catch(err => console.error(err));
    setVars(env);
  };

  return {
    hostName: vars.VITE_HOSTNAME || 'bi-bee',
    appBasePath: vars.VITE_APP_BASE_PATH || '/',
    appDevServerPort: vars.VITE_APP_SERVER_PORT
      ? Number(vars.VITE_APP_SERVER_PORT)
      : 8080,
    isModeDevelopment,
    appBasePathSettings,
    getVars,
    setVars,
    setDynamicEnv,
    usingProxy,
    basePath: usingProxy ? appBasePathSettings : '',
    BASE_PATH: usingProxy ? appBasePathSettings : '',
  };
};
