import getApiModuleHost from '../helpers/__api-base/getApiModuleHost';
import getApiPath from '../helpers/__api-base/getApiPath';
import getEntityApiInfo, {
  IEntityApiPath,
} from '../helpers/__api-base/getEntityApiInfo';

/**
 *
 */
export default function getEntityApiBase({
  apiSettings,
  basePath,
  entityName,
  // hostName,
  isLogEnabled,
  isRequestUrlPure,
  target,
  usingProxy,
}: {
  apiSettings: Record<string, any>;
  basePath: string;
  entityName: string;
  // hostName: string;
  isLogEnabled?: boolean;
  isRequestUrlPure?: boolean;
  target: string;
  usingProxy: boolean;
}) {
  // console.group('getEntityApiBase');

  /*  */
  const { entitiesModulesMap, hosts, hostsProxyMap, modulesHostsMap } =
    apiSettings;

  /*  */
  const entityApiInfo = getEntityApiInfo(entityName, {
    entitiesModulesMap,
    hosts,
    modulesHostsMap,
  });
  const apiModuleData = entityApiInfo.module;

  const proxyPath = basePath + '/' + entityName;

  const _result: IEntityApiPath = {
    modulePath: isRequestUrlPure
      ? entityApiInfo.module.host + entityApiInfo.module.path
      : basePath,
    path: entityApiInfo.path,
    url: '',
  };

  /* Host */
  let host = getApiModuleHost({ apiModuleData, hostsProxyMap, usingProxy });
  if (host === undefined /*  || apiModuleData.hostName === hostName */) {
    host = '';
  }

  /* Path */
  const requestUrl =
    host +
    getApiPath(entityName, target, entityApiInfo, hostsProxyMap, usingProxy);

  /*  */
  isLogEnabled &&
    console.log('[getEntityApiBase] ' + proxyPath + ' => ' + requestUrl);

  const result = isRequestUrlPure ? requestUrl : proxyPath;
  _result.url = result;
  // console.log('requestUrl', requestUrl, 'proxyPath', proxyPath);
  // console.groupEnd('getEntityApiBase');
  return _result;
}
