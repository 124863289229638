import createSchemaFromDataModel from './createSchemaFromModel';
import processColumns from './processColumns';

export default function createModelInfo(model) {
  const modelColumns = processColumns(model.columns);
  const tableSchema = {
    columns: modelColumns,
    table_name: model.table_name,
  };
  /*  */
  const objectSchema = {
    id: model.id,
    name: model.name,
    modelId: model.model_id,
    ...createSchemaFromDataModel(tableSchema),
    showcaseInfo: tableSchema,
  };
  return objectSchema;
}
