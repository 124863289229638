import { EDashboardFilterComparison, EDataType } from '@/types';

export enum EValueTypes {
  Input = 'input',
  Select = 'select',
  Disabled = 'disabled',
  DatePicker = 'datePicker',
  Union = 'union',
}
export interface Comparison {
  comparison?: EDashboardFilterComparison;
  name?: string;
  value: EValueTypes;
  icon?: string;
  case?: EDataType[];
}
export const comparisonSettings: Comparison[] = [
  {
    comparison: EDashboardFilterComparison.Contains,
    name: 'Содержит',
    value: EValueTypes.Input,
    icon: 'contains',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotContains,
    name: 'Не содержит',
    value: EValueTypes.Input,
    icon: 'not-contains',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.StartWith,
    name: 'Начинается с',
    value: EValueTypes.Input,
    icon: 'begin-with',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotStartWith,
    name: 'Не начинается с',
    value: EValueTypes.Input,
    icon: 'not-begin-with',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.EndWith,
    name: 'Заканчивается на',
    value: EValueTypes.Input,
    icon: 'end-with',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotEndWith,
    name: 'Не заканчивается на',
    value: EValueTypes.Input,
    icon: 'not-end-with',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.Less,
    name: 'Меньше',
    value: EValueTypes.DatePicker,
    icon: 'less',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.LessThanEqual,
    name: 'Меньше или равно',
    value: EValueTypes.DatePicker,
    icon: 'less-or-equal',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Greater,
    name: 'Больше',
    value: EValueTypes.DatePicker,
    icon: 'greater',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.GreaterThanEqual,
    name: 'Больше или равно',
    value: EValueTypes.DatePicker,
    icon: 'greater-or-equal',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Less,
    name: 'Меньше',
    value: EValueTypes.Input,
    icon: 'less',
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.LessThanEqual,
    name: 'Меньше или равно',
    value: EValueTypes.Input,
    icon: 'less-or-equal',
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.Greater,
    name: 'Больше',
    value: EValueTypes.Input,
    icon: 'greater',
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.GreaterThanEqual,
    name: 'Больше или равно',
    value: EValueTypes.Input,
    icon: 'greater-or-equal',
    case: [EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.Eq,
    name: 'Равно',
    value: EValueTypes.Input,
    icon: 'equal',
    case: [EDataType.String, EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.NotEq,
    name: 'Не равно',
    value: EValueTypes.Input,
    icon: 'not-equal',
    case: [EDataType.String, EDataType.Numeric],
  },
  {
    comparison: EDashboardFilterComparison.DateEq,
    name: 'Равно',
    value: EValueTypes.DatePicker,
    icon: 'equal',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.DateNotEq,
    name: 'Не равно',
    value: EValueTypes.DatePicker,
    icon: 'not-equal',
    case: [EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Includes,
    name: 'В списке',
    value: EValueTypes.Select,
    icon: 'in-the-list',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.NotIncludes,
    name: 'Не в списке',
    value: EValueTypes.Select,
    icon: 'not-in-the-list',
    case: [EDataType.String],
  },
  {
    comparison: EDashboardFilterComparison.IsEmpty,
    name: 'Является пустым',
    value: EValueTypes.Disabled,
    icon: 'empty',
    case: [EDataType.String, EDataType.Numeric, EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.IsNotEmpty,
    name: 'Не является пустым',
    value: EValueTypes.Disabled,
    icon: 'not-empty',
    case: [EDataType.String, EDataType.Numeric, EDataType.Date],
  },
  {
    comparison: EDashboardFilterComparison.Union,
    name: 'Диапазон',
    value: EValueTypes.DatePicker,
    icon: 'range',
    case: [EDataType.Date, EDataType.Numeric],
  },
];
