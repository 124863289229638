import { Ref, computed } from 'vue';

import { IRestOptions } from '@/api/client';
import { useModelSchema, useWidget } from '@/store/queries';

export function useWidgetModelSchema(id: Ref<string>, options?: IRestOptions) {
  const {
    data: widgetQuery,
    isFetched: isFetchedWidget,
    ...optionsQueryWidget
  } = useWidget(
    id,
    {
      enabled: computed(() => !!id.value),
    },
    options
  );
  const modelId = computed(() => widgetQuery.value?.modelId);

  const {
    data: model,
    isFetched: isFetchedModel,
    ...optionsQueryModel
  } = useModelSchema(modelId, {
    enabled: computed(() => !!modelId.value),
  });

  return {
    isFetchedModel,
    isFetchedWidget,
    widgetQuery,
    model,
    modelId,
    optionsQueryWidget,
    optionsQueryModel,
  };
}
