import wrap from 'word-wrap';

export interface IEllipses {
  width: number;
  alias: string;
}

export interface IOptionsText {
  width: number;
  trim?: boolean;
  overflow?: 'breakAll' | 'break';
  ellipsis?: IEllipses;
  escape?: (text: string) => string;
}

export const getWrapText = (
  text: string,
  options: IOptionsText = {
    width: 20,
    trim: true,
    ellipsis: {
      width: 50,
      alias: '...',
    },
    overflow: 'break',
    escape(text): string {
      return text;
    },
  }
) => {
  let _wrapText = wrap(text, {
    ...options,
    cut: options.overflow === 'breakAll',
  });
  if (options.trim) {
    _wrapText = _wrapText.trim();
  }
  const ellipsis: IEllipses | undefined = options.ellipsis;
  if (ellipsis && ellipsis.width <= _wrapText.length) {
    _wrapText = _wrapText.substring(0, ellipsis.width) + ellipsis.alias;
  }
  return _wrapText;
};
