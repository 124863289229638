/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ShowcaseSourceDto = {
    id: string;
    name: string;
    type: ShowcaseSourceDto.type;
};

export namespace ShowcaseSourceDto {

    export enum type {
        CONNECTION = 'connection',
        SHOWCASE = 'showcase',
    }


}

