/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ColumnModelViewDto } from './ColumnModelViewDto';
import type { ModelJoin } from './ModelJoin';
import type { ViewSourceDataDto } from './ViewSourceDataDto';

export type DataProviderStructureViewDto = {
    columns: Array<ColumnModelViewDto>;
    viewType: DataProviderStructureViewDto.viewType;
    source?: ViewSourceDataDto;
    /**
     * @deprecated
     */
    orderBy?: Array<number>;
    /**
     * @deprecated
     */
    joins?: Array<ModelJoin>;
};

export namespace DataProviderStructureViewDto {

    export enum viewType {
        MATERIALIZED = 'MATERIALIZED',
        BASIC = 'BASIC',
    }


}

