import { ObjectRightsResponseDto } from '@/api/generated';

export interface Pageable {
  page: number;
  size: number;
  sort: Array<string>;
}

export type Right = ObjectRightsResponseDto;
export const RightType = ObjectRightsResponseDto.accessType;

export type DeleteParams = Omit<Right, 'accessType'>;
