import { Notify, QNotifyCreateOptions } from 'quasar';
import { error, info, log, warn } from '~packages/bi-logger';

export const enum EType {
  Success = 'positive',
  Info = 'info',
  Warning = 'warning',
  Error = 'negative',
}

export const iconType = {
  [EType.Success]: 'bi-bee bi-bee-double-check',
  [EType.Error]: 'bi-bee bi-bee-attention',
};

export type TALERT = {
  type: EType | string;
  message: string;
};

export const useAlerts = () => {
  return {
    setAlert: (
      newAlert: TALERT,
      options: QNotifyCreateOptions = {},
      toConsole = false
    ) => {
      Notify.create({
        type: newAlert.type,
        message: newAlert.message,
        ...options,
        // icon: iconType[newAlert.type],
      });

      if (toConsole) {
        consoleMessage(newAlert);
      }
    },
  };
};

function consoleMessage(newAlert: TALERT): void {
  switch (newAlert.type) {
    case EType.Success: {
      log(newAlert.message);
      break;
    }
    case EType.Info: {
      info(newAlert.message);
      break;
    }
    case EType.Warning: {
      warn(newAlert.message);
      break;
    }
    case EType.Error: {
      error(newAlert.message);
      break;
    }
  }
}
