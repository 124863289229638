import { useRoute } from 'vue-router';
import { warn } from '~packages/bi-logger';

import { useBreadcrumbs } from '@/composables';

import { CrumbStructure, DynamicCrumbStructure } from '../types';

export function useQuery() {
  const route = useRoute();
  const {
    getQueryKey,
    getLastHierarchy,
    saveFullHierarchy,
    findHierarchy,
    saveLastHierarchy,
    fetchHierarchy,
  } = useBreadcrumbs();

  const getDynamicCrumbsFromServer = async (
    crumbsStructure: CrumbStructure[]
  ) => {
    const emptyList = [];
    const needGetHierarchy = crumbsStructure.some(
      cs => !cs.info.isStatic && cs.info.entityType
    );
    if (!needGetHierarchy) return emptyList;

    const dynamicStructs = crumbsStructure.filter(
      c => !c.info.isStatic
    ) as DynamicCrumbStructure[];

    const lastItem = dynamicStructs[dynamicStructs.length - 1];
    const lastDynCrumbType = lastItem.info.entityType;
    const lastDynRouteParam = lastItem.info.paramName;
    const lastDynCrumbId = route.params[lastDynRouteParam] as string;
    if (!lastDynCrumbId) {
      warn(
        `не найден id крошки ${lastDynCrumbId} в параметрах роута:`,
        route.params
      );
      return emptyList;
    }

    let crumbsHierarchy = findHierarchy(lastDynCrumbType, lastDynCrumbId);

    if (!crumbsHierarchy) {
      crumbsHierarchy = await fetchHierarchy(lastDynCrumbId);
    }

    saveFullHierarchy(crumbsHierarchy);
    saveLastHierarchy(crumbsHierarchy);

    return crumbsHierarchy;
  };

  return {
    getQueryKey,
    findHierarchy,
    fetchHierarchy,
    getDynamicCrumbsFromServer,
    getLastHierarchy,
    saveFullHierarchy,
  };
}
