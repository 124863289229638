import cloneDeep from 'lodash.clonedeep';
import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { Ref, computed, ref } from 'vue';
import { allWidgetStructures } from '~packages/charts-new/chartTypes';

/*  eslint import/namespace: ['error', { allowComputed: true }] */
import * as jsxComponents from '@/bi-views/views/components/panelNew/_typeChart/WidgetSettings/FieldSettings/jsx';
import {
  WidgetField,
  WidgetInfo,
  WidgetSettingsFieldItem,
  WidgetStructure,
} from '@/types';

export const useWidgetInfoStore = defineStore('widgetInfo', () => {
  // Такая структура хранится на бэке, здесь хранятся значения чипсов и их настроек
  const widgetInfo: Ref<WidgetInfo> = ref({});
  const fieldItems: Ref<WidgetField[]> = computed(
    () => widgetInfo.value.widgetFields
  );

  const fieldSettingsNew = computed(() => widgetInfo.value.widgetFields);

  const widgetStructure: Ref<WidgetStructure> = computed(() =>
    cloneDeep(
      allWidgetStructures.find(
        widget => widget.id === widgetInfo.value.structureId
      )
    )
  );

  // Используемые колонки в полях
  const usedColumns = computed(() => {
    const arr: WidgetSettingsFieldItem[] = [];
    for (const field of fieldItems.value) {
      arr.push(field.items);
    }
    return arr.flat();
  });

  const selectedType = computed(() => widgetStructure.value?.type || '');

  const selectedId = computed(() => widgetStructure.value?.id || '');

  const selectedSubtype = computed(() => widgetStructure.value?.id || '');

  const structureFields = computed(
    () => cloneDeep(widgetStructure.value?.structureFields) || []
  );

  // Определяем подтипы выбранного типа виджета
  const currentWidgetSubtypes = computed(() => {
    const { lib, type } = widgetStructure.value;
    return allWidgetStructures.filter(
      setting => setting.lib === lib && setting.type === type
    );
  });

  const isSubtypesDisabled = computed(
    () => currentWidgetSubtypes.value.length === 1
  );

  // Устанавливаем выбранный тип/подтип виджета с сохранением чипсов (из structureFields), если их id совпадают в структуре виджета
  // TODO доделать смену типа т.к не подставляются дефолтные settings если другой компонент
  const setWidgetType = (type, e) => {
    // находим нужную дефолтную настройку
    const newWidget: WidgetStructure = cloneDeep(
      allWidgetStructures.find(setting => setting[type] === e)
    );
    const newFields = getNewFieldsAddition(
      widgetInfo.value.widgetFields,
      structureFields.value,
      newWidget?.structureFields
    );
    widgetInfo.value = {
      ...widgetInfo.value,
      name: newWidget?.name,
      structureId: newWidget.id,
      widgetFields: newFields,
    };
  };

  // получаем пересечение structureFields старых и новых и добавляем новые
  const getNewFieldsAddition = (
    oldFields,
    oldStructureFields,
    newStructureFields
  ) => {
    const result: WidgetField[] = [];

    for (const newStructureField of newStructureFields) {
      const { id, settings, type } = newStructureField;
      // ищем пересечения полей в старой и новой структуре
      const oldStructureField = oldStructureFields.find(
        f => f.id === newStructureField.id
      );
      // если пересечение есть, то мы проверяем, одинаковые ли компоненты используется для ключа настройки
      if (oldStructureField) {
        const oldStructureSettings = oldStructureField.settings;
        const newStructureSettings = newStructureField.settings;
        const field = {
          id,
          items: oldFields.find(f => f.id === id).items,
          settings: {},
          type,
        };
        for (const newSettingsKey of Object.keys(newStructureSettings)) {
          const componentName = newStructureSettings[newSettingsKey];
          // если компоненты одинаковые, то берем значения из старого варианта
          if (
            oldStructureSettings[newSettingsKey] ===
            newStructureSettings[newSettingsKey]
          ) {
            field.settings = {
              ...field.settings,
              [newSettingsKey]: oldFields.find(f => f.id === id).settings[
                newSettingsKey
              ],
            };
          }
          // если компоненты различаются, то подставляем дефолтные значения нового компонента
          else {
            field.settings = {
              ...field.settings,
              [newSettingsKey]: jsxComponents[componentName].value,
            };
          }
        }
        result.push(field);
      } else {
        const field = {
          id,
          items: [],
          settings: getDefaultSettingsValues(settings),
          type,
        };
        result.push(field);
      }
    }
    return result;
  };

  const updateWidgetInfoField = (fieldId, type, items) => {
    const widgetInfoField = widgetInfo.value.widgetFields.find(
      field => field.id === fieldId
    );
    if (!widgetInfoField) throw new Error('Не удалось обновить widgetInfo');
    widgetInfoField[type] = items;
  };

  // Срабатывает на дроп чипса
  const addItem = (fieldId, item, index) => {
    const fieldIndex = widgetInfo.value.widgetFields.findIndex(
      field => field.id === fieldId
    );
    const recomendedType = widgetStructure.value?.structureFields.find(
      el => el.id === fieldId
    )?.recommendedType;
    const items: WidgetSettingsFieldItem[] =
      widgetInfo.value.widgetFields[fieldIndex].items;
    const newItem = {
      ...item,
      type: recomendedType ?? item.type,
      chipsId: uuidv4(),
    };
    items.splice(index, 0, newItem);
  };

  // Срабатывает на удаление чипса
  const removeItem = (fieldId, item) => {
    const fieldIndex = widgetInfo.value.widgetFields.findIndex(
      field => field.id === fieldId
    );
    widgetInfo.value.widgetFields[fieldIndex].items =
      widgetInfo.value.widgetFields[fieldIndex].items.filter(
        i => i.chipsId !== item.chipsId
      );
  };

  // позволяет обновить конкретный итем по id поля
  const updateItem = (fieldId, item) => {
    const fieldIndex = fieldItems.value.findIndex(
      field => field.id === fieldId
    );
    const items: WidgetSettingsFieldItem[] =
      widgetInfo.value.widgetFields[fieldIndex].items;
    const itemIndex = items.findIndex(i => i.chipsId === item.chipsId);
    items[itemIndex] = { ...item };
  };

  // Переписать сразу все итемы (актуально для фильтров)
  const setItems = (fieldId, items) => {
    const fieldIndex = fieldItems.value.findIndex(
      field => field.id === fieldId
    );
    widgetInfo.value.widgetFields[fieldIndex].items = [...items];
  };

  // Получаем настройки чипса по id самого чипса
  const getSettingsByFieldId = fieldId => {
    return (
      fieldSettingsNew.value.find(setting => setting.id === fieldId)
        ?.settings || {}
    );
  };

  //  обновляем настроку по id чипса в fieldSettings и затем в widgetInfo
  const updateSettings = (fieldId, setting) => {
    updateWidgetInfoField(fieldId, 'settings', setting);
  };

  // вызывается при смене подключения в сайдбаре
  const changeConnection = modelId => {
    console.log('Смена витрины', modelId);
    widgetInfo.value.modelId = modelId;
  };

  // вызывается из виджет пикера
  // Получаем дефолтные настройки выбранного графика
  const pickNewWidget = widget => {
    const widgetStructure: WidgetStructure = allWidgetStructures.find(
      structure => structure.id === widget.structureId
    );
    const newWidget = {
      ...widget,
      widgetFields: [],
    };
    for (const structure of widgetStructure?.structureFields) {
      const { id, settings } = structure;
      const field = {
        id,
        items: [],
      };
      if (settings) field.settings = getDefaultSettingsValues(settings);
      newWidget.widgetFields.push(field);
    }
    widgetInfo.value = cloneDeep(newWidget);
  };

  // получить дефолтные настройки из используемого компонента в settings
  const getDefaultSettingsValues = settings => {
    const settingsValues = {};
    if (settings) {
      Object.keys(settings).forEach(el => {
        const element = settings[el];
        settingsValues[el] = jsxComponents[element].value;
      });
    }

    return settingsValues;
  };

  return {
    pickNewWidget,
    widgetInfo,
    changeConnection,
    updateSettings,
    getSettingsByFieldId,
    updateItem,
    removeItem,
    fieldItems,
    addItem,
    selectedId,
    selectedType,
    selectedSubtype,
    structureFields,
    setWidgetType,
    currentWidgetSubtypes,
    isSubtypesDisabled,
    widgetStructure,
    usedColumns,
    setItems,
  };
});
