/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdditionalFilter = {
    filterType?: AdditionalFilter.filterType;
    values?: Array<any>;
};

export namespace AdditionalFilter {

    export enum filterType {
        DASHBOARD = 'DASHBOARD',
        DYNAMIC = 'DYNAMIC',
        DYNAMIC_FORMULA = 'DYNAMIC_FORMULA',
    }


}

