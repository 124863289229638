import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const isLoggedIn = ref(false);
  const isPublicRoute = ref<boolean | null>(null);

  return {
    isLoggedIn,
    isPublicRoute,
  };
});
