import { getVars } from '@bi-core/constants/env/envVars';

import getApiMiddlewares from '../../__base/client/middlewares/getApiMiddlewares';
import { apiServices } from './../services';

const { middlewares, alerts } = getApiMiddlewares(
  JSON.parse(getVars()?.VITE_API_SETTINGS || '{}'),
  apiServices
);

export default middlewares;
export { alerts, middlewares };
