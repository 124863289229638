/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConvertedFormula = {
    formula: string;
    returnType?: ConvertedFormula.returnType;
    hasAggregateFunc?: boolean;
};

export namespace ConvertedFormula {

    export enum returnType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

