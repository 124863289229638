/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionFieldDto } from './ConditionFieldDto';

export type ConditionalFormatConditionDto = {
    field?: ConditionFieldDto;
    comparison?: ConditionalFormatConditionDto.comparison;
    value?: Array<any>;
};

export namespace ConditionalFormatConditionDto {

    export enum comparison {
        EQ = 'eq',
        NOT_EQ = 'notEq',
        GREATER = 'greater',
        GREATER_OR_EQ = 'greaterOrEq',
        SMALLER = 'smaller',
        SMALLER_OR_EQ = 'smallerOrEq',
        START_WITH = 'startWith',
        NOT_START_WITH = 'notStartWith',
        END_WITH = 'endWith',
        NOT_END_WITH = 'notEndWith',
        INCLUDES = 'includes',
        NOT_INCLUDES = 'notIncludes',
        BETWEEN = 'between',
        EXTENDED_BETWEEN = 'extendedBetween',
        CONTAINS = 'contains',
        NOT_CONTAINS = 'notContains',
        IS_EMPTY = 'isEmpty',
        IS_NOT_EMPTY = 'isNotEmpty',
        DATE_EQ = 'dateEq',
        DATE_NOT_EQ = 'dateNotEq',
    }


}

