import {
  BiBeeRestStandardApiService,
  RequestMethod,
} from '../../__base/client';

export default class UsersRightsApiService extends BiBeeRestStandardApiService {
  async fetch(id: string, route = '') {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this._sendRequest(
        this.pathFetch + (route ? `/${route}` : ''),
        { objectId: id },
        {},
        { dataOptions: { id }, method: RequestMethod.GET }
      );

      return apiServiceResponse.response;
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async delete(
    params: { objectId: string; userId: string; userSub: string },
    route = ''
  ) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this._sendRequest(
        this.pathDelete,
        params,
        { method: RequestMethod.DELETE },
        { method: RequestMethod.DELETE }
      );

      return apiServiceResponse.response;
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }
}
