import { createRouter, createWebHistory } from 'vue-router';

import { useLoading } from '@/composables';
import { auth, guest, shared } from '@/middlewares';
import { PiniaVuePlugin, pinia } from '@/plugins';
import { getVars } from '@bi-core/constants/env/envVars';

import { routes } from './bi-router';
import {
  applyRouterMiddlewares,
  createDefaultMiddlewares,
} from './middlewares';

const { setIsLoading } = useLoading();

const envVars = getVars();

createDefaultMiddlewares(routes, route => {
  if (!route.meta?.middlewares?.includes(guest)) {
    route.meta?.middlewares?.push(auth);
  }
  route.meta?.middlewares?.push(shared);
});

const router = createRouter({
  history: createWebHistory(envVars.BASE_URL),
  routes,
});

applyRouterMiddlewares(router);

router.afterEach(() => {
  setIsLoading(false);
});

export { routes, pinia, PiniaVuePlugin };
export default router;
