/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShowcaseColumnSourceDto } from './ShowcaseColumnSourceDto';

export type ShowcaseEntityColumnDto = {
    id?: string;
    index: number;
    name: string;
    source: ShowcaseColumnSourceDto;
    dataType: ShowcaseEntityColumnDto.dataType;
    nullable?: boolean;
    active?: boolean;
};

export namespace ShowcaseEntityColumnDto {

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        DATE = 'DATE',
    }


}

