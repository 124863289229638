import { EApiSourceType, EDataType } from '@/types';

// import { JdbcConnection, JdbcConnectionSetting } from '@/api/generated';

export const SOURCE__CONNECTION_DEFAULT_DATA = {
  [EApiSourceType.Clickhouse]: {
    port: '8123',
    connection: 'clickhouse',
  },
  [EApiSourceType.Postgres]: {
    port: '5432',
    connection: 'postgresql',
  },
};

export const SOURCE__DATA_TYPE = {
  [EDataType.Numeric]: {
    name: 'Число',
    icon: 'count',
    dataType: 'Факт',
  },
  [EDataType.String]: {
    name: 'Строка',
    icon: 'text',
    dataType: 'Аналитика',
  },
  [EDataType.Date]: {
    name: 'Дата',
    icon: 'calendar',
    dataType: 'Аналитика',
  },
  [EDataType.Boolean]: {
    name: 'Логический',
    icon: 'facts-boolean',
    dataType: 'Факт',
  },
};
