import {
  BarChart,
  LineChart,
  MapChart,
  PieChart,
  RadarChart,
  SunburstChart,
  TreemapChart,
} from 'echarts/charts';
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  MarkPointComponent,
  PolarComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import Chart from './charts';
import ChartsTypes from './chartsTypes';

echarts.use([
  DataZoomComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer,
  LegendComponent,
  ToolboxComponent,
  MarkLineComponent,
  TransformComponent,
  DatasetComponent,
  BarChart,
  LineChart,
  MapChart,
  PieChart,
  RadarChart,
  TreemapChart,
  SunburstChart,
  PolarComponent,
  MarkPointComponent,
  LabelLayout,
  UniversalTransition,
]);

export * from './constants';
export * from './helpers';

export { Chart, ChartsTypes, echarts };
