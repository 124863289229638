export function getDigits(digits?: number | null): string {
  let _digits = '';
  if (digits === 1) {
    _digits += '';
  } else if (digits === 1000) {
    _digits += '(a|k)';
  } else if (digits === 1000000) {
    _digits += '(a|m)';
  } else if (digits === 1000000000) {
    _digits += '(a|b)';
  } else if (digits === 1000000000000) {
    _digits += '(a|t)';
  }
  return _digits;
}
