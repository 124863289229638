import {
  EDashboardElementsType,
  EDashboardItemsType,
  EDataType,
  IDraggbleOptions,
  IResizeOptions,
} from '@/types';
import { ConfirmFormButton } from '@bi-views/views/components/ConfirmForm';

export const defaultPage = {
  name: 'Страница 1',
  colsCount: 26,
  rowsHeight: 38,
  isVisible: true,
};

export const dialogDeleteButtons: ConfirmFormButton[] = [
  {
    name: 'Отменить',
    id: 'cancel',
    view: 'outlined',
  },
  {
    name: 'Удалить',
    id: 'delete',
    view: 'filled',
  },
];

export const resizeOptions: IResizeOptions = {
  autoScroll: {
    container: null,
    margin: 50,
    distance: 5,
    interval: 10,
    speed: 400,
  },
};

export const dragOptions: IDraggbleOptions = {
  autoScroll: {
    container: null,
    margin: 50,
    distance: 5,
    interval: 10,
    speed: 400,
  },
};

export const iconByType = {
  [EDataType.String]: 'text',
  [EDataType.Numeric]: 'sigma',
  [EDataType.Date]: 'calendar',
  [EDataType.Boolean]: 'facts-boolean',
};

export const delays = {
  INPUT_SAVE_HISTORY: 800,
};

export const fabButtons = [
  {
    id: EDashboardItemsType.GROUP,
    label: 'Группа виджетов',
    icon: 'group',
  },
  {
    id: EDashboardElementsType.Button,
    label: 'Виджет-кнопка',
    icon: 'object-button',
  },
  {
    id: EDashboardElementsType.Text,
    label: 'Текст',
    icon: 'text',
  },
  {
    id: EDashboardElementsType.Image,
    label: 'Изображение',
    icon: 'image',
  },
  {
    id: EDashboardElementsType.Filter,
    label: 'Виджет-фильтр',
    icon: 'filter-widget',
  },
  {
    id: EDashboardItemsType.WIDGET,
    label: 'Виджет',
    icon: 'widgets',
  },
];

export const dialogSaveButtons: ConfirmFormButton[] = [
  {
    name: 'Не сохранять',
    id: 'cancel',
    view: 'outlined',
  },
  {
    name: 'Сохранить',
    id: 'accept',
    view: 'filled',
  },
];

export const dialogCreateShowcaseButtons: ConfirmFormButton[] = [
  { view: 'outlined', name: 'Позже', id: 'cancel' },
  { view: 'filled', name: 'Создать витрину', id: 'accept' },
];

export const dataTests = {
  CONTENT: 'dashboard__content',
};

export { comparisonSettings } from './comparisonSettings';
