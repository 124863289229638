import Keycloak, { KeycloakConfig, KeycloakLogoutOptions } from 'keycloak-js';
import { App, getCurrentInstance, inject, markRaw, version } from 'vue';
import { error } from '~packages/bi-logger';

import { PROVIDE__KEYCLOAK } from './constants';
import { SettingsType, UpdateTokenInfo } from './types';

export class KeycloakPlugin {
  instance: Keycloak | null = null;
  createOpts: KeycloakConfig = {};
  initOpts: SettingsType = {};
  defaults: Record<string, number> = {
    interval: 6000,
    threshold: 70,
  };

  constructor() {
    this.create = this.create.bind(this);
    this.init = this.init.bind(this);
    this.updateToken = this.updateToken.bind(this);
    this.logout = this.logout.bind(this);
  }

  create() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const plugin = markRaw({
      install(
        app: App,
        initOptions: KeycloakConfig,
        options: SettingsType = {}
      ) {
        Object.assign(self.createOpts, initOptions);

        self.initOpts.interval = options.interval;
        self.initOpts.threshold = options.threshold || self.defaults.threshold;
        self.initOpts.needToReloadIfNotAuth = options.needToReloadIfNotAuth;
        self.initOpts.needToUpdateTokenOnFocus =
          options.needToUpdateTokenOnFocus;
        self.initOpts.preventClearHashAfterLogin =
          options.preventClearHashAfterLogin || true;

        const keycloak = Keycloak(initOptions);

        self.setInstance(keycloak);
        if (version[0] === '3') {
          app?.provide(PROVIDE__KEYCLOAK, keycloak);
          app.config.globalProperties.$keycloak = keycloak;
        } else {
          app.prototype.$keycloak = keycloak;
        }
      },
    });

    return plugin;
  }

  init(redirectUri?: string) {
    return new Promise((resolve, reject) => {
      const keycloak = this.getInstance();

      if (!keycloak) {
        console.warn('keycloak not created');
        return;
      }

      let redirect: string | undefined;
      if (redirectUri) {
        redirect = window.location.origin + redirectUri;
      }

      keycloak
        .init({
          onLoad: this.createOpts?.onLoad,
          redirectUri: redirect,
        })
        .then(auth => {
          if (!auth) {
            if (this.initOpts.needToReloadIfNotAuth)
              return window.location.reload();
          }
          if (this.initOpts.needToUpdateTokenOnFocus) {
            window.onfocus = this.updateToken;
          }

          /* Set timeout */
          if (this.initOpts.interval) {
            setInterval(
              () => this.updateToken,
              typeof this.initOpts.interval === 'boolean'
                ? this.defaults.interval
                : this.initOpts.interval
            );
          }

          resolve();
        })
        .catch(e => {
          if (e instanceof Error) {
            error('Auth. Failed!', e.message, keycloak);
          }
          reject(e);
        });
    });
  }

  setInstance(instance: Keycloak) {
    this.instance = instance;
  }

  getInstance() {
    return (
      (getCurrentInstance() && inject<Keycloak>(PROVIDE__KEYCLOAK)) ||
      this.instance
    );
  }

  /**
   * d
   * @param {any} keycloak
   * @param {number} threshold
   * @return { isTokenRefreshed, token }
   */
  async updateToken(
    threshold = this.initOpts.threshold
  ): Promise<UpdateTokenInfo | void> {
    let isTokenRefreshed = false;
    const keycloak = this.getInstance();

    if (!keycloak) {
      console.warn('keycloak not created');
      return;
    }

    if (this.initOpts.preventClearHashAfterLogin) {
      const href = location.href
        .replace(/[&\\?#]code=[^&\\$]*/, '')
        .replace(/[&\\?#]scope=[^&\\$]*/, '')
        .replace(/[&\\?#]state=[^&\\$]*/, '')
        .replace(/[&\\?#]session_state=[^&\\$]*/, '');
      history.replaceState(null, window.name, href);
    }

    try {
      isTokenRefreshed = await keycloak.updateToken(threshold);
    } catch (error: any) {
      // `error` can be undefined. Thanks to `keycloak-js`
      // change warn on log. Unimportant error
      error('updateToken ERROR! Adapter message:', error?.message || error);
    }

    return { isTokenRefreshed, token: keycloak.token };
  }

  async logout(options?: KeycloakLogoutOptions): Promise<void> {
    const keycloak = this.getInstance();

    if (!keycloak) {
      console.warn('keycloak not created');
      return;
    }
    return keycloak.logout(options);
  }
}

const keycloakPlugin = new KeycloakPlugin();

export const createKeycloak = keycloakPlugin.create;
export const initKeycloak = keycloakPlugin.init;
export const updateToken = keycloakPlugin.updateToken;
export const logout = keycloakPlugin.logout;
