import { defineStore } from 'pinia';
import { reactive } from 'vue';

import { Alert } from '@/api/__base/client/middlewares/Alerts';

export const useAlertsStore = defineStore('alert', () => {
  const alerts = reactive<Record<string, Alert | undefined>>({});

  return {
    alerts,
  };
});
