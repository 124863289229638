import { z } from 'zod';

const baseMess = 'Нельзя сохранить виджет.';
const emptyPageNameMess = `${baseMess} Название не должно быть пустым`;

const emptyNameWidgetSchema = z.string().trim().min(1, {
  message: emptyPageNameMess,
});

const saveWidgetSchema = z.object({
  name: emptyNameWidgetSchema,
});

export { saveWidgetSchema, emptyNameWidgetSchema };
