import { IEntityBaseApiServiceParams } from '@/api/client';
import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
} from '../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'DataSourceElementApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class DataSourceElementApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  fetch<TResponse>(id: string, options?: IRestOptions) {
    return super.fetch<TResponse>(id, options);
  }
}
