import DefaultAxisScale from './DefaultAxisScale';
import DefaultAxisTitleComponent, {
  defaultValue as defaulAxisTitleValue,
} from './DefaultAxisTitle';
import DefaultAxisType from './DefaultAxisType';
import axisGrid from './axisGrid.jsx';

const DefaultAxisTitle = {
  component: DefaultAxisTitleComponent,
  value: defaulAxisTitleValue,
};

export {
  // DefaultAxisScale,
  DefaultAxisTitle,
  // DefaultAxisType,
  // axisGrid
};
