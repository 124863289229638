/**
 * fetch current loading state
 */
import { error } from '~packages/bi-logger';

const varName = 'isGlobalLoading';

function fetchGlobalLoadingState() {
  const currentState = sessionStorage.getItem(varName);
  if (currentState === null) return false;
  return JSON.parse(currentState);
}

function setLoadingState(state: boolean): void {
  const settingState = state ? JSON.stringify(state) : 'false';
  sessionStorage.setItem(varName, settingState);
}

/**
 *
 * @return {
 * withLoading() : toggle global loading state depending on callback - duration
 * setIsLoading() : toggle global loading state
 * getLoadingState : get current loading state
 * }
 * @usage: const { setIsLoading, getLoadingState, withLoading } = useLoading()
 * !:: can be used anywhere cause state is being handled in the current Session storage
 */

export const useLoading = () => {
  return {
    setIsLoading: (state: boolean, delay = 0) => {
      if (delay) {
        setLoadingState(true);
        setTimeout(() => {
          setLoadingState(false);
        }, delay);
      } else {
        setLoadingState(state);
      }
    },
    getLoadingState: () => {
      const isLoading: boolean = fetchGlobalLoadingState();
      return isLoading;
    },

    withLoading: async callBack => {
      try {
        setLoadingState(true);
        await callBack();
        setLoadingState(false);
      } catch (_error) {
        setLoadingState(false);
        error(_error);
      }
    },
  };
};
