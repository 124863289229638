import { EChartEditorType } from '@/types';

// TODO работает только с одной вложенностью
const validSchema = [
  { constant: 'LABEL_TYPES', field: 'label.type' },
  {
    constant: 'FORMATTING',
    field: 'label.formatting',
  },
  {
    constant: 'POSITION_LABEL',
    field: 'label.position',
  },
  {
    constant: 'POSITION_LEGEND',
    field: 'legend.position',
  },
  {
    constant: 'ROTATE',
    field: 'label.rotate',
  },
];

const validEditor = [
  {
    editorType: EChartEditorType.Xaxis,
    optionName: 'xAxis',
  },
  {
    editorType: EChartEditorType.Normalize,
    optionName: 'normalize',
  },
  {
    editorType: EChartEditorType.Yaxis,
    optionName: 'yAxis',
  },
  {
    editorType: EChartEditorType.ColorScheme,
    optionName: 'theme',
  },
  {
    editorType: EChartEditorType.DropSettings,
    optionName: 'dropSettings',
  },
  {
    editorType: EChartEditorType.Legend,
    optionName: 'legend',
  },
  {
    editorType: EChartEditorType.LimitValues,
    optionName: 'limitValues',
  },
  {
    editorType: EChartEditorType.Ring,
    optionName: 'ring',
  },
  {
    editorType: EChartEditorType.Label,
    optionName: 'label',
  },
  {
    editorType: EChartEditorType.AreaStyle,
    optionName: 'areaStyle',
  },
];

export { validSchema, validEditor };
