/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataProviderStructureDictionaryDto } from './DataProviderStructureDictionaryDto';

export type ModelDictionaryResponseDto = {
    id: string;
    /**
     * Model name
     */
    name: string;
    /**
     * Model type
     */
    type: ModelDictionaryResponseDto.type;
    /**
     * Project id
     */
    projectId: string;
    creationTime: string;
    ownerName?: string;
    structure?: DataProviderStructureDictionaryDto;
    editable?: boolean;
};

export namespace ModelDictionaryResponseDto {

    /**
     * Model type
     */
    export enum type {
        SHOWCASE = 'SHOWCASE',
        DICTIONARY = 'DICTIONARY',
        VIEW = 'VIEW',
        KAFKA = 'KAFKA',
        POSTGRES = 'POSTGRES',
        CLICKHOUSE = 'CLICKHOUSE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
    }


}

