export const enum ETypes {
  Image = 'image',
}

export const DESCRIPTION_TYPES = [
  {
    id: ETypes.Image,
    alias: 'Картинка',
    name: 'Картинка',
  },
];
