/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionResponse = {
    id: string;
    name: string;
    connectionType: ConnectionResponse.connectionType;
    projectId: string;
    dataSourceIds: Array<string>;
    dataSourceElementsIds: Array<string>;
    ownerName: string;
    description?: string;
    creationTime: string;
    modificationTime?: string;
};

export namespace ConnectionResponse {

    export enum connectionType {
        XLSX = 'XLSX',
        CSV = 'CSV',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

