import { EType, TALERT, useAlerts } from '@/composables';
import { identity, kebabize } from '@clearview/helpers';

import getEntityMethodMessage, { EMessageType } from './getEntityMethodMessage';

const { setAlert } = useAlerts();

interface IMethodNameAlertMap {
  [key: string]: { hasAlertSuccess: boolean; hasAlertError: boolean };
}

export class Alert {
  private entityId: string;
  private entityMessages;
  private defaultMessages;
  private entitiesNamesDeclensions;
  private methodName: string;
  private hasAlertSuccess: boolean;
  private hasAlertError: boolean;
  private methodNameAlertMap: IMethodNameAlertMap;

  public needToStop: boolean;
  public needToAlert: boolean;

  constructor(
    entityId: string,
    entityMessages: Record<string, unknown>,
    defaultMessages,
    entitiesNamesDeclensions,
    methodName: string,
    hasAlertSuccess = false,
    hasAlertError = true,
    methodNameAlertMap = {}
  ) {
    this.entityId = entityId;
    this.entityMessages = entityMessages;
    this.defaultMessages = defaultMessages;
    this.entitiesNamesDeclensions = entitiesNamesDeclensions;
    this.methodName = methodName;
    this.hasAlertSuccess = hasAlertSuccess;
    this.hasAlertError = hasAlertError;
    this.methodNameAlertMap = methodNameAlertMap;

    this.needToAlert = false;
    this.needToStop = false;
  }

  public getMessages(status: boolean, errors: Array<{ message: string }> = []) {
    let messages: Array<TALERT> = [];
    let messageType: EMessageType;
    let alertType: string;

    if (status) {
      messageType = EMessageType.Success;
      alertType = EType.Success;
      this.needToAlert =
        this.methodNameAlertMap[this.methodName]?.hasAlertSuccess ||
        this.hasAlertSuccess;
    } else {
      messageType = EMessageType.Error;
      alertType = EType.Error;
      this.needToAlert =
        this.methodNameAlertMap[this.methodName]?.hasAlertError ||
        this.hasAlertError;
      this.needToStop = true;
    }

    if (this.needToAlert) {
      if (errors.length) {
        messages = errors
          .map(e => e.message)
          .filter(identity)
          .map(error => ({ message: error, type: alertType }));
      } else {
        messages = [
          {
            message: getEntityMethodMessage(
              this.entityMessages,
              this.methodName,
              messageType,
              this.defaultMessages,
              this.entitiesNamesDeclensions[this.entityId]
            ),
            type: alertType,
          },
        ];
      }
    }
    return messages;
  }

  public show(status: boolean, errors: Array<{ message: string }> = []) {
    const messages = this.getMessages(status, errors);
    if (this.needToAlert) {
      messages.forEach(message => setAlert(message));
    }
  }
}

export class Alerts {
  private apiSettings;
  private entitiesNamesDeclensions;
  private entitiesMessages;

  constructor(apiSettings) {
    this.apiSettings = apiSettings;
    /* Extract entities info for messaging */
    this.entitiesNamesDeclensions = apiSettings.entitiesNamesDeclensions || {};
    this.entitiesMessages = apiSettings.entitiesMessages || {};
  }

  public getAlert(
    entityId: string,
    methodName: string,
    methodNameAlertMap?: IMethodNameAlertMap,
    hasAlertSuccess?: boolean,
    hasAlertError?: boolean
  ): Alert {
    /* Extract messages info */
    const entityIdKebabized = kebabize(entityId);
    return new Alert(
      entityIdKebabized,
      this.entitiesMessages[entityIdKebabized] || {},
      this.entitiesMessages._,
      this.entitiesNamesDeclensions || {},
      methodName,
      hasAlertSuccess,
      hasAlertError,
      methodNameAlertMap
    );
  }
}
