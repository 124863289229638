import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useShareStore = defineStore('share', () => {
  const sharedId = ref<string | number | null>(null);
  const isSharedPage = ref(false);

  return {
    sharedId,
    isSharedPage,
  };
});
