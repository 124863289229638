import cloneDeep from 'lodash.clonedeep';
import { defineStore } from 'pinia';
import { Ref, computed, ref } from 'vue';

import { useWidgetModelSchema } from '@/composables';
import { EChartTypes, EWidgetType } from '@/types';

export const useWidgetConstructorStore = defineStore(
  'widgetConstructor',
  () => {
    const widgetConstructor = ref({});
    const getWidgetConstructor = (id: Ref<string>) => {
      const { isFetchedModel, isFetchedWidget, model, widgetQuery, modelId } =
        useWidgetModelSchema(id);
      setWidget(cloneDeep(widgetQuery.value));
      return { isFetchedModel, isFetchedWidget, widgetQuery, model, modelId };
    };
    const setWidget = widget => {
      if (widget.type === EWidgetType.Chart) {
        // Поддержка для отображения серий (метрик) при переключении между типами графиков
        widget.uiOptions.metrics = widget.uiOptions.metrics.map(metric => {
          if (
            widget.subtype === EChartTypes.LineBarTotal ||
            widget.subtype === EChartTypes.LineBar ||
            widget.subtype === EChartTypes.BarTotal
          )
            return { ...metric, chartType: EChartTypes.Bar };
          if (widget.subtype === EChartTypes.MixLineBar) {
            return metric.chartType === EChartTypes.Bar ||
              metric.chartType === EChartTypes.Line
              ? metric
              : { ...metric, chartType: EChartTypes.Line };
          }
          return { ...metric, chartType: widget.subtype };
        });
      }
      widgetConstructor.value = widget;
    };
    const widgetTypes = computed(() => ({
      type: widgetConstructor.value.type,
      subtype: widgetConstructor.value.subtype,
    }));
    const widgetConstructorUiOptions = computed(
      () => widgetConstructor.value?.uiOptions
    );
    const setWidgetTypes = types => {
      widgetConstructor.value.type = types.type;
      widgetConstructor.value.uiOptions.type = types.type;
      widgetConstructor.value.subtype = types.subtype;
      widgetConstructor.value.uiOptions.subtype = types.subtype;
    };
    const setWidgetOption = (type, option) => {
      widgetConstructor.value.uiOptions[type] = option;
    };
    const setWidgetOptionSortOrder = order => {
      const sort = widgetConstructor.value.uiOptions.sort;
      if (sort.field) {
        sort.order = order === 'ASC' ? 'DESC' : 'ASC';
      }
    };
    const setWidgetOptionSortXFieldOrder = order => {
      const sort = widgetConstructor.value.uiOptions.sortX;
      if (sort.field) {
        sort.order = order === 'ASC' ? 'DESC' : 'ASC';
      }
    };
    return {
      widgetTypes,
      widgetConstructor,
      widgetConstructorUiOptions,
      setWidgetTypes,
      setWidget,
      getWidgetConstructor,
      setWidgetOptionSortOrder,
      setWidgetOptionSortXFieldOrder,
      setWidgetOption,
    };
  }
);
