import { EChartEditorType, EChartTypes, EWidgetType } from '@/types';

export const CHART_MAX_ROUNDING_VALUE = 6;

export const CHART_DIGITS = [
  {
    id: 1,
    name: 'Единицы',
  },
  {
    id: 1000,
    name: 'Тысячи',
  },
  {
    id: 1000000,
    name: 'Миллионы',
  },
  {
    id: 1000000000,
    name: 'Миллиарды',
  },
];

const CHART_DEFAULT_LIMIT_VALUES = {
  show: false,
  maxValue: {
    value: null,
    name: 'Максимальное значение',
  },
  minValue: {
    value: null,
    name: 'Минимальное значение',
  },
};

const CHART_DEFAULT_LABEL = {
  show: true,
  position: 'top',
  rotate: '0',
  formatting: 'space',
  digits: 1,
  rounding: null,
  type: 'value',
};

const CHART_DEFAULT_LEGEND = { show: false, position: 'bottom' };

const CHART_DEFAULT_COLOR_SCHEME = {
  theme: 'default',
  shouldReverseColors: false,
};

export const CHART_DEFAULT_DATA = {
  metrics: [],
  series: [],
  filters: [],
  sort: {},
  sortX: {},
  limit: null,
};

export const CHART_VISUAL_SETTINGS = {
  [EChartTypes.Line]: {
    icon: {
      name: 'template-chart-line',
    },
  },
  [EChartTypes.Pie]: {
    icon: {
      name: 'template-chart-pie',
    },
  },
  [EChartTypes.Bar]: {
    icon: {
      name: 'template-chart-bar',
    },
  },

  [EChartTypes.BarTotal]: {
    icon: {
      name: 'template-chart-bar-total',
    },
  },
  [EChartTypes.LineBar]: {
    icon: {
      name: 'template-chart-line-bar',
    },
  },
  [EChartTypes.LineBarTotal]: {
    icon: {
      name: 'template-chart-line-bar-total',
    },
  },
  [EChartTypes.Radar]: {
    icon: {
      name: 'template-chart-radar',
    },
  },
  [EChartTypes.Sunburst]: {
    icon: {
      name: 'template-chart-sunburst',
    },
  },
  [EChartTypes.Treemap]: {
    icon: {
      name: 'template-chart-treemap',
    },
  },
  [EChartTypes.MixLineBar]: {
    icon: {
      name: 'template-chart-mix-line-bar',
    },
  },
  [EChartTypes.Map]: {
    icon: {
      name: 'template-chart-russia-map',
    },
  },
  [EChartTypes.BigNumber]: {
    icon: {
      name: 'template-chart-big-number',
    },
  },
};

export const CHART_DEFAULT_SETTINGS = {
  [EChartTypes.Line]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Line,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      dataZoom: false,
      nameTextStyle: {
        align: 'right',
      },
      nameLocation: 'end',
    },
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    areaStyle: null,
    isAutoName: true,
  },
  [EChartTypes.Pie]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Pie,
    ring: {
      show: false,
      radius: {
        inner: 0,
        outer: 90,
      },
    },
    label: {
      show: true,
      position: 'outside',
      rotate: '0',
      formatting: 'space',
      digits: 1,
      rounding: null,
      type: 'value',
    },
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.Bar]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Bar,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      nameTextStyle: {
        align: 'right',
      },
      dataZoom: false,
      nameLocation: 'end',
    },
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.BarTotal]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.BarTotal,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      nameTextStyle: {
        align: 'right',
      },
      dataZoom: false,
      nameLocation: 'end',
    },
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
    normalize: false,
  },
  [EChartTypes.LineBar]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.LineBar,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      nameTextStyle: {
        align: 'right',
      },
      nameLocation: 'end',
      dataZoom: false,
    },
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
  },
  [EChartTypes.LineBarTotal]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.LineBarTotal,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      nameTextStyle: {
        align: 'right',
      },
      nameLocation: 'end',
      dataZoom: false,
    },
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    normalize: false,
  },
  [EChartTypes.Radar]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Radar,
    radar: {
      shape: 'polygon',
      axisName: {
        show: true,
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
    },
    label: CHART_DEFAULT_LABEL,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.Treemap]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Treemap,
    upperLabel: {
      show: true,
      position: 'insideLeft',
      rotate: '0',
      formatting: 'space',
      digits: 1,
      rounding: null,
      distance: 0,
      type: 'category',
    },
    label: {
      show: true,
      position: 'insideTop',
      rotate: '0',
      formatting: 'space',
      digits: 1,
      rounding: null,
      type: 'value',
    },
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.Sunburst]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Sunburst,
    ring: {
      show: false,
      radius: {
        inner: 0,
        outer: 90,
      },
    },
    label: {
      show: true,
      position: 'inside',
      rotate: 'radial',
      formatting: 'space',
      digits: 1,
      rounding: null,
      type: 'category',
    },
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.Text]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Text,
    label: CHART_DEFAULT_LABEL,
    ...CHART_DEFAULT_DATA,
  },
  [EChartTypes.BigNumber]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.BigNumber,
    label: {
      fontSize: '48',
      position: 'left',
      digits: 1,
      rounding: null,
    },
    fact: {
      show: true,
      fontSize: '12',
      fontWeight: null,
      fontStyle: null,
    },
    legend: { show: false, position: 'right', title: '' },
    metrics: [],
    filters: [],
  },
  [EChartTypes.Map]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.Map,
    mapRange: 10,
    legend: { show: false, position: 'top-right' },
    label: CHART_DEFAULT_LABEL,
    mappingType: 'title',
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
  [EChartTypes.MixLineBar]: {
    name: 'Новый виджет',
    type: EWidgetType.Chart,
    subtype: EChartTypes.MixLineBar,
    xAxis: {
      type: 'category',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      dataZoom: false,
    },
    yAxis: {
      type: 'value',
      show: true,
      axisLabel: {
        fontSize: '12',
        rotate: '0',
        fontWeight: null,
        fontStyle: null,
      },
      name: {
        show: false,
        text: '',
      },
      nameTextStyle: {
        align: 'right',
      },
      nameLocation: 'end',
      dataZoom: false,
    },
    areaStyle: null,
    label: CHART_DEFAULT_LABEL,
    limitValues: CHART_DEFAULT_LIMIT_VALUES,
    legend: CHART_DEFAULT_LEGEND,
    ...CHART_DEFAULT_COLOR_SCHEME,
    ...CHART_DEFAULT_DATA,
    isAutoName: true,
  },
};

export const CHART_EDITORS = {
  [EChartTypes.Line]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.AreaStyle,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.MixLineBar]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.AreaStyle,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Pie]: [
    EChartEditorType.Label,
    EChartEditorType.PieValues,
    EChartEditorType.Legend,
    EChartEditorType.Ring,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Bar]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.LineBar]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.LineBarTotal]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.Normalize,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.BarTotal]: [
    EChartEditorType.Yaxis,
    EChartEditorType.Xaxis,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.LimitValues,
    EChartEditorType.Normalize,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Radar]: [
    EChartEditorType.AxisRadar,
    EChartEditorType.Label,
    EChartEditorType.Legend,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Treemap]: [
    EChartEditorType.TreemapUpperLabel,
    EChartEditorType.Label,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Sunburst]: [
    EChartEditorType.Label,
    EChartEditorType.PieValues,
    EChartEditorType.ColorScheme,
    EChartEditorType.Ring,
    EChartEditorType.DropSettings,
    EChartEditorType.Legend,
  ],
  [EChartTypes.Text]: [],
  [EChartTypes.BigNumber]: [
    EChartEditorType.Label,
    EChartEditorType.Fact,
    EChartEditorType.Legend,
    EChartEditorType.ConditionalFormatting,
    EChartEditorType.DropSettings,
  ],
  [EChartTypes.Map]: [
    EChartEditorType.Legend,
    EChartEditorType.SeriesMapping,
    EChartEditorType.ValueRange,
    EChartEditorType.ColorScheme,
    EChartEditorType.DropSettings,
  ],
  // для фильтра
  base: [EChartEditorType.Placeholder],
};

export const CHART_ALL_INPUTS_TYPES = [
  EChartEditorType.Metrics,
  EChartEditorType.Series,
  EChartEditorType.Sort,
];

export const CHART_SERIES_METRICS_TYPES = [
  EChartEditorType.Metrics,
  EChartEditorType.Series,
];

export const CHART_DEFAULT_TEMPLATE = {
  name: 'Новый виджет',
  type: EWidgetType.Chart,
  subtype: EChartTypes.Line,
  uiOptions: CHART_DEFAULT_SETTINGS[EChartTypes.Line],
};
