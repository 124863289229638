/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SortColumnSource } from './SortColumnSource';

export type SortColumn = {
    source?: SortColumnSource;
    aggregationType?: SortColumn.aggregationType;
    order?: SortColumn.order;
    /**
     * @deprecated
     */
    id?: string;
    /**
     * @deprecated
     */
    sourceAttribute?: string;
    /**
     * @deprecated
     */
    name?: string;
};

export namespace SortColumn {

    export enum aggregationType {
        SUM = 'sum',
        AVG = 'avg',
        COUNT = 'count',
        UNIQ = 'uniq',
        MIN = 'min',
        MAX = 'max',
    }

    export enum order {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}

