import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useDataTableStore = defineStore('data-table', () => {
  const scrollCollection = reactive<Record<string, number>>({});

  return {
    scrollCollection,
  };
});
