/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ColumnModelShowcaseDto = {
    index?: number;
    id: string;
    name: string;
    dataType: ColumnModelShowcaseDto.dataType;
    dictionaryId?: string;
    isNullable?: boolean;
};

export namespace ColumnModelShowcaseDto {

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

