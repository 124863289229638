export enum EAxisTitleOptionId {
  On = 'on',
  Off = 'off',
  Manual = 'manual',
}
export const defaultAxisTitleOptions = [
  {
    id: EAxisTitleOptionId.On,
    name: 'Включено',
  },
  {
    id: EAxisTitleOptionId.Off,
    name: 'Выключено',
  },
  {
    id: EAxisTitleOptionId.Manual,
    name: 'Ввод вручную',
  },
];

// export const defaultAxisScaleOptions = [
//   {
//     id: visualSettings.scale.auto,
//     name: 'Автоматически',
//   },
//   {
//     id: visualSettings.scale.manual,
//     name: 'Вручную',
//   },
// ];
// export const defaultAxisTypeOptions = [
//   {
//     id: visualSettings.type.linear,
//     name: 'Линейная',
//   },
//   {
//     id: visualSettings.type.logarithmic,
//     name: 'Логарифмическая',
//   },
// ];
// export const axisGridOptions = [
//   {
//     id: visualSettings.grid.on,
//     name: 'Включено',
//   },
//   {
//     id: visualSettings.grid.off,
//     name: 'Выключено',
//   },
// ];
