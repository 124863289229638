/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormulaCheckResponseDto = {
    valid: boolean;
    message?: string;
    returnType?: FormulaCheckResponseDto.returnType;
    hasAggregateFunc?: boolean;
};

export namespace FormulaCheckResponseDto {

    export enum returnType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

