interface DataZoom {
  yAxisIndex?: string;
  xAxisIndex?: string;
}

export interface Toolbox {
  show: boolean;
  feature: {
    saveAsImage: any;
    dataZoom?: DataZoom | null;
  };
}

export enum EZoomTypes {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}
