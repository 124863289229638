import { kebabize } from '@clearview/helpers';

export default function getApiServiceEntityId(
  apiServiceName: string,
  camelize?: boolean
) {
  return camelize
    ? apiServiceName.charAt(0).toLowerCase() +
        apiServiceName.slice(1, apiServiceName.indexOf('ApiService'))
    : kebabize(apiServiceName.slice(0, apiServiceName.indexOf('ApiService')));
}
