/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ObjectsRelationsExistenceRequestDto = {
    elementId: string;
    elementRelationType: ObjectsRelationsExistenceRequestDto.elementRelationType;
};

export namespace ObjectsRelationsExistenceRequestDto {

    export enum elementRelationType {
        WORKSPACE = 'WORKSPACE',
        PROJECT = 'PROJECT',
        DASHBOARD = 'DASHBOARD',
        CONNECTION = 'CONNECTION',
        MODEL = 'MODEL',
        WIDGET = 'WIDGET',
        SHOWCASE = 'SHOWCASE',
    }


}

