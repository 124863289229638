import { RouteRecordRaw, Router } from 'vue-router';

import type { IRouterContext, TMiddleware } from '@/types/vue-router';

export function createDefaultMiddlewares(
  routes: RouteRecordRaw[],
  callback?: (route: RouteRecordRaw, level: number) => void,
  level = 0
) {
  routes.map(route => {
    if (typeof route.meta === 'undefined') {
      route.meta = {};
    }

    if (typeof route.meta.middlewares === 'undefined') {
      route.meta.middlewares = [];
    }

    if (typeof callback === 'function') {
      callback(route, level);
    }

    if (route.children) {
      createDefaultMiddlewares(route.children, callback, level + 1);
    }

    return route;
  });
}

export function applyRouterMiddlewares(router: Router) {
  router.beforeEach((to, from, next) => {
    const context: IRouterContext = {
      to,
      from,
      next,
      router,
    };

    const middlewares = to.meta?.middlewares;

    if (!middlewares || !middlewares.length) {
      return next();
    }

    const obj = {
      ...context,
      next: middlewarePipeline(context, middlewares, 1),
    };

    return middlewares[0](obj);
  });
}

export function middlewarePipeline(
  context: IRouterContext,
  middlewares: TMiddleware[],
  nextIndex: number
): () => void {
  const nextMiddleware = middlewares[nextIndex];

  if (!nextMiddleware) {
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(
      context,
      middlewares,
      nextIndex + 1
    );
    nextMiddleware({ ...context, next: nextPipeline });
  };
}
