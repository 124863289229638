/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ViewColumnSourceDto } from './ViewColumnSourceDto';

export type ColumnModelViewDto = {
    index?: number;
    id: string;
    name: string;
    source?: ViewColumnSourceDto;
    /**
     * @deprecated
     */
    sourceColumnId: string;
    /**
     * @deprecated
     */
    sourceModelId: string;
    data_type: ColumnModelViewDto.data_type;
    /**
     * @deprecated
     */
    dictionary_id?: string;
};

export namespace ColumnModelViewDto {

    export enum data_type {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

