/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DataSourceElementShortWithModelIdResponse = {
    id: string;
    name: string;
    type: DataSourceElementShortWithModelIdResponse.type;
    modelId: string;
};

export namespace DataSourceElementShortWithModelIdResponse {

    export enum type {
        XLSX_LIST = 'XLSX_LIST',
        XLSX_TABLE = 'XLSX_TABLE',
        PG_TABLE = 'PG_TABLE',
        ONE_C_DATA = 'ONE_C_DATA',
        CSV_FILE = 'CSV_FILE',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL_TABLE = 'MS_SQL_TABLE',
        MYSQL_TABLE = 'MYSQL_TABLE',
    }


}

