export default function getApiModuleHost({
  apiModuleData,
  hostsProxyMap = {},
  usingProxy,
}: {
  apiModuleData: Record<string, any>;
  hostsProxyMap: Record<string, any>;
  usingProxy?: boolean;
}): string {
  // console.log('getApiModuleHost');
  const apiModuleHostName = apiModuleData.hostName;
  if (!apiModuleHostName) return '';

  const hostProxySettings = hostsProxyMap[apiModuleHostName];

  return hostProxySettings
    ? hostProxySettings.always || usingProxy
      ? hostProxySettings.host
      : apiModuleData.host
    : apiModuleData.host;
}
