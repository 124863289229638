/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ColumnModelDictionaryDto = {
    index?: number;
    id: string;
    name: string;
    dataType?: ColumnModelDictionaryDto.dataType;
};

export namespace ColumnModelDictionaryDto {

    export enum dataType {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

