<template>
  <LoaderTemplate v-if="isLoading" />
</template>

<script lang="ts">
import { useLoading } from '@/composables';

import LoaderTemplate from '../LoaderTemplate';

const { getLoadingState } = useLoading();

export default {
  name: 'GlobalLoader',
  components: { LoaderTemplate },
  props: {
    height: { default: '100px', type: String },
    width: { default: '100px', type: String },
    type: { default: 'cube', type: String },
    text: { default: '', type: String },
  },
  data: () => ({
    length: 9,
    throttle: null,
    isLoading: true,
  }),
  computed: {
    items() {
      return Array.from({ length: this.length }).map((_, index) => index);
    },
  },
  mounted() {
    this.throttle = setInterval(() => this.setIsLoading(), 100);
  },
  unmounted() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    setIsLoading() {
      this.isLoading = getLoadingState();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './GlobalLoader';
</style>
