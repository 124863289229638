import { z } from 'zod';

const emptyHostMsg = 'Хост не должно быть пустым';
const lengthPortMsg = 'Порт должен состоять из 4 символов';
const emptyDBMsg = 'Имя базы данных не должно быть пустым';
const emptyUserMsg = 'Имя пользователя не должно быть пустым';

const checkJdbcConnectionSchema = z.object({
  host: z.string().trim().min(1, { message: emptyHostMsg }),
  port: z.preprocess(
    val => String(val),
    z.string().trim().length(4, { message: lengthPortMsg })
  ),
  database: z.string().trim().min(1, { message: emptyDBMsg }),
  user: z.string().trim().min(1, { message: emptyUserMsg }),
  password: z.string(),
});

export { checkJdbcConnectionSchema };
