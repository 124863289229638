/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionalFormatConditionDto } from './ConditionalFormatConditionDto';
import type { ConditionalFormatTargetFieldDto } from './ConditionalFormatTargetFieldDto';

export type ConditionalFormatDto = {
    id?: string;
    name?: string;
    unionCondition?: ConditionalFormatDto.unionCondition;
    priority?: number;
    targetFields?: Array<ConditionalFormatTargetFieldDto>;
    color?: string;
    conditions?: Array<ConditionalFormatConditionDto>;
};

export namespace ConditionalFormatDto {

    export enum unionCondition {
        OR = 'or',
        AND = 'and',
    }


}

