import type {
  EChartsOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { AnalitycsJSON, EType, getWrapText } from '~packages/analyticsJSON';
import { TDataValue } from '~packages/analyticsJSON/types';

import {
  EChartFormatting,
  ETypeColumn,
  EWidgetType,
  FilterCondition,
  TLegend,
} from '@/types';
import DatabaseQuery from '@bi-core/helpers/DatabaseQuery';
import { clone } from '@clearview/helpers';
import { hasOwnProperty, setMapSettings } from '@clearview/helpers';

import {
  getFormatValue,
  getMetric,
  getThemeColors,
  zoomChartTypes,
} from '../charts';
import { EZoomTypes, Toolbox } from './types';

const databaseQuery = new DatabaseQuery();

export default class Chart {
  /* TODO: add types */
  // datasetLegend;
  // fields;
  // serie;
  // filters;
  // label;
  // metrics;
  // radius;
  // separator;
  // type;
  // hasToolbox
  // tooltip

  constructor(setting) {
    this.analyticsJSON = null;
    setMapSettings(this, setting, this.getSettingsChart());
    this.tempType = this.type;
    if (setting.label) {
      this.label = {
        ...setting.label,
        rotate: setting.label.rotate,
      };
    }
    this.fields = (setting.fields || []).reduce(function (obfields, field) {
      obfields[field.id] = field.name;
      return obfields;
    }, {});
    // TODO проверяем на DEPRECEATED поле "metric.name", после удалить проверку
    this.metrics = setting.metrics?.length
      ? setting.metrics.map(metric =>
          getMetric({
            ...metric,
            name: metric?.name || this.fields[metric.field],
            fieldName: metric.fieldName ?? metric.name,
          })
        )
      : [];
    this.series = setting.series?.length
      ? setting.series.map(serie =>
          getMetric({
            ...serie,
            name: serie?.name || this.fields[serie.field],
            fieldName: serie.fieldName ?? serie.name,
          })
        )
      : [];
    const radius = setting.ring?.radius;
    const areaStyle = setting.areaStyle;
    if (areaStyle !== undefined) {
      this.areaStyle = areaStyle;
    }
    if (radius) {
      this.radius = {
        ...setting.ring,
        value: [`${radius.inner}%` || 0, `${radius.outer}%` || 0],
      };
    }
    this.sort = setting.sort;
    databaseQuery.setSchema(setting.schema || {});
    this.subtype = null;
    const limitValues = setting.limitValues;
    this.setMaxMinValue(limitValues);
  }

  getSettingsChart() {
    return {
      filters: {},
      series: { field: 'series', default: [] },
      legend: {},
      xAxis: {},
      yAxis: {},
      limitValues: {},
      theme: { default: 'default' },
      type: { field: 'subtype', default: null },
      hasToolbox: { field: 'hasToolbox', default: true },
      shouldReverseColors: { field: 'shouldReverseColors', default: false },
      startColor: { field: 'startColor', default: '' },
      dataZoom: { default: [] },
    };
  }

  addMarkLine({ value, name }) {
    return {
      name: name,
      type: 'line',
      markLine: {
        data: [{ yAxis: Number(value) }],
        label: {
          position: 'insideEndTop',
        },
      },
      data: [],
    };
  }

  convertDataQuery(analyticsJSON, dataQuery, options) {
    return analyticsJSON.convert(dataQuery, options);
  }

  formattingValue(value, formatting = '') {
    const formattingValue = getFormatValue(
      value,
      formatting as EChartFormatting,
      {
        digits: this?.label?.digits,
        rounding: this?.label?.rounding,
      }
    );

    return formattingValue;
  }

  generateName(name: string) {
    const fields = { ...this.fields };
    this.metrics.forEach(metric => {
      fields[metric.name] = metric.alias;
    });
    return fields[name] ? fields[name] : name;
  }

  getOptionsConvert() {
    return {
      isAddAllIndicators: true,
      isRollupData: true,
    };
  }

  getField(name: string) {
    const fields = [...this.series, ...this.metrics];
    const field = fields.find(
      field => field.name === name || field.alias === name
    );
    return field;
  }

  getFieldAlias(field) {
    for (const item of this.metrics) {
      if (item.name === field) {
        return item.alias;
      }
    }
  }

  generateAllOptionChart(datasetLegend) {
    const settings = { ...this };
    const optionChart = {
      dataset: datasetLegend.dataset,
      series: this.generateSeries(datasetLegend.activeSeries),
      legend: this.generateLegend(datasetLegend.legend),
      yAxis: {},
      xAxis: {},
      tooltip: this.getTooltip(),
      toolbox: this.getToolbox(),
      grid: this.getGrid(),
      dataZoom: [],
    };
    if (settings.limitValues?.show) this.generateMarkline(optionChart);
    return optionChart;
  }

  generateDataset(dataQuery) {
    const analyticsJSON = new AnalitycsJSON(EType.Column, this.analyticsJSON);

    return this.convertDataQuery(
      analyticsJSON,
      dataQuery,
      this.getOptionsConvert()
    );
  }

  generateDatasetLegend(dataQuery) {
    const {
      source,
      indicators,
      analitycs: _analitycs,
    } = this.generateDataset(dataQuery);
    return {
      dataset: [{ source }],
      activeSeries: indicators,
      legend: indicators,
    };
  }

  generateLegend(legend) {
    return {
      type: 'plain',
      show: true,
      data: legend.map(legend => legend.name),
    };
  }

  generateMarkline(optionChart) {
    if (this.maxValue?.value) {
      const markLine = this.addMarkLine(this.maxValue);
      optionChart.series.push(markLine);
      optionChart.legend.data.push(this.maxValue?.name);
    }
    if (this.minValue?.value) {
      const markLine = this.addMarkLine(this.minValue);
      optionChart.series.push(markLine);
      optionChart.legend.data.push(this.minValue?.name);
    }
  }

  generateOptionChart(
    dataQuery,
    conditionalFormats: Record<string, any>[] = []
  ): EChartsOption {
    this.conditionalFormats = conditionalFormats;
    this.datasetLegend = this.generateDatasetLegend(dataQuery);
    const optionChart = this.generateAllOptionChart(this.datasetLegend);
    this.updateOptionChart(optionChart);
    return optionChart;
  }

  isValidateSettings() {
    return (
      this.metrics.length && this.series.length && this.isValidateFilters()
    );
  }

  isValidateFilters() {
    let valid = true;
    this.filters.forEach(el => {
      if (el.comparison === undefined) {
        valid = false;
      }
    });
    return valid;
  }

  generateSeries(activeSeries) {
    const formatting = this?.label?.formatting || '';
    const series = activeSeries.map((serie, i) => ({
      type: this.type,
      tooltip: {
        formatter: params =>
          this.getFormattingSerieTooltip(params, serie, formatting),
      },
      name: this.generateName(serie.name),
      encode: {
        x: serie.analityc,
        y: serie.name,
      },
    }));
    return series;
  }

  getNameSerieLabel(params) {
    const defaultName = this.getSeriesName(params);
    const serieName = this.getField(defaultName)?.viewName || defaultName;
    return serieName;
  }

  getValueSerieLabel(params, serie) {
    const defaultLabel = serie.name;
    const field = this.getField(serie.name);
    return params.value[field?.name || defaultLabel];
  }

  getSeriesName(params) {
    return params.seriesName;
  }

  getFormattingSerieLabel(params, serie, labelType, formatting = '') {
    let formattingValue = '';
    const serieName = this.getNameSerieLabel(params);
    const data = this.getValueSerieLabel(params, serie);
    if (labelType === 'category') {
      formattingValue = serieName;
    } else if (labelType === 'value') {
      formattingValue = this.formattingValue(data, formatting);
    } else if (labelType === 'category-value') {
      formattingValue = `${serieName}: ${this.formattingValue(
        data,
        formatting
      )}`;
    } else if (labelType === 'percent') {
      formattingValue = `${params.percent}%`;
    } else if (labelType === 'category-value-percent') {
      formattingValue = `${serieName}: ${this.formattingValue(
        data,
        formatting
      )} (${params.percent}%)`;
    } else if (labelType === 'value-percent') {
      formattingValue = ` ${this.formattingValue(data, formatting)} (${
        params.percent
      }%)`;
    }
    return formattingValue;
  }

  getFormattingSerieTooltip(params, serie, formatting = '') {
    const serieName = this.getSeriesName(params);
    const name = params.name;
    const data = this.getValueSerieLabel(params, serie);
    const dimensionValues = [{ name, data }];
    return this.getHTMLTemplate({
      params,
      serieName,
      dimensionValues,
      formatting,
    });
  }

  getGrid() {
    const grid = {
      containLabel: true,
      left: '5%',
      bottom: '5%',
      right: '5%',
      top: '60',
    };

    return grid;
  }

  getLabelSerie(serie, label, labelType, formatting) {
    let serieLabel = label;
    if (labelType) {
      serieLabel = {
        ...serieLabel,
        formatter: params =>
          this.getFormattingSerieLabel(params, serie, labelType, formatting),
      };
    }
    return serieLabel;
  }

  getInterpretation(params) {
    const value = params.name;
    const field = Object.keys(params.data)[0];

    const conditions: FilterCondition[] = [
      {
        field: field,
        value: [value],
        comparison: 'includes',
      },
    ];

    return { conditions };
  }

  getFiltersForDetailedInfo(params) {
    const model = params.model;
    const name = params.name || '';
    const field = Object.keys(params.data)[0];

    if (!field || !name) return [];
    return [
      {
        field,
        table: model.table,
        comparison: 'includes',
        value: [name],
      },
    ];
  }

  getHTMLTemplate({ params, serieName, dimensionValues, formatting }) {
    return `<div style="margin: 0px 0 0;line-height:1;">
    <div style="font-size:14px;color:#666;font-weight:400;line-height:1;white-space: normal;max-width: 350px;">${serieName}</div>
    <div style="margin: 10px 0 0;line-height:1;">
    <div style="margin: 0px 0 0;line-height:1;display: grid;grid-template-columns: 15px 1fr auto;align-items: center;grid-row-gap: 5px;">
    ${dimensionValues
      .map(
        dimension => `
          <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;
          background-color:${params.color};">
          </span>
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px;white-space: normal; max-width: 350px;">
          ${getWrapText(dimension.name, { width: 40 })}
          </span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
          ${this.formattingValue(dimension.data, formatting)}
          </span>`
      )
      .join('')}
      </div>
        <div style="clear:both"/>
    </div>
    <div style="clear:both"/>
</div>`;
  }

  getSeparatorSeries() {
    return AnalitycsJSON.getSeparatorIndicator();
  }

  getToolbox() {
    const toolbox: Toolbox = {
      show: true,
      feature: {
        saveAsImage: {},
      },
    };
    const type = this.tempType;
    if (zoomChartTypes[type]) {
      toolbox.feature.dataZoom =
        zoomChartTypes[type] === EZoomTypes.Horizontal
          ? { yAxisIndex: 'none' }
          : { xAxisIndex: 'none' };
    }
    return toolbox;
  }

  tooltipPosition(point, params, dom, rect, size) {
    const containerBoundsX = size.viewSize[0];
    const { width: tooltipWidth } = dom.getBoundingClientRect();
    const halfWidth = tooltipWidth / 2;
    const yOffset = 20;
    const xOffsetBase = 25;
    const rightCondition = containerBoundsX < point[0] + tooltipWidth;
    const leftCondition = point[0] < halfWidth;
    const xOffset = leftCondition
      ? -halfWidth + 3 * xOffsetBase
      : rightCondition
      ? -halfWidth - xOffsetBase
      : 10;
    return [point[0] + xOffset, point[1] + yOffset];
  }

  getTooltip() {
    return { show: true, position: this.tooltipPosition, appendToBody: true };
  }

  getXAxis(xAxis: XAXisComponentOption | XAXisComponentOption[]) {
    const formatAxis = (x: XAXisComponentOption) => ({
      ...x,
      axisLabel: {
        ...x?.axisLabel,
        formatter: (value: TDataValue, _index: number) =>
          this.formatterAxisLabel(value, this.label),
      },
      show: x?.show !== undefined ? x.show : false,
      type: 'category',
    });

    return Array.isArray(xAxis) ? xAxis.map(formatAxis) : formatAxis(xAxis);
  }

  getLegend(legend) {
    const position = legend?.position;
    const _legend: TLegend = {
      show: legend?.show,
      orient: 'horizontal',
    };
    switch (position) {
      case 'bottom':
        _legend.bottom = 0;
        break;
      case 'top':
        _legend.top = 0;
        break;
      case 'left':
        _legend.left = 0;
        _legend.top = '50%';
        _legend.orient = 'vertical';
        break;
      case 'right':
        _legend.right = 0;
        _legend.top = '50%';
        _legend.orient = 'vertical';
        break;
      case 'top-left':
        _legend.left = 0;
        _legend.top = 0;
        _legend.orient = 'vertical';
        break;
      case 'bottom-left':
        _legend.left = 0;
        _legend.bottom = 0;
        _legend.orient = 'vertical';
        break;
      case 'top-right':
        _legend.right = 0;
        _legend.top = 0;
        _legend.orient = 'vertical';
        break;
      case 'bottom-right':
        _legend.right = 0;
        _legend.bottom = 0;
        _legend.orient = 'vertical';
        break;

      default:
        break;
    }
    return _legend;
  }

  getYAxis(yAxis: YAXisComponentOption | YAXisComponentOption[]) {
    const formatAxis = (y: YAXisComponentOption) => ({
      ...y,
      axisLabel: {
        ...y?.axisLabel,
        formatter: (value: TDataValue, _index: number) =>
          this.formatterAxisLabel(value, this.label),
      },
      name: y?.name?.show ? y.name.text : '',
      show: y?.show !== undefined ? y.show : false,
    });

    return Array.isArray(yAxis) ? yAxis.map(formatAxis) : formatAxis(yAxis);
  }

  getAxis(axis) {
    return {
      ...axis,
      show: axis?.show !== undefined ? axis.show : false,
    };
  }

  formatterAxisLabel(value: TDataValue, _label) {
    let _value = value;
    if (typeof _value === 'string') {
      _value = getWrapText(String(value));
    } else if (typeof _value === 'number') {
      _value = getFormatValue(_value, EChartFormatting.Unit);
    }
    return _value;
  }

  getQuerySettings(settingPanel) {
    const {
      table,
      limit,
      filters,
      additionalFilters,
      sort,
      sortX,
      label,
      conditionalFormats,
    } = settingPanel;
    const fields = [];
    const _sort = { ...sort };
    const _sortX = { ...sortX };
    const digits = label.digits;
    const rounding = label.rounding;
    /* Create fields & fields map */
    this.metrics?.forEach(item => {
      const aggregation = item.aggregation;
      const alias = aggregation ? `${item.field}_${aggregation}` : item.field;
      const name = item.field;
      const id = alias;

      // TODO Одинаковое поле в метрике с разными агрегатными функциями, поэтому подменяем поле сортировки на первую совпадающую метрику
      // if (aggregation === _sort.aggregation && name === _sort.field) {
      //   _sort.field = id;
      // }
      const valueField = {
        type: ETypeColumn.Value,
        id,
        alias,
        functionType: aggregation,
        name,
        rounding,
        digits,
        formulaName: item.fieldName,
        dataType: item.dataType,
        isCalculatedColumn: item.isCalculatedColumn,
        hasAggregateFunc: item.hasAggregateFunc,
        formula: item.isCalculatedColumn && item.formula,
        userFormula: item.userFormula,
      };
      fields.push(valueField);
    });
    this.series?.forEach(serie => {
      const aggregation = serie.aggregation;
      const alias = aggregation ? `${serie.field}_${aggregation}` : serie.field;
      const name = serie.field;
      const id = alias;
      const functionType = aggregation;
      if (name === _sort.field && aggregation === _sort.aggregation) {
        _sort.field = id;
      }
      const dimensionField = {
        type: ETypeColumn.Dimension,
        id,
        name,
        alias,
        functionType,
        fieldName: serie.fieldName,
        isCalculatedColumn: serie.isCalculatedColumn,
        hasAggregateFunc: serie.hasAggregateFunc,
        formula: serie.isCalculatedColumn && serie.formula,
        dataType: serie.dataType,
        userFormula: serie.userFormula,
      };
      fields.push(dimensionField);
    });
    // проверка для сортировки (без проверки если серия или метрика без агрегатной функции, график рисуется с любой функцией в сортировке)
    // if (Object.keys(_sort).length !== 0) {
    //   const isContains = fields.findIndex(
    //     el => el.functionType === _sort.aggregation && el.id === _sort.field
    //   );
    //   // if (isContains === -1) _sort.field = null;
    // }
    return {
      fields,
      filter: filters,
      additionalFilters,
      table: table,
      limit,
      sort: Array.isArray(_sort)
        ? _sort
        : Object.keys(_sort).length > 0
        ? [_sort]
        : [],
      sortX: Array.isArray(_sortX)
        ? _sortX
        : Object.keys(_sortX).length > 0
        ? [_sortX]
        : [],
      modelId: settingPanel.modelId,
      conditionalFormats,
    };
  }

  getJsonFormat(settings, schema) {
    const querySettings = this.getQuerySettings(settings);
    const {
      columns,
      filters,
      additionalFilters,
      sort,
      columnsLevels,
      sortX,
      conditionalFormats,
    } = databaseQuery.createAttributeJsonFormat(querySettings);
    return databaseQuery.getJsonFormat(
      EWidgetType.Chart,
      {
        columns,
        columnsLevels,
        filters,
        additionalFilters,
        sort,
        sortX,
        level: 0,
        page: null,
        limit: querySettings.limit,
        conditionalFormats,
      },
      schema
    );
  }

  setMaxMinValue(limitValues) {
    if (limitValues?.maxValue.value) {
      this.maxValue = limitValues.maxValue;
    }
    if (limitValues?.minValue.value) {
      this.minValue = limitValues.minValue;
    }
  }

  setUnitYAxis(optionChart, mapUnit = {}) {
    optionChart.series.forEach(serie => {
      const unit = mapUnit[serie.name];
      if (unit) {
        serie.unit = unit;
      }
    });
  }

  setTooltip(tooltip) {
    this.tooltip = tooltip;
  }

  normalizeDataset(dataset, metrics) {
    const newDataset = clone(dataset);
    const metricNames = metrics.map(el => el.name);
    for (const [index, data] of newDataset.entries()) {
      let el = null;
      for (const name of metricNames) {
        el += data[name];
      }
      if (el) {
        for (const name of metricNames) {
          newDataset[index][name] = newDataset[index][name] / el;
        }
      }
    }
    return newDataset;
  }

  checkHighlightOptions(optionChart, isInitiatorHighlight) {
    const options = clone(optionChart);
    return this.getHighlightOptions(options, isInitiatorHighlight);
  }

  getHighlightOptions(optionChart, isInitiatorHighlight = false) {
    return optionChart;
  }

  updateOptionChart(optionChart, isInitiatorHighlight = false) {
    if (
      typeof optionChart === 'object' &&
      Object.keys(optionChart).length > 0
    ) {
      const allSettings = { ...this };
      if (hasOwnProperty.call(allSettings, 'legend')) {
        optionChart.legend = this.getLegend(allSettings.legend);
      }
      if (hasOwnProperty.call(allSettings, 'yAxis')) {
        optionChart.yAxis = this.getYAxis(allSettings.yAxis);
        if (optionChart.yAxis.dataZoom) {
          if (!allSettings.yAxis.show) {
            optionChart.dataZoom = optionChart.dataZoom.filter(
              el => !hasOwnProperty.call(el, 'yAxisIndex')
            );
          } else {
            const zoom = {
              start: 0,
              yAxisIndex: 0,
              end: 100,
            };
            optionChart.dataZoom = optionChart.dataZoom
              ? [...optionChart.dataZoom, zoom]
              : [zoom];
          }
          delete optionChart.grid.right;
          delete optionChart.yAxis.dataZoom;
        }
      }
      if (hasOwnProperty.call(allSettings, 'xAxis')) {
        optionChart.xAxis = this.getXAxis(allSettings.xAxis);

        if (optionChart.xAxis.dataZoom) {
          if (!allSettings.xAxis.show) {
            optionChart.dataZoom = optionChart.dataZoom.filter(
              el => !hasOwnProperty.call(el, 'xAxisIndex')
            );
          } else {
            const zoom = {
              start: 0,
              xAxisIndex: 0,
              end: 100,
            };
            optionChart.dataZoom = optionChart.dataZoom
              ? [...optionChart.dataZoom, zoom]
              : [zoom];
          }
          delete optionChart.grid.bottom;
          delete optionChart.xAxis.dataZoom;
        }
      }
      if (hasOwnProperty.call(allSettings, 'tooltip')) {
        optionChart.tooltip = allSettings.tooltip;
      }
      if (hasOwnProperty.call(allSettings, 'hasToolbox')) {
        optionChart.toolbox.show = allSettings.hasToolbox;
      }
      if (hasOwnProperty.call(allSettings, 'theme')) {
        optionChart.color = getThemeColors(
          allSettings.theme,
          allSettings.type,
          allSettings.startColor,
          allSettings.shouldReverseColors
        );
      }
      if (hasOwnProperty.call(allSettings, 'radius') && allSettings.radius) {
        optionChart.series.forEach(serie => {
          serie.radius = allSettings.radius.value;
          if (!allSettings.radius.show) {
            serie.radius[0] = '0%';
          }
        });
      }
      if (hasOwnProperty.call(allSettings, 'label') && allSettings.label) {
        const labelType = allSettings.label.type || '';
        const formatting = allSettings.label.formatting || '';
        optionChart.series.forEach(serie => {
          const labelSerie = this.getLabelSerie(
            serie,
            allSettings?.label || {},
            labelType,
            formatting
          );
          serie.label = labelSerie;
          serie.labelLayout = {
            hideOverlap: true,
          };
        });
      }
      if (hasOwnProperty.call(allSettings, 'shouldReverseColors')) {
        optionChart.shouldReverseColors = allSettings.shouldReverseColors;
      }
      if (hasOwnProperty.call(allSettings, 'areaStyle')) {
        optionChart.series.forEach(serie => {
          if (serie.type === 'line') {
            serie.areaStyle = allSettings.areaStyle;
          }
        });
      }
      // если в настройке больше одного датасета, значит мы нажали на точку для фильтра по клику
      if (optionChart.dataset[1]) {
        return this.checkHighlightOptions(optionChart, isInitiatorHighlight);
      }
      return optionChart;
    }
  }
}
