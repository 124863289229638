/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionWithoutOwnerResponse = {
    id: string;
    name: string;
    connectionType: ConnectionWithoutOwnerResponse.connectionType;
    projectId: string;
    dataSourceIds: Array<string>;
    dataSourceElementsIds: Array<string>;
    description?: string;
    creationTime: string;
    modificationTime?: string;
};

export namespace ConnectionWithoutOwnerResponse {

    export enum connectionType {
        XLSX = 'XLSX',
        CSV = 'CSV',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

