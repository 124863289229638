/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RestrictedSource } from './RestrictedSource';

export type SelectRestriction = {
    columnId?: string;
    restrictedSource?: RestrictedSource;
    modelId?: string;
    sortDirection?: SelectRestriction.sortDirection;
    limit?: number;
};

export namespace SelectRestriction {

    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}

