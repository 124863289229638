import {
  EChartLib,
  ECustomWidgetType,
  EEchartsWidgetId,
  EEchartsWidgetType,
  ETypeColumn,
  WidgetStructure,
} from '@/types';

//
const echartsLineSettings: WidgetStructure = {
  id: EEchartsWidgetId.Line,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Line,
  name: 'Базовый линейный график',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [
    {
      allowedTypes: [ETypeColumn.Dimension, ETypeColumn.Value],
      recommendedType: ETypeColumn.Value,
      id: 'y',
      type: 'y',
      label: 'Y',
      required: true,
      capacity: 1,
      // Чтобы поле попало в columns execute-calculated
      isColumn: true,
      settings: {
        title: 'DefaultAxisTitle',
        // scale: 'DefaultAxisScale',
        // type: 'DefaultAxisType',
      },
    },
    {
      allowedTypes: [ETypeColumn.Value, ETypeColumn.Dimension],
      recommendedType: ETypeColumn.Dimension,
      id: 'x',
      type: 'x',
      label: 'X',
      required: true,
      isColumn: true,
      capacity: 5,
      settings: {
        title: 'DefaultAxisTitle',
        // scale: 'DefaultAxisScale',
        // type: 'DefaultAxisType',
      },
    },
    {
      id: 'sort',
      component: 'DefaultSort',
    },
    {
      id: 'filter',
      component: 'DefaultFilter',
    },
  ],
};

const echartsStackedLineSettings = {
  lib: EChartLib.Echarts,
  id: EEchartsWidgetId.StackedLine,
  name: 'Линейный график c накоплением',
  type: EEchartsWidgetType.Line,
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [
    {
      allowedTypes: [ETypeColumn.Dimension, ETypeColumn.Value],
      recommendedType: ETypeColumn.Value,
      id: 'x',
      type: 'xAxis',
      label: 'X',
      required: true,
      capacity: 5,
      settings: {
        title: 'DefaultAxisTitle',
      },
    },
    // {
    //   allowedTypes: ['DIMENSION', 'VALUE', 'FUNCTION'],
    //   recommendedType: ETypeColumn.Dimension,
    //   hasAggregation: true,
    //   id: 'y',
    //   type: 'yAxis',
    //   label: 'Y',
    //   items: [],
    //   required: true,
    //   capacity: 1,
    //   settings: {
    //     title: 'on',
    //     titleValue: 'nameY',
    //   },
    // },
  ],
};

const echartsPieSettings = {
  id: EEchartsWidgetId.Pie,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Pie,
  name: 'Базовая круговая диаграмма',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const echartsBarSettings = {
  id: EEchartsWidgetId.Bar,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Bar,
  name: 'Базовая столбчатая диаграмма',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const echartsStackedBarSettings = {
  ...echartsBarSettings,
  id: EEchartsWidgetId.StackedBar,
  name: 'Столбчатая диаграмма c накоплением',
};

const echartsLineBarSettings = {
  ...echartsBarSettings,
  id: EEchartsWidgetId.LineBar,
  type: EEchartsWidgetType.LineBar,
  name: 'Базовая линейчатая диаграмма',
};

const echartsStackedLineBarSettings = {
  ...echartsLineBarSettings,
  id: EEchartsWidgetId.StackedLineBar,
  name: 'Линейчатая диаграмма с накоплением',
};

const echartsRadarSettings = {
  id: EEchartsWidgetId.Radar,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Radar,
  name: 'Базовый радар',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const echartsTreemapSettings = {
  id: EEchartsWidgetId.Treemap,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Treemap,
  name: 'Базовая древовидная диаграмма',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const echartsSunburstSettings = {
  id: EEchartsWidgetId.Sunburst,
  lib: EChartLib.Echarts,
  type: EEchartsWidgetType.Sunburst,
  name: 'Базовая солнцевидная диаграмма',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const customCounterSettings = {
  id: 'custom-counter',
  lib: EChartLib.Custom,
  type: ECustomWidgetType.Counter,
  name: 'Базовый счётчик',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const customRussiaMapSettings = {
  id: 'custom-russia-map',
  lib: EChartLib.Custom,
  type: ECustomWidgetType.Map,
  name: 'Карта России',
  hasFilters: true,
  hasSort: true,
  hasTooltip: true,
  structureFields: [],
};

const allWidgetStructures = [
  echartsLineSettings,
  echartsStackedLineSettings,
  echartsBarSettings,
  echartsStackedBarSettings,
  echartsLineBarSettings,
  echartsStackedLineBarSettings,
  echartsRadarSettings,
  customCounterSettings,
  customRussiaMapSettings,
  echartsPieSettings,
  echartsTreemapSettings,
  echartsSunburstSettings,
];

export { allWidgetStructures };
