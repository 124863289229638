type EnvVars = ImportMetaEnv;
let envVars: EnvVars;

try {
  envVars = { ...import.meta.env };
} catch (error) {
  //
}

export function setVars(value: Partial<EnvVars>) {
  envVars = { ...envVars, ...value };
}

export function getVars() {
  return {
    ...(process.env || {}),
    ...envVars,
  };
}
