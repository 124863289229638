<template>
  <CvmDefault :content-class="contentClass">
    <template #header>
      <CvmHeader :header-text="headerText" />
    </template>
  </CvmDefault>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import { useRoute } from 'vue-router';

import CvmHeader from '@/bi-views/views/components/mobile/CvmHeader';

import CvmDefault from '../CvmDefault.vue';

interface LayoutProps {
  container?: boolean;
  contentClass?: string;
}

withDefaults(defineProps<LayoutProps>(), {
  contentClass: 'cvm-common-padding-area',
});
const route = useRoute();
const headerText = computed<string | undefined>(
  () => route.meta.title as string | undefined
);
</script>

<style lang="scss">
@import './CvmDefaultWithHeader';
</style>
