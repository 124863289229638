import { createMetricName } from '../helpers';

export function getMetric({
  field,
  dataType,
  formula,
  isCalculatedColumn,
  hasAggregateFunc,
  fieldName,
  name = '',
  aggregation = 'SUM',
  chartType,
  userFormula,
}) {
  const metric = {
    field,
    dataType,
    formula,
    isCalculatedColumn,
    hasAggregateFunc,
    aggregation,
    fieldName,
    alias: '',
    viewName: name,
    name: aggregation ? `${field}_${aggregation}` : field,
    chartType,
    userFormula,
  };
  metric.alias = createMetricName(metric);
  return metric;
}
