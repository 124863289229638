import getConstants from '@bi-core/constants/env';

import {
  BiBeeRestStandardApiService,
  IRestOptions,
  Mode,
  RequestMethod,
} from './../../__base/client';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'DataApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
};

export default class DataApiService extends BiBeeRestStandardApiService {
  constructor() {
    super(constructorParams);
  }

  async fetchRecords(data: unknown, options?: IRestOptions) {
    return await this.getRecords(data, options);
  }

  async fetchRecordsCalculated(data: unknown, options?: IRestOptions) {
    return await this.getRecordsCalculated(data, options);
  }

  async getRecords(data: unknown, options?: IRestOptions) {
    return await this.sendRequest(
      this.pathFetchAll + '/execute',
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: 'getRecords', ...options }
    );
  }

  async getRecordsCalculated(data: unknown, options?: IRestOptions) {
    return await this.sendRequest(
      this.pathFetchAll + '/execute-calculated',
      null,
      { body: JSON.stringify(data), method: RequestMethod.POST },
      { method: 'getRecords', ...options }
    );
  }
}
