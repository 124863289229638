import { App } from 'vue';

import router from '@/app/router/index';
import { getVars } from '@bi-core/constants/env/envVars';
import BiAnalytic from '@clearview/analytic';

const env = getVars();

const id: string = env.VITE_BI_BEE_METRIC_ID || '';
const metaContent: string = env.VITE_BI_BEE_META_CONTENT_ID || '';

const options = {
  id,
  enable: !!id,
  router: router,
  env: process.env.NODE_ENV,
  metaContent,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
};

type TOptions = typeof options;

const provider = BiAnalytic;
type TProvider = {
  install: (app: App, options: TOptions) => void;
};

export class VuePlugin {
  private provider: TProvider;
  private options: TOptions;

  constructor(provider, options: TOptions) {
    this.provider = provider;
    this.options = options;
  }

  install(app: App) {
    if (this.options.enable) {
      app.use(this.provider, this.options);
    }
  }

  meta() {
    if (!this.options.metaContent) return;
    return {
      meta: {
        ogBiCVAnalytic: {
          name: 'yandex-verification',
          content: this.options.metaContent,
        },
      },
    };
  }
}

const BIAnalyticPlugin = new VuePlugin(provider, options);

export { BIAnalyticPlugin, provider, options };
