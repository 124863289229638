/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdditionalFieldDto } from './AdditionalFieldDto';

export type TableSchemaColumn = {
    name: string;
    source: string;
    sourceIndex: number;
    sourceId: string;
    type: TableSchemaColumn.type;
    unit?: string;
    nullable?: boolean;
    human_name: string;
    data_type: string;
    additional_fields?: Array<AdditionalFieldDto>;
};

export namespace TableSchemaColumn {

    export enum type {
        VALUE = 'VALUE',
        DIMENSION = 'DIMENSION',
    }


}

