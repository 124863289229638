<template>
  <Overlay background="rgba(255, 255, 255, .6)">
    <div class="loader">
      <div v-if="type === 'cube'" class="loader-cube">
        <div class="loader-cube__inner">
          <div
            class="sk-cube-grid"
            :style="'height: ' + height + '; width: ' + width + ';'"
          >
            <div
              v-for="item in items"
              :class="'sk-cube sk-cube' + item"
              :key="item"
            />
          </div>
        </div>
      </div>
      <span v-if="text">{{ text }}</span>
    </div>
  </Overlay>
</template>

<script lang="ts">
import Overlay from '@/bi-views/views/components/Overlay';

export default {
  name: 'LoaderTemplate',
  components: { Overlay },
  props: {
    height: { default: '100px', type: String },
    width: { default: '100px', type: String },
    type: { default: 'cube', type: String },
    text: { default: '', type: String },
  },
  data: () => ({
    length: 9,
  }),
  computed: {
    items() {
      return Array.from({ length: this.length }).map((_, index) => index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './LoaderTemplate';
</style>
