/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Source } from './Source';

export type ColumnDto = {
    id?: string;
    name?: string;
    source?: Source;
    isActive?: boolean;
    periodicity?: ColumnDto.periodicity;
};

export namespace ColumnDto {

    export enum periodicity {
        YEAR = 'year',
        QUARTER = 'quarter',
        MONTH = 'month',
        WEEK = 'week',
        DAY = 'day',
    }


}

