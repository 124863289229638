export const enum EType {
  Column = 'column',
  Row = 'row',
}

export type TIndicatorSerie = string;
export type TIndicatorName = string;
export type TIndicatorFact = string;
export type TIndicatorAlias = string;

interface IIndicator {
  fact: TIndicatorFact;
  name?: TIndicatorName;
  serie?: TIndicatorSerie;
  // TODO временное свойство
  alias?: TIndicatorAlias;
}

type TAnalitycs = Array<string>;
type TIndicators = Array<IIndicator>;

export interface IAnalyticsJSON {
  analitycs: TAnalitycs;
  indicators: TIndicators;
}

export interface IOptions {
  isAddAllIndicators?: boolean;
  isRollupData?: boolean;
  isTotal?: boolean;
}

export type TDataValue = number | string | boolean;

export type TData = Array<Record<string, TDataValue>>;

export type TSet = Record<TIndicatorName, TDataValue>;
export type TSource = Array<TSet>;

export interface IEncodeIndicator {
  name: TIndicatorName;
  analityc: string;
  serie: string;
}

export type TEncodeAnalityc = Set<TDataValue>;
