import { App } from 'vue';
import { createI18n } from 'vue-i18n';

import { defaultLang, fallbackLang, messages } from '@/../languages';

const LanguagePlugin = {
  install(app: App) {
    const i18n = createI18n({
      locale: defaultLang.isoName,
      messages,
      legacy: false,
      fallbackLocale: fallbackLang.isoName,
    });

    if (document) {
      document.querySelector('html')?.setAttribute('lang', defaultLang.isoName);
    }
    app.use(i18n);
  },
};

export { LanguagePlugin };
