/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ColumnModelMsSqlDto = {
    id?: string;
    name?: string;
    alias?: string;
    index?: number;
    isNullable?: boolean;
    data_type?: ColumnModelMsSqlDto.data_type;
};

export namespace ColumnModelMsSqlDto {

    export enum data_type {
        STRING = 'STRING',
        BOOLEAN = 'BOOLEAN',
        NUMERIC = 'NUMERIC',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        DATE = 'DATE',
        DECIMAL = 'DECIMAL',
    }


}

