/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataSourceElementDto } from './DataSourceElementDto';

export type CsvDataSourceResponse = {
    id: string;
    name: string;
    type: CsvDataSourceResponse.type;
    dataSourceElements?: Array<DataSourceElementDto>;
    fileId?: string;
};

export namespace CsvDataSourceResponse {

    export enum type {
        XLSX = 'XLSX',
        PG = 'PG',
        ONE_C = 'ONE_C',
        CSV = 'CSV',
        CLICKHOUSE = 'CLICKHOUSE',
        MS_SQL = 'MS_SQL',
        MYSQL = 'MYSQL',
    }


}

