import enUS from 'quasar/lang/en-US';
import ru from 'quasar/lang/ru';

import { TLanguage } from '@/types';
import {
  COMMON__LANG,
  COMMON__LANG_DEFAULT,
  COMMON__LANG_FALLBACK,
} from '@bi-core/constants';
import { getVars } from '@bi-core/constants/env/envVars';

import messages from './messages';

const env = getVars();

const availableLangs: Record<string, TLanguage> = {
  [ru.isoName]: {
    ...ru,
    nativeName: 'Русский',
  },
  [enUS.isoName]: enUS,
};

const getDefaultLang = () => {
  const langLocalStorage = localStorage.getItem(COMMON__LANG);
  if (langLocalStorage && availableLangs[langLocalStorage])
    return availableLangs[langLocalStorage];

  return availableLangs[env?.VITE_DEFAULT_LOCALE || COMMON__LANG_DEFAULT];
};

const getFallbackLang = () => {
  return availableLangs[env?.VITE_FALLBACK_LOCALE || COMMON__LANG_FALLBACK];
};

const getLangByName = (name: string) => {
  if (!name) return;
  const isoNameLang = Object.keys(availableLangs).find(
    langIso => availableLangs[langIso]?.nativeName === name
  );
  if (!isoNameLang) return;
  return availableLangs[isoNameLang];
};

const defaultLang = getDefaultLang();
const fallbackLang = getFallbackLang();

if (!defaultLang) {
  throw new Error('Не найден язык по-умолчанию в списке доступных языков');
}
if (!fallbackLang) {
  throw new Error('Не найден язык вспомогательный в списке доступных языков');
}

export {
  availableLangs,
  messages,
  defaultLang,
  fallbackLang,
  getLangByName,
  getDefaultLang,
  getFallbackLang,
};
