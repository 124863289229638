/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ShowcaseJoinConditionDto } from './ShowcaseJoinConditionDto';
import type { ShowcaseSourceTableDto } from './ShowcaseSourceTableDto';

export type ShowcaseJoinDataDto = {
    table: ShowcaseSourceTableDto;
    joinType: ShowcaseJoinDataDto.joinType;
    joinConditions: Array<ShowcaseJoinConditionDto>;
};

export namespace ShowcaseJoinDataDto {

    export enum joinType {
        LEFT = 'LEFT',
        RIGHT = 'RIGHT',
        INNER = 'INNER',
        OUTER = 'OUTER',
    }


}

