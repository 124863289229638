import { PropType, computed, defineComponent, ref } from 'vue';
import type { SVGAttributes } from 'vue';

export const uid = () => Math.random().toString(36).substring(2);

export default defineComponent({
  name: 'ContentLoader',
  props: {
    preserveAspectRatio: {
      type: String,
      default: 'xMidYMid meet',
    },
    speed: {
      type: Number,
      default: 2,
    },
    baseUrl: {
      type: String,
      default: '',
    },
    primaryColor: {
      type: String,
      default: '#f9f9f9',
    },
    secondaryColor: {
      type: String,
      default: '#ecebeb',
    },
    primaryOpacity: {
      type: Number,
      default: 1,
    },
    secondaryOpacity: {
      type: Number,
      default: 1,
    },
    uniqueKey: {
      type: String,
    },
    animate: {
      type: Boolean,
      default: true,
    },
    style: {
      type: Object,
      default: () => ({}),
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 0.25,
    },
    gradientDirection: {
      type: String,
      default: 'left-right',
    },
    gradientRatio: {
      type: Number,
      default: 2,
    },
    svgProps: {
      type: Object as PropType<SVGAttributes>,
      default: () => ({}),
    },
  },

  setup(props) {
    const idClip = computed(() =>
      props.uniqueKey ? `${props.uniqueKey}-idClip` : uid()
    );
    const idGradient = computed(() =>
      props.uniqueKey ? `${props.uniqueKey}-idGradient` : uid()
    );
    const idAria = computed(() =>
      props.uniqueKey ? `${props.uniqueKey}-idGradient` : uid()
    );

    const rtlStyle = ref(props.rtl ? { transform: 'scaleX(-1)' } : null);
    const keyTimes = ref(`0; ${props.interval}; 1`);
    const gradientTransform = ref(
      props.gradientDirection === 'top-bottom' ? 'rotate(90)' : undefined
    );

    return {
      idClip,
      idGradient,
      idAria,
      gradientTransform,
      keyTimes,
      rtlStyle,
    };
  },

  render() {
    return (
      <svg
        {...this.svgProps}
        role="img"
        aria-labelledby={this.idAria}
        preserveAspectRatio={this.preserveAspectRatio}
        style={{
          ...this.style,
          ...this.rtlStyle,
          width: '100%',
          height: '100%',
        }}
      >
        <rect
          role="presentation"
          style={{ fill: `url(${this.baseUrl}#${this.idGradient})` }}
          clip-path={`url(${this.baseUrl}#${this.idClip})`}
          x="0"
          y="0"
          width="100%"
          height="100%"
        />

        <defs>
          <clipPath id={this.idClip}>
            {this.$slots.default ? this.$slots.default() : null}
          </clipPath>

          <linearGradient
            id={this.idGradient}
            gradientTransform={this.gradientTransform}
          >
            <stop
              offset="0%"
              stop-color={this.primaryColor}
              stop-opacity={this.primaryOpacity}
            >
              {this.animate ? (
                <animate
                  attributeName="offset"
                  keyTimes={this.keyTimes}
                  values={`${-this.gradientRatio}; ${-this.gradientRatio}; 1`}
                  dur={`${this.speed}s`}
                  repeatCount="indefinite"
                />
              ) : null}
            </stop>
            <stop
              offset="50%"
              stop-color={this.secondaryColor}
              stop-opacity={this.secondaryOpacity}
            >
              {this.animate ? (
                <animate
                  attributeName="offset"
                  values={`${-this.gradientRatio / 2}; ${
                    -this.gradientRatio / 2
                  }; ${1 + this.gradientRatio / 2}`}
                  keyTimes={this.keyTimes}
                  dur={`${this.speed}s`}
                  repeatCount="indefinite"
                />
              ) : null}
            </stop>
            <stop
              offset="100%"
              stop-color={this.primaryColor}
              stop-opacity={this.primaryOpacity}
            >
              {this.animate ? (
                <animate
                  attributeName="offset"
                  values={`0; 0; ${1 + this.gradientRatio}`}
                  keyTimes={this.keyTimes}
                  dur={`${this.speed}s`}
                  repeatCount="indefinite"
                />
              ) : null}
            </stop>
          </linearGradient>
        </defs>
      </svg>
    );
  },
});
