import { getMethods } from '@clearview/helpers';

import { Alerts } from './Alerts';

export interface IMiddlewareParams {
  apiServiceParams?: unknown;
  hasAlertError?: boolean;
  hasAlertSuccess?: boolean;
  isManual?: boolean;
  methodNameAlertMap?: Record<string, any>;
}

export type EntityApiMiddlewareType = any;

/**
 * Create apiMiddleware for entity
 * @param {string} entityIdCamelized - Entity id in camelCase
 * @param {object} apiSettings - API settings
 * @param {object} apiServices - Object with apiServices for middleware
 * @returns {function: (boolean | object)} Object with functions - wrappers for apiService methods
 */
export default function createEntityApiMiddleware(
  entityIdCamelized: string,
  apiSettings: Record<string, any> = {},
  apiServices: Record<string, any> = {}
) {
  const alerts = new Alerts(apiSettings);

  /* Define result */
  return ({
    apiServiceParams = {},
    hasAlertError = true,
    hasAlertSuccess,
    isManual,
    methodNameAlertMap = {},
  }: IMiddlewareParams = {}) => {
    /* Create entity apiService */
    const apiServiceFactory = apiServices[entityIdCamelized];
    const apiService = apiServiceFactory(apiServiceParams);

    /* Define methods */
    const methodsNames = getMethods(apiService).filter(m => m[0] !== '_');
    methodsNames.sort();

    const functions: Record<string, any> = {};

    try {
      for (const methodName of methodsNames) {
        functions[methodName] = async (...params) => {
          /* Call method */
          const apiServiceResponse = await apiService[methodName](...params);
          if (isManual || methodName === 'init') return apiServiceResponse;

          const alert = alerts.getAlert(
            entityIdCamelized,
            methodName,
            methodNameAlertMap,
            hasAlertSuccess,
            hasAlertError
          );
          alert.show(
            apiServiceResponse?.status,
            apiServiceResponse?.errors || []
          );

          if (alert.needToStop) return false;

          return apiServiceResponse.response || true;
        };
      }
    } catch (error) {
      if (error instanceof Error) {
        console.warn('createApiMiddleware', entityIdCamelized, error.message);
      }
    }

    return functions;
  };
}
