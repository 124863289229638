/* eslint-disable camelcase */
import type {
  ColumnModelDictionaryDto,
  ColumnModelShowcaseDto,
  ColumnModelViewDto,
  ModelDictionaryResponseDto,
  ModelShowcaseResponseDto,
  ModelViewResponseDto,
  SchemaResponseDto,
} from '@/api/generated';

export interface IModelSchemaColumn {
  dataType: string;
  human_name: string;
  name: string;
  source: string;
  sourceId: string;
  sourceIndex: number;
  type: string;
}

export type TModelRowsData = Array<string | number>;

export type TShowcaseColumn = ColumnModelShowcaseDto;
export type TDictionaryColumn = ColumnModelDictionaryDto;
export type TModelViewColumn = ColumnModelViewDto;

export type TModelColumnData =
  | TShowcaseColumn
  | TDictionaryColumn
  | TModelViewColumn;

export type TModelSchema = SchemaResponseDto & {
  columns: IModelSchemaColumn[];
  modelType: string;
  model_id: string;
  orderBy: unknown[];
  primaryKeyColumn: null | unknown;
  table_name: string;
};

export enum EDataType {
  Date = 'DATE',
  Numeric = 'NUMERIC',
  String = 'STRING',
  Boolean = 'BOOLEAN',
}

export enum ETypeColumn {
  Dimension = 'DIMENSION',
  Value = 'VALUE',
}

export enum ETypeModel {
  Showcase = 'SHOWCASE',
  Dictionary = 'DICTIONARY',
  View = 'VIEW',
  Kafka = 'KAFKA',
  Postgres = 'POSTGRES',
  Clickhouse = 'CLICKHOUSE',
}

export enum EOptionsModel {
  Schema = 'schema',
  Configure = 'configure',
}

export type TModelShowcaseData = Omit<ModelShowcaseResponseDto, 'type'> & {
  type: ETypeModel.Showcase;
};
export type TDictionaryData = Omit<ModelDictionaryResponseDto, 'type'> & {
  type: ETypeModel.Dictionary;
};
export type TModelViewData = Omit<ModelViewResponseDto, 'type'> & {
  type: ETypeModel.View;
};
export type TModelData = TModelShowcaseData | TDictionaryData | TModelViewData;
