import type { EChartsType, LegendComponentOption } from 'echarts';
import { EChartsOption, LegendOption } from 'echarts/types/dist/shared';

enum EChartTypes {
  Line = 'line',
  Bar = 'bar',
  BarTotal = 'barTotal',
  LineBar = 'lineBar',
  LineBarTotal = 'lineBarTotal',
  Pie = 'pie',
  Radar = 'radar',
  BigNumber = 'bigNumber',
  Text = 'text',
  Map = 'chartMap',
  Treemap = 'treemap',
  Sunburst = 'sunburst',
  MixLineBar = 'mixLineBar',
  Catalog = 'catalog',
}

const EAggregationFunction = {
  Sum: 'SUM',
  Avg: 'AVG',
  Count: 'COUNT',
  Uniq: 'uniq',
  Min: 'MIN',
  Max: 'MAX',
};

enum EChartEditorType {
  AxisRadar = 'axisRadar',
  TreemapUpperLabel = 'treemapUpperLabel',
  Yaxis = 'yAxis',
  Xaxis = 'xAxis',
  Label = 'label',
  Legend = 'legend',
  LimitValues = 'limitValues',
  ColorScheme = 'colorScheme',
  DropSettings = 'dropSettings',
  PieValues = 'pieValues',
  Ring = 'ring',
  AreaStyle = 'areaStyle',
  SeriesMapping = 'seriesMapping',
  ValueRange = 'valueRange',
  Metrics = 'metrics',
  Series = 'series',
  Filters = 'filters',
  Sort = 'sort',
  SortX = 'sortX',
  Limit = 'limit',
  Fact = 'fact',
  Placeholder = 'placeholder',
  Normalize = 'normalize',
  ConditionalFormatting = 'conditionalFormatting',
}

enum EChartFormatting {
  Space = 'space',
  Percent = 'percent',
  Unit = 'unit',
}

export type TLegend = LegendComponentOption;
export type TChartsType = EChartsType;
export type TLegendOption = LegendOption;

export type TOption = EChartsOption;
export interface IColorTheme {
  name: string;
  colors: string[];
}
export interface TChartChildrenData extends Record<string, unknown> {
  name: string;
  analytic: string;
  seria?: string;
  value: unknown;
  children?: TChartChildrenData[];
}

export interface BigNumberOptions {
  facts: [
    {
      number: {
        value: number | null;
      };
    }
  ];
}

export {
  EAggregationFunction,
  EChartTypes,
  EChartEditorType,
  EChartFormatting,
};
