import {
  CONNECTIONS_NAME,
  DASHBOARD_PAGE_NAME,
  DATA_SOURCE_CREATOR,
  DATA_SOURCE_EDITOR,
  DICTIONARY_NAME,
  PROJECTS_NAME,
  SHOWCASES_NAME,
  SHOWCASE_CREATOR,
  SHOWCASE_EDITOR,
  TEAM_NAME,
  TEXT_EDITOR_NAME,
  WIDGETS_CATALOG_NAME,
  WORKSPACES_NAME,
} from '@bi-router/routes.json';

import type { CrumbStructure } from './types';

export const structure: CrumbStructure[] = [
  {
    id: 'workspace',
    parentId: '',
    route: {
      name: WORKSPACES_NAME,
    },
    info: {
      isStatic: true,
      iconName: 'home',
    },
  },
  {
    id: 'workspaceId',
    parentId: 'workspace',
    route: {
      name: PROJECTS_NAME,
    },
    info: {
      isStatic: false,
      entityType: 'WORKSPACE',
      paramName: 'workspaceId',
    },
  },
  {
    id: 'projectId',
    parentId: 'workspaceId',
    route: {
      name: DASHBOARD_PAGE_NAME,
    },
    info: {
      isStatic: false,
      entityType: 'PROJECT',
      paramName: 'projectId',
    },
  },
  {
    id: 'connection',
    parentId: 'projectId',
    route: {
      name: CONNECTIONS_NAME,
    },
    info: {
      isStatic: true,
      text: 'Подключения',
    },
    invisible: true,
  },
  {
    id: 'showcase',
    parentId: 'projectId',
    route: {
      name: SHOWCASES_NAME,
    },
    info: {
      isStatic: true,
      text: 'Витрины',
    },
    invisible: true,
  },
  {
    id: 'dashboard',
    parentId: 'projectId',
    route: {
      name: DASHBOARD_PAGE_NAME,
    },
    info: {
      isStatic: true,
      text: 'Дашборды',
    },
    invisible: true,
  },
  {
    id: 'widget',
    parentId: 'projectId',
    route: {
      name: WIDGETS_CATALOG_NAME,
    },
    info: {
      isStatic: true,
      text: 'Виджеты',
    },
    invisible: true,
  },
  {
    id: 'team',
    parentId: 'projectId',
    route: {
      name: TEAM_NAME,
    },
    info: {
      isStatic: true,
      text: 'Команда',
    },
    invisible: true,
  },
  {
    id: 'dataSourceCreator',
    parentId: 'connection',
    route: {
      name: DATA_SOURCE_CREATOR,
    },
    info: {
      isStatic: true,
      text: 'Новое подключение',
    },
  },
  {
    id: 'dataSourceEditor',
    parentId: 'connection',
    route: {
      name: DATA_SOURCE_EDITOR,
    },
    info: {
      isStatic: false,
      entityType: 'CONNECTION',
      paramName: 'connectionId',
    },
  },
  {
    id: 'showcaseCreator',
    parentId: 'showcase',
    route: {
      name: SHOWCASE_CREATOR,
    },
    info: {
      isStatic: true,
      text: 'Новая витрина',
    },
  },
  {
    id: 'showcaseEditor',
    parentId: 'showcase',
    route: {
      name: SHOWCASE_EDITOR,
    },
    info: {
      isStatic: false,
      entityType: 'SHOWCASE',
      paramName: 'showcaseId',
    },
  },
  {
    id: 'dictionary',
    parentId: 'projectId',
    route: {
      name: DICTIONARY_NAME,
    },
    info: {
      isStatic: true,
      text: 'Справочник',
    },
  },
  {
    id: 'textEditor',
    parentId: '',
    route: {
      name: TEXT_EDITOR_NAME,
    },
    info: {
      isStatic: true,
      text: 'Редактор текста',
    },
  },
];

export const pages = Array.from(new Set(structure.map(el => el.route.name)));

export default structure;
