export const enum ESourceType {
  Postgres = 'postgres',
  Mssql = 'mssql',
  Mysql = 'mysql',
  Oracle = 'oracle',
  OneC = '1C',
  Clickhouse = 'clickhouse',
  Csv = 'csv',
  Xlsx = 'xlsx',
  Image = 'image',
  File = 'file',
}

export const enum EApiSourceType {
  Xlsx = 'XLSX',
  Postgres = 'PG',
  OneC = 'ONE_C',
  Csv = 'CSV',
  Clickhouse = 'CLICKHOUSE',
}

export const enum ESourceLabel {
  Xlsx = 'MS Excel',
  Postgres = 'PostgreSQL',
  OneC = '1С',
  Csv = 'CSV',
  Clickhouse = 'Clickhouse',
}

export const SOURCE_TYPE_MAP: Record<EApiSourceType, ESourceType> = {
  [EApiSourceType.Xlsx]: ESourceType.Xlsx,
  [EApiSourceType.Csv]: ESourceType.Csv,
  [EApiSourceType.OneC]: ESourceType.OneC,
  [EApiSourceType.Postgres]: ESourceType.Postgres,
  [EApiSourceType.Clickhouse]: ESourceType.Clickhouse,
} as const;

export const SOURCE_API_TYPE_MAP: Record<
  ESourceType,
  EApiSourceType | 'DEFAULT'
> = {
  [ESourceType.Xlsx]: EApiSourceType.Xlsx,
  [ESourceType.Csv]: EApiSourceType.Csv,
  [ESourceType.OneC]: EApiSourceType.OneC,
  [ESourceType.Postgres]: EApiSourceType.Postgres,
  [ESourceType.Mssql]: 'DEFAULT',
  [ESourceType.Mysql]: 'DEFAULT',
  [ESourceType.Oracle]: 'DEFAULT',
  [ESourceType.Clickhouse]: EApiSourceType.Clickhouse,
  [ESourceType.Image]: 'DEFAULT',
  [ESourceType.File]: 'DEFAULT',
} as const;
