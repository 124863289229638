import { warn } from '~packages/bi-logger';

import getConstants from '@bi-core/constants/env';

import { Method, Mode, RequestMethod } from '../../__base/client';
import FileModuleApiService from './__modules/FileModuleApiService';

const { isModeDevelopment } = getConstants();

const constructorParams = {
  className: 'DataModelApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
  needInit: isModeDevelopment,
};

export default class DataModelApiService extends FileModuleApiService {
  constructor() {
    super(constructorParams);
  }

  async postDataFromFile(file, id, params = {}) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.upload(
        file,
        this.pathAdd + '/load-from-file/' + id,
        params
      );
      if (!apiServiceResponse.response) warn('ERROR! Empty model');

      return apiServiceResponse;
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async getModelData(id, params = {}) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.sendRequest(
        this.pathFetchAll + '/read/' + id,
        params,
        { method: this.requestMethodFetchAll },
        { method: Method.fetchAll }
      );
      if (!apiServiceResponse.response) warn('ERROR! Empty model');

      return apiServiceResponse;
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async exportDataToFile(id, params = {}) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.download(
        null,
        this.pathFetchAll + '/read-to-file/' + id,
        params
      );
      const model = apiServiceResponse.baseResponse;
      return {
        errors: model?.status,
        response: model,
        status: model?.ok || false,
      };
    } catch (error) {
      this._log('ERROR: ' + error.message);
    }
  }

  async postDataModel(id, data, params = {}) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.sendRequest(
        this.pathAdd + '/insert/' + id,
        params,
        { body: JSON.stringify(data), method: RequestMethod.POST },
        { method: RequestMethod.POST }
      );
      if (!apiServiceResponse.response) warn('ERROR! Empty model');
      return apiServiceResponse;
    } catch (error) {
      this._log('ERROR fetchAll: ' + error.message);
    }
  }

  async updateDataModel(id, data, type = 'update') {
    let apiServiceResponse;
    let method;
    let path;
    try {
      if (type === 'update') {
        method = RequestMethod.POST;
        // по-умолчанию без '/'
        path = this.pathAdd + '/';
      } else if (type === 'delete') {
        method = RequestMethod.DELETE;
        // по-умолчанию с '/'
        path = this.pathDelete;
      }
      apiServiceResponse = await this.sendRequest(
        path + 'record/' + id,
        null,
        { body: JSON.stringify(data), method },
        { method }
      );
      const response = apiServiceResponse;
      if (!response) warn('ERROR! Empty model');
      return response;
    } catch (error) {
      this._log('ERROR update ' + error.message);
    }
  }
}
