import { Ref } from 'vue';

import type { IRestOptions } from '@/api/client';
import createModelInfo from '@/bi-core/helpers/DataModel/createModelInfo';
import { useSettings } from '@/composables/showcase';
import { useShareStore } from '@/store/bi-store';
import { EOptionsModel } from '@/types';
import { UseQueryOptions, useQuery } from '@tanstack/vue-query';

import {
  getErrorQuery,
  getQueryMiddlewares,
  useQueryWrapper,
} from '../helpers';

const middleware = () => getQueryMiddlewares('model', 'fetchSchema');

export async function fetchModelSchema(modelId: string, options?) {
  const { api } = middleware();
  const apiResponse = await api(modelId, options);
  if (!apiResponse?.status) {
    throw getErrorQuery(
      'Не удалось загрузить схему модели',
      apiResponse.errors,
      apiResponse.statusCode
    );
  }

  return apiResponse.response;
}

export function useModelSchema(
  modelId: Ref<string>,
  queryOpts: UseQueryOptions = {},
  options: IRestOptions = {}
) {
  const { alert } = middleware();
  const shareStore = useShareStore();
  if (shareStore.sharedId) {
    options.sharedId = shareStore.sharedId;
  }

  const fetchSchema = async (modelId: string) => {
    const apiResponse = await fetchModelSchema(modelId, options);

    const modelInfo = createModelInfo(apiResponse);
    const model = useSettings(modelInfo);
    return model;
  };

  const queryKey = ['models', EOptionsModel.Schema, modelId];
  const query = useQuery(queryKey, () => fetchSchema(modelId.value), queryOpts);

  return useQueryWrapper(query, queryKey, alert);
}
