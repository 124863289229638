import { warn } from '~packages/bi-logger';

import { applyArraySearchParamsToURI } from '@/bi-core/helpers/api/applyArraySearchParamsToURI';
import { ETypeModel, IApiRequestParams } from '@/types';
import getConstants from '@bi-core/constants/env';
import { hasOwnProperty } from '@clearview/helpers';

import {
  IEntityBaseApiServiceParams,
  IRestOptions,
  Method,
  Mode,
  RequestMethod,
} from '../../__base/client';
import FileModuleApiService from './__modules/FileModuleApiService';

const { isModeDevelopment } = getConstants();

const constructorParams: IEntityBaseApiServiceParams = {
  className: 'ModelApiService',
  mode: isModeDevelopment ? Mode.Production : Mode.Production,
  needInit: isModeDevelopment,
};

export default class ModelApiService extends FileModuleApiService {
  constructor() {
    super(constructorParams);
  }

  async fetchAll(
    params: Record<string, string>,
    type: string | Array<string> = '',
    options: IRestOptions = {}
  ) {
    try {
      const apiServiceResponse = await this.sendRequest(
        this.pathFetchAll,
        {
          ...(type !== '' && {
            type,
          }),
          ...params,
        },
        { method: this.requestMethodFetchAll },
        { method: Method.fetchAll, ...options }
      );
      const apiData = apiServiceResponse.response;
      if (!apiData) warn('ERROR! Empty models');

      const models = hasOwnProperty.call(apiData, 'response')
        ? apiData.response
        : apiData;
      if (!models) warn('ERROR! Empty models response');

      return {
        errors: apiServiceResponse.errors,
        response: models,
        status: apiServiceResponse.status,
      };
    } catch (error) {
      if (error instanceof Error) {
        this._log('ERROR fetchAll: ' + error.message);
      }
    }
  }

  async fetch(id: string | number, route = '', options: IRestOptions) {
    let apiServiceResponse;
    try {
      apiServiceResponse = await this.sendRequest(
        this.pathFetch + id + (route ? `/${route}` : ''),
        null,
        {},
        { dataOptions: { id }, method: RequestMethod.GET, ...options }
      );
      const model = apiServiceResponse.response;
      if (!model) warn('ERROR! Empty model');

      return {
        errors: apiServiceResponse.errors,
        response: model,
        status: apiServiceResponse.status,
        statusCode: apiServiceResponse.statusCode,
      };
    } catch (error) {
      if (error instanceof Error) {
        this._log('ERROR fetch: ' + error.message);
      }
    }
  }

  async fetchAllShowcase(params: Record<string, string>) {
    return await this.fetchAll(params, ETypeModel.Showcase);
  }

  async fetchAllShowcaseByProjectId(projectId: string) {
    return await this.fetchAll({ projectId }, ETypeModel.Showcase);
  }

  async fetchAllDictionary(params: Record<string, string>) {
    return await this.fetchAll(params, ETypeModel.Dictionary);
  }

  async fetchAllDictionaryByProjectId(projectId: string) {
    return await this.fetchAll({ projectId }, ETypeModel.Dictionary);
  }

  async fetchAllShowcaseView(params: Record<string, string>) {
    return await this.fetchAll(params, [
      ETypeModel.Showcase,
      ETypeModel.View,
      ETypeModel.Kafka,
      ETypeModel.Postgres,
      ETypeModel.Clickhouse,
    ]);
  }

  async fetchSchema(id: string | number, options: IRestOptions) {
    return await this.fetch(id, 'schema', options);
  }

  fetchAllByFilters<TResponse>(
    meta: Omit<IApiRequestParams, 'filter'> & { projectId: string },
    filter: Record<string, unknown> = {},
    options?: IRestOptions
  ) {
    const uri = applyArraySearchParamsToURI('/filter', meta);

    return this.sendRequest<TResponse>(
      uri,
      null,
      { body: JSON.stringify(filter), method: RequestMethod.POST },
      { method: RequestMethod.POST, ...options }
    );
  }
}
