/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DashboardFilterRequestDto = {
    name: string;
    isEnabled: boolean;
    dashboardId: string;
    type: DashboardFilterRequestDto.type;
    pageId?: string;
    modelId: string;
    modelName: string;
    columnId: string;
    columnName: string;
    columnType: DashboardFilterRequestDto.columnType;
    columnDataType: string;
    comparison: DashboardFilterRequestDto.comparison;
    values?: Array<any>;
    isValuesUnset?: boolean;
    isMultiple: boolean;
};

export namespace DashboardFilterRequestDto {

    export enum type {
        DASHBOARD = 'DASHBOARD',
        PAGE = 'PAGE',
    }

    export enum columnType {
        VALUE = 'VALUE',
        DIMENSION = 'DIMENSION',
    }

    export enum comparison {
        EQ = 'eq',
        NOT_EQ = 'notEq',
        GREATER = 'greater',
        GREATER_OR_EQ = 'greaterOrEq',
        SMALLER = 'smaller',
        SMALLER_OR_EQ = 'smallerOrEq',
        START_WITH = 'startWith',
        NOT_START_WITH = 'notStartWith',
        END_WITH = 'endWith',
        NOT_END_WITH = 'notEndWith',
        INCLUDES = 'includes',
        NOT_INCLUDES = 'notIncludes',
        BETWEEN = 'between',
        EXTENDED_BETWEEN = 'extendedBetween',
        CONTAINS = 'contains',
        NOT_CONTAINS = 'notContains',
        IS_EMPTY = 'isEmpty',
        IS_NOT_EMPTY = 'isNotEmpty',
        DATE_EQ = 'dateEq',
        DATE_NOT_EQ = 'dateNotEq',
    }


}

