import { defineStore } from 'pinia';
import { ref } from 'vue';

import { ConnectionData } from '@/types';

export const useModelConnectionsStore = defineStore('modelConnections', () => {
  const currentModelConnections = ref<ConnectionData[]>([]);
  const setModelConnections = value => {
    currentModelConnections.value = value;
  };
  const addModelConnections = value => {
    currentModelConnections.value.push(value);
  };
  return {
    currentModelConnections,
    setModelConnections,
    addModelConnections,
  };
});
