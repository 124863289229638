import { aggregationFunctionsAll } from '../constants';

export function createMetricName(metric) {
  let aliasMetric = '';
  const index = aggregationFunctionsAll.findIndex(
    el => el.function === metric.aggregation
  );
  if (index !== -1 && metric.viewName) {
    aliasMetric =
      aggregationFunctionsAll[index].name + '(' + metric.viewName + ')';
  } else aliasMetric = metric.name;
  return aliasMetric;
}
